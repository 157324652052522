import React from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Segment } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import './BookingBlock.scss';
import { format, parseISO } from 'date-fns';
import { dateFNSLocale, localeFormat } from '../../utils/date';
import ExpiringNavLink from '../../components/ExpiringNavLink';
import { getString } from '../../utils';
import { AddAppointmentToCalendar } from '../../workshops/blocks/WorkshopAddToCalendarBlock';
import { useCurrentAppointment, useFreeTimeSlots } from '../../appointments/appointments.hooks';

const translationPrefix = 'appointments';
const t = (key, data) => getString(`${translationPrefix}.${key}`, data);

const formatDate = (date) => {
  const dateOptions = { locale: dateFNSLocale() };
  return format(parseISO(date), 'PPPPp', dateOptions);
};

const BookingBlock = (props) => {
  const { appointmentUserRef } = props;
  const currentAppointment = useCurrentAppointment(
    appointmentUserRef.collection,
    appointmentUserRef.userId,
  );
  const timeSlots = useFreeTimeSlots(appointmentUserRef.collection, appointmentUserRef.userId);

  if (currentAppointment) {
    return (
      <Segment className="segment--booking-block">
        <p>
          {t('steps.prepare.side-note', {
            date: localeFormat(currentAppointment.startDate, 'PPPPp'),
          })}
        </p>
        {currentAppointment && appointmentUserRef && (
          <AddAppointmentToCalendar appointment={currentAppointment} sponsor={appointmentUserRef} />
        )}

        <div>
          <Button className="link" as={ExpiringNavLink} to="/meeting">
            {getString('btn.edit')}
          </Button>
          <Button className="link" as={ExpiringNavLink} to="/meeting">
            {getString('btn.cancel')}
          </Button>
        </div>

        <Button
          as={ExpiringNavLink}
          to="/meeting"
          fluid
          size="small"
          primary
          className="button--make-appointment"
        >
          {t('steps.prepare.btn')}
        </Button>
      </Segment>
    );
  }

  const { freeTimeSlots = [] } = timeSlots;

  if (freeTimeSlots.length === 0) {
    return (
      <Segment className="segment--booking-block">
        <Header className="title" as="h3">
          {t('widget.title')}
        </Header>
        <span>{t('steps.select-slot.no-slot-left')}</span>
      </Segment>
    );
  }

  const nextAvailableTimeSlots = orderBy(freeTimeSlots, ['startDate'], ['asc'])[0];

  // prettier-ignore
  const nextSlotLabel = nextAvailableTimeSlots ?
    formatDate(nextAvailableTimeSlots.startDate) :
    '';

  return (
    <Segment className="segment--booking-block">
      <Header className="title" as="h3">
        {t('widget.title')}
      </Header>

      {!currentAppointment && (
        <>
          <p className="label--choose-slots">{t('steps.select-slot.sidebar-label')}</p>
          <p className="label--next-available-slot">
            {t('steps.select-slot.next-free')} :<br />
            <span className="label--date">{nextSlotLabel}</span>
          </p>
        </>
      )}
      <ExpiringNavLink to="/meeting">
        <Button fluid size="small" primary className="button--make-appointment">
          {t('steps.select-slot.btn')}
        </Button>
      </ExpiringNavLink>
    </Segment>
  );
};

BookingBlock.propTypes = {
  appointmentUserRef: PropTypes.object.isRequired,
};

export default BookingBlock;
