/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Dropzone from 'react-dropzone';
import querystring from 'querystring';
import { Icon, Progress } from 'semantic-ui-react';
import request from 'superagent/dist/superagent.min';
import store from '../shared/Store';
import CdnImage from './CdnImage';
import { sweetAlert } from '../utils/popupUtils';
import { getString } from '../utils';

const imageFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

export function uploadAssetFile(file, urlOptions, options = {}) {
  const { onProgress } = options;
  const host = window.__DATA__.endpoints.files;
  let url = `${host}/v1/${store.eventId}/assets/users/${store.userId}`;
  if (urlOptions) {
    url += `?${querystring.stringify(urlOptions)}`;
  }

  return new Promise((resolve, reject) => {
    request
      .post(url)
      .set('Authorization', `JWT ${store.token}`)
      .attach('file', file)
      .on('progress', (e) => {
        if (onProgress) onProgress(e.percent);
      })
      .end(async (err, res) => {
        if (err) reject(err);
        else resolve(res.body);
      });
  });
}

export const styles = {
  color: {
    width: 36,
    height: 14,
    borderRadius: 2,
  },
  swatch: {
    padding: 5,
    background: '#fff',
    borderRadius: 1,
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  dropzoneContainer: {
    cursor: 'pointer',
    width: 50,
    height: 50,
    display: 'block',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.35)',
    borderRadius: 0,
  },
  dropzoneActive: {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee',
  },
  dropzoneRejected: {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee',
  },
  dropzoneText: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: 12,
  },
};

class ImageUploader extends PureComponent {
  state = {
    state: 'IDLE',
    progress: 0,
  };

  handleRemove = () => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(null);
    }
  };

  handleProgress = (progress) => {
    this.setState({ progress });
  };

  handleDrop = async (accepted) => {
    const { maxWidth, maxHeight } = this.props;
    if (accepted.length > 0) {
      try {
        this.setState({ progress: 0, state: 'UPLOADING' });
        const json = await uploadAssetFile(
          accepted[0],
          {
            maxWidth,
            maxHeight,
          },
          { onProgress: this.handleProgress },
        );
        const { onChange } = this.props;
        this.setState({ state: 'FINISHED' });
        if (onChange) {
          onChange(json);
        }
      } catch (e) {
        this.setState({ state: 'IDLE' });
        await sweetAlert({ text: getString(`errors.file.invalid-file`), icon: 'error' });
        console.error(e);
      }
    } else {
      this.setState({ state: 'IDLE' });
      console.warn('no images to upload');
    }
  };

  render() {
    const { extraImageFormats, placeholder, style, value } = this.props;
    const { state, progress } = this.state;
    if (value && (value.uri || typeof value === 'string')) {
      return (
        <div
          className="ac-table__image"
          style={{ ...styles.dropzoneContainer, cursor: 'normal', padding: 0, ...style }}
        >
          <CdnImage
            as="img"
            alt="logo"
            src={value}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
          <Icon
            name="close"
            fitted
            size="large"
            onClick={this.handleRemove}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              lineHeight: style.height ? `${style.height}px` : '50px',
              cursor: 'pointer',
            }}
          />
        </div>
      );
    }
    return (
      <Dropzone
        style={{ ...styles.dropzoneContainer, ...style }}
        activeStyle={styles.dropzoneActive}
        rejectStyle={styles.dropzoneRejected}
        accept={[...imageFormats, ...extraImageFormats].join(', ')}
        multiple={false}
        onDrop={this.handleDrop}
      >
        <div style={styles.dropzoneText}>
          <Icon size="huge" color="grey" name="image outline" />
          <p>{placeholder}</p>
          {state === 'UPLOADING' && (
            <Progress
              style={{ width: '90%', textAlign: 'center' }}
              size="small"
              percent={progress}
              color="green"
            />
          )}
        </div>
      </Dropzone>
    );
  }
}

ImageUploader.defaultProps = {
  extraImageFormats: [],
  maxHeight: '',
  maxWidth: '',
  placeholder: '',
  style: {},
  value: undefined,
};

ImageUploader.propTypes = {
  extraImageFormats: PropTypes.array,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.shape({
    uri: PropTypes.string,
    largeUri: PropTypes.string,
    mediumUri: PropTypes.string,
    squareUri: PropTypes.string,
  }),
};

export default ImageUploader;
