/* eslint-disable react/prop-types */
import { useSetState } from 'ahooks';
import { mapValues, omit } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Button, Form, Header, Message, Modal, Radio } from 'semantic-ui-react';
import CdnImage from '../../../../../../components/CdnImage';
import authService from '../../../../../../core/services/auth.service';
import store from '../../../../../../shared/Store';
import {
  checkPasswordsSimilarity,
  checkPasswordValidity,
} from '../../../../../utils/passwordUtils';
import './FormModal.scss';

function isEmailValid(field, value) {
  if (field.required && !value) return false;
  return (value || '').match(/^\S+@\S+\.\S+$/);
}

function isRadioValid(field, value) {
  return !(field.required && !value);
}

function hasFieldError(field, data) {
  if (field.type === 'header') return false;
  if (!field.required) return false;

  const value = data[field.name] || '';
  if (field.required && !value) return true;

  switch (field.type) {
    case 'email':
      return !isEmailValid(field, value);
    case 'password':
      return (
        !checkPasswordValidity(value) || !checkPasswordsSimilarity(value, data.confirmPassword)
      );
    case 'platform-password':
      return !checkPasswordValidity(value);
    default:
      return false;
  }
}

const PasswordField = ({ data, field, value: password = '', onChange }) => {
  const { t } = useTranslation();
  // const [confirmPassword, setConfirmation] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...rest } = field;

  const isValidPassword = checkPasswordValidity(password);
  const areSamePasswords = checkPasswordsSimilarity(password, data.confirmPassword);

  const passwordError = isValidPassword ? undefined : t('login.error-password-characters-length');
  const confirmError = areSamePasswords ? undefined : t('login.error-password-not-same');

  return (
    <>
      <Form.Input
        type="password"
        {...rest}
        value={password || ''}
        onChange={onChange}
        error={passwordError}
      />
      <Form.Input
        type="password"
        {...rest}
        name="confirmPassword"
        label="Confirmation"
        value={data.confirmPassword || ''}
        onChange={onChange}
        error={confirmError}
      />
    </>
  );
};

const PlatformPasswordField = ({ field, value: password = '', onChange }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, ...rest } = field;

  const isValidPassword = checkPasswordValidity(password);
  const passwordError = isValidPassword ? undefined : t('login.error-password-characters-length');

  return (
    <Form.Input
      type="password"
      {...rest}
      value={password || ''}
      onChange={onChange}
      error={passwordError}
    />
  );
};

const RadioField = ({ value, name, items, label: labelName, onChange, field, error }) => {
  const onRadioChange = (e, { value: radioValue }) => onChange(e, { name, value: radioValue });
  return (
    <>
      <Form.Field error={error} required={field.required}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{labelName}</label>
        {items.map((item) => (
          <div key={item.value}>
            <Radio
              label={item.label}
              name={`radio-group--${name}`}
              value={item.value}
              checked={value === item.value}
              onChange={onRadioChange}
            />
          </div>
        ))}
      </Form.Field>
    </>
  );
};

const FormField = ({ data, field, value, onChange }) => {
  const { type, ...rest } = field;
  switch (type) {
    case 'header': {
      return <Header as="h2">{field.content}</Header>;
    }
    case 'email': {
      return (
        <Form.Input
          type="email"
          {...rest}
          value={value || ''}
          onChange={onChange}
          error={!isEmailValid(rest, value)}
        />
      );
    }
    case 'password': {
      return <PasswordField field={field} value={value} onChange={onChange} data={data} />;
    }

    case 'platform-password': {
      return <PlatformPasswordField field={field} value={value} onChange={onChange} />;
    }

    case 'radio': {
      return (
        <RadioField
          field={field}
          {...rest}
          value={value}
          onChange={onChange}
          error={!isRadioValid(rest, value)}
        />
      );
    }

    case 'string':
    default: {
      return (
        <Form.Input
          {...rest}
          value={value || ''}
          onChange={onChange}
          error={rest.required && !value}
        />
      );
    }
  }
};

function sanitizeData(data) {
  return mapValues(data, (v) => (v && v.trim ? v.trim() : v));
}

const FormModal = ({ form, onClose }) => {
  const { t } = useTranslation();
  const [isConnected, setIsConnected] = useState(false);
  const [modalState, setModalState] = useSetState({ state: 'edit', error: undefined });
  const [data, setData] = useSetState({});
  const { header, fields, banner, collection } = form;

  function handleChange(e, { name, value }) {
    setData({ [name]: value });
  }

  function isFormValid() {
    const cleanData = sanitizeData(data);
    // eslint-disable-next-line no-restricted-syntax
    for (const field of fields) {
      if (hasFieldError(field, cleanData)) return false;
    }
    return true;
  }

  function onSuccess(res) {
    if (res.token) {
      store.onLogin(res, { stayConnected: true });
      setIsConnected(true);
    } else {
      // eslint-disable-next-line no-alert
      window.alert(form?.strings?.confirm || 'Votre inscription a bien été enregistrée');
      onClose();
    }
  }

  async function handleValidate() {
    const formData = sanitizeData(omit(data, ['confirmPassword']));
    if (isFormValid()) {
      setModalState({ error: null, state: 'sending' });
      const res = await authService.registerAccount(formData, collection);
      if (res?.success) {
        onSuccess(res);
      } else {
        setModalState({ error: res?.message || 'Une erreur est survenue', state: 'edit' });
      }
    }
  }
  if (isConnected) {
    // redirect to login screen
    return <Redirect to="/" />;
  }

  return (
    <Modal open size="tiny" className="FormModal">
      {header && <Modal.Header>{header}</Modal.Header>}
      {banner && (
        <Modal.Content className="FormModal__Image">
          <CdnImage src={banner} />
        </Modal.Content>
      )}
      <Modal.Content>
        <Form>
          {(fields || []).map((field) => (
            <FormField
              key={field._id || field.name}
              field={field}
              data={data}
              value={data[field.name]}
              onChange={handleChange}
            />
          ))}
          {modalState.error && <Message negative content={modalState.error} />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{t('btn.cancel')}</Button>
        <Button
          primary
          onClick={handleValidate}
          disabled={!isFormValid()}
          loading={modalState.state === 'sending'}
        >
          {t('btn.register')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default FormModal;
