import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Container } from 'semantic-ui-react';
import CmsScreen from '../cms/CmsScreen';
import PageBackground from '../components/PageBackground';
import { useUpdateConfig } from '../config/config.context';
import { useDesignConfig } from '../config/design.context';
import { PreviewMode } from '../config/preview.context';
import { setUsers } from '../core/users/users.actions';
import Home from '../home/Home';
import { setPrograms } from '../programs/store/programs.actions';
import store from '../shared/Store';
import { setSponsors } from '../sponsors/store/sponsors.actions';
import Styles from '../Styles';
import { setWorkshops } from '../workshops/store/workshops.actions';

const PagePreview = ({ match }) => {
  const { name } = match.params;
  const { homeBackground } = useDesignConfig();
  const updateConfig = useUpdateConfig();
  store.isPreview = true;

  useEffect(() => {
    const listener = (event) => {
      const { type, payload } = event.data;
      if (type === 'platform' && payload) {
        const { config, workshops, sponsors, programs, speakers } = payload;
        updateConfig(config);
        if (workshops) store.reduxStore.dispatch(setWorkshops(workshops));
        if (sponsors) store.reduxStore.dispatch(setSponsors(sponsors));
        if (programs) store.reduxStore.dispatch(setPrograms(programs));
        if (speakers) store.reduxStore.dispatch(setUsers('speakers', speakers));
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener, false);
    };
  }, [updateConfig]);

  const Component = name === 'home' ? Home : CmsScreen;

  return (
    <PreviewMode>
      <Provider store={store.reduxStore}>
        <Styles />
        <Container style={{ marginTop: 16 }}>
          <PageBackground image={homeBackground} />
          <Component name={name} />
        </Container>
      </Provider>
    </PreviewMode>
  );
};

export default PagePreview;
