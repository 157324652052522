import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import get from 'lodash/get';
import { cmsFields } from '../cms';
import { getBackgroundStyle } from '../../utils/Images';
import { bem } from '../../core/design/bem';

const css = bem('HeaderBlock');

export const GridColumn = ({ column, index }) => {
  const { blocks = [], width } = column;
  return (
    <Grid.Column className={`column--${index}`} style={{ padding: 0 }} width={width}>
      {blocks.map((block) => {
        const { type, _id } = block;
        const Component = get(cmsFields, type);
        if (!Component) return null;
        return <Component key={_id} {...block} />;
      })}
    </Grid.Column>
  );
};

GridColumn.propTypes = {
  column: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

function HeaderSlide(props) {
  const { className, blocks, background, horizontalAlign } = props;

  return (
    <div className={css('slide').mix(className)} style={getBackgroundStyle(background)}>
      <Container>
        <Grid
          stackable
          widths="equal"
          verticalAlign="middle"
          style={{ margin: 0, textAlign: horizontalAlign }}
        >
          <Grid.Row style={{ padding: 0 }}>
            {blocks.map((column, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <GridColumn key={index} column={column} index={index} />
            ))}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
}

HeaderSlide.defaultProps = {
  className: '',
  background: {},
  blocks: [],
  horizontalAlign: 'center',
};

HeaderSlide.propTypes = {
  className: PropTypes.string,
  background: PropTypes.object,
  blocks: PropTypes.arrayOf(PropTypes.object),
  horizontalAlign: PropTypes.oneOf(['center', 'right', 'left']),
};

export default HeaderSlide;
