import React from 'react';
import { bem } from '../../../../core/design/bem';
import CdnImage from '../../../CdnImage';
import { Image } from '../../types';

const css = bem('CarouselBlock');

type ImageVariantProps = {
  image?: Image;
  title?: string;
};

const ImageVariant = ({ title, image }: ImageVariantProps): JSX.Element | null => (
  <div className={css('image')}>
    <CdnImage src={image} maxWidth={600} srcSet openOnClick />
    {title}
  </div>
);

ImageVariant.defaultProps = {
  image: undefined,
  title: undefined,
};

export default ImageVariant;
