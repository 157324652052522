import { every, flatten } from 'lodash';

export function extractUsers(state, collection) {
  if (collection in state) {
    // eslint-disable-next-line no-shadow
    const store = state[collection];
    if (!store) return { users: [], loaded: true };
    const { loaded = true } = store;
    return { users: store[collection] || [], loaded };
  }
  // Take from generic user collection
  const { collections } = state.users;
  if (collections[collection]) {
    return { ...collections[collection], loaded: true };
  }
  return { users: [], loaded: true };
}

export function selectUsers(state, collections) {
  const collectionUsers = collections.map((col) => extractUsers(state, col));
  const users = flatten(collectionUsers.map((u) => u.users));
  return { users, loaded: every(collectionUsers, 'loaded') };
}

export function findUser(state, predicate) {
  const { users } = state.users;
  const { sponsors } = state.sponsors;
  return users.find(predicate) || sponsors?.find(predicate);
}
