/* eslint-disable react/no-danger */
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useConfig } from '../../config/config.context';
import { useScreenConfig } from '../../config/screens.context';
import CdnImage from '../CdnImage';
import { CmsBlocks } from '../cms';
import CookieModal from './CookieModal';
import './footer.scss';
import FooterLink from './FooterLink';
import FooterModal from './FooterModal';

function useFooter() {
  const { footer } = useConfig();
  const footerScreen = useScreenConfig('footer');
  return footer || footerScreen;
}

function Footer(props) {
  const footer = useFooter();
  if (!footer || !footer.blocks?.length) return null;
  const { blocks = [] } = footer;
  const { style } = props;

  const renderBlock = (block, index) => {
    const { _id, key = '', type } = block;
    switch (type) {
      case 'cms':
        return <CmsBlocks {...block} />;
      case 'link':
        return <FooterLink {...block} />;
      case 'html': {
        const { content } = block;
        return (
          <div
            key={_id || index}
            className={cx('footer--text', key)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        );
      }
      case 'image': {
        const { image, maxHeight, alt, tyle: imageStyle, url } = block;
        if (!image || !image.uri) return null;
        return (
          <CdnImage
            as={url ? 'a' : undefined}
            href={url}
            key={_id || index}
            src={image}
            className={`footer--logo block--id-${_id || index}`}
            alt={alt || 'footer-logo'}
            maxHeight={maxHeight || 50}
            style={imageStyle}
          />
        );
      }
      case 'social': {
        const { items = [] } = block;
        return (
          <div key={_id || index} className="footer--socialmedia">
            {items.map(({ icon, url }) => (
              <a href={url} target="_blank" rel="noreferrer">
                <Icon name={icon} size="big" />
              </a>
            ))}
          </div>
        );
      }
      case 'modal': {
        const { link, ...rest } = block;
        return <FooterModal key={_id || index} {...rest} trigger={<FooterLink {...link} />} />;
      }
      case 'cookieModal': {
        return <CookieModal key={_id || index} {...block} />;
      }
      default:
        return null;
    }
  };
  return (
    <footer className="footer" style={style}>
      <div className="footer--container">{blocks.map(renderBlock)}</div>
    </footer>
  );
}

Footer.defaultProps = {
  style: {},
};

Footer.propTypes = {
  style: PropTypes.object,
};

export default Footer;
