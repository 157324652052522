import { Message } from 'semantic-ui-react';
import React from 'react';
import { getString } from '../../utils';

const translationPrefix = 'login';

const Messages = ({ message, error }) => (
  <>
    {message && (
      <div className="Login--MessageWrapper">
        <Message content={message} />
      </div>
    )}
    {error && (
      <div className="Login--MessageWrapper">
        <Message error header={getString(`${translationPrefix}.error`)} content={error} />
      </div>
    )}
  </>
);
export default Messages;
