import { useSetState } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Button, Checkbox, Form, Icon } from 'semantic-ui-react';
import authService from '../../core/services/auth.service';
import store, { getParameterByName } from '../../shared/Store';
import { getString } from '../../utils';
import { useAutoRefresh } from '../../utils/hooks';
import { useLoginConfig } from '../hooks';
import Messages from './Messages';

const translationPrefix = 'login';

const AutoDisableButton = ({ disabled, disableUntil, content, ...props }) => {
  const now = useAutoRefresh(1000);
  if (disableUntil && disableUntil > now) {
    return (
      <Button {...props} disabled style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div>
          <div>{Math.floor((disableUntil - now) / 1000)}s</div>
          <div>
            <small>{content}</small>
          </div>
        </div>
      </Button>
    );
  }
  return (
    <Button {...props} disabled={disabled}>
      {content}
    </Button>
  );
};

const OneTimePasswordForm = ({ onLogin }) => {
  const otpRef = useRef();
  const loginConfig = useLoginConfig();
  const [state, setState] = useSetState(() => ({
    login: getParameterByName('email') || '',
    otp: '',
    hasSentOTP: false,
    sentOTPTime: undefined,
    // eslint-disable-next-line react/destructuring-assignment
    stayConnected: loginConfig?.stayConnected ?? true,
    error: undefined,
    message: undefined,
  }));
  const { login, otp, hasSentOTP, sentOTPTime, error, message, stayConnected } = state;
  function handleChange(e, { name, value, checked }) {
    setState({ [name]: value || checked, message: null, error: null });
  }

  function handleBack(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    setState({ hasSentOTP: false, error: null, message: null });
  }

  async function handleSendCode(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    await authService.sendOneTimePassword(login);
    setState({ hasSentOTP: true, sentOTPTime: Date.now() });
    if (otpRef.current && otpRef.current.focus) {
      setTimeout(() => otpRef.current.focus(), 600);
    }
  }

  async function handleLogin(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    try {
      await store.login(login, otp, stayConnected, { method: 'otp' });
      if (store.isLoggedIn()) {
        onLogin();
      } else {
        setState({ error: getString(`${translationPrefix}.error-wrong-login-password`) });
      }
    } catch (e) {
      setState({
        error: getString(`${translationPrefix}.error-connection-error`),
      });
    }
  }

  return (
    <>
      <div className="scrolling-pane-wrapper">
        <div className="scrolling-pane" style={{ marginLeft: hasSentOTP ? '-100%' : 0 }}>
          <div className="scrolling-pane__panel">
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={getString(`${translationPrefix}.login`)}
              name="login"
              value={login || ''}
              onChange={handleChange}
            />
            <Form.Field className="login-form__options">
              <Checkbox
                name="stayConnected"
                className="form-login--stay-connected-checkbox"
                checked={stayConnected}
                label={getString(`${translationPrefix}.accept-save-data`)}
                onChange={handleChange}
              />
            </Form.Field>
            <Button
              fluid
              type={hasSentOTP ? 'button' : 'submit'}
              size="large"
              primary={!hasSentOTP}
              disabled={!login}
              onClick={handleSendCode}
            >
              {getString(`${translationPrefix}.otp.send-sms`)}
            </Button>
          </div>
          <div className="scrolling-pane__panel">
            <Form.Input
              fluid
              input={{ ref: otpRef }}
              icon="key"
              iconPosition="left"
              placeholder={getString(`${translationPrefix}.otp.placeholder`)}
              type="password"
              name="otp"
              value={otp}
              onChange={handleChange}
              actionPosition="right"
              action={
                <AutoDisableButton
                  disabled={!hasSentOTP}
                  disableUntil={sentOTPTime ? sentOTPTime + 60 * 1000 : undefined}
                  onClick={handleSendCode}
                  type="button"
                  content={getString(`${translationPrefix}.otp.send-sms`)}
                />
              }
            />
            <p>{getString(`${translationPrefix}.otp.enter-message-sms`)}</p>

            <Button
              type={hasSentOTP ? 'submit' : 'button'}
              primary
              fluid
              size="large"
              disabled={!login || !otp}
              onClick={handleLogin}
            >
              {getString('btn.connect')}
            </Button>
            <p style={{ marginTop: '0.5rem' }}>
              <a className="link--secondary" href="#" onClick={handleBack}>
                <Icon name="chevron left" /> {getString(`${translationPrefix}.otp.change-login`)} (
                {login})
              </a>
            </p>
          </div>
        </div>
      </div>

      <Messages message={message} error={error} />
    </>
  );
};

OneTimePasswordForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default OneTimePasswordForm;
