/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Header, Segment, Card, Button, Item } from 'semantic-ui-react';
import './ExhibitorGroup.scss';
import { userArrayProptypes, userProptypes } from '../../propTypes';
import { replaceValues } from '../../utils/stringUtils';
import CdnImage from '../CdnImage';
import useTranslations from '../../hooks/useTranslations';
import EntityLink from '../EntityLink';
import { evalValue } from '../../utils/constraints';
import { bem } from '../../core/design/bem';
import ProgressCircular from '../ProgressCircular';
import { eventTags } from '../../core/trackers/events';
import { useTracking } from '../../Context';
import store from '../../shared/Store';

export function makeFilter(filter) {
  if (!filter) return () => true;
  if (filter.type === 'jsep') return (item) => evalValue(filter, item);
  return true;
}

const cxGroup = bem('exhibitor-group');
const cxCards = bem(cxGroup('cards').toString());
const cxCard = bem(cxCards('card').toString());

function getItemInfo(item, config) {
  const {
    title: titleTemplate = '{title}',
    subtitle: subtitleTemplate = '{subtitle}',
    description: descriptionTemplate = '{description}',
    image: imageField = 'image',
    offerConfig = {},
  } = config;
  const { field = 'offers' } = offerConfig;
  const title = replaceValues(titleTemplate, item);
  const subtitle = replaceValues(subtitleTemplate, item);
  const description = replaceValues(descriptionTemplate, item);
  const image = get(item, imageField);
  const offers = get(item, field) || [];
  return { title, subtitle, description, image, offers };
}

const SeeMoreButton = ({ entity }) => {
  const { t } = useTranslations('components.exhibitor-group');
  const { trackEvent } = useTracking();
  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        className="see-more"
        as={EntityLink}
        entity={entity}
        onClick={() => trackEvent(eventTags.SPONSOR_GO_TO_PAGE, { item: entity })}
      >
        {t('see-more')}
      </Button>
    </div>
  );
};

SeeMoreButton.propTypes = {
  entity: PropTypes.object.isRequired,
};

const SimpleCard = ({ item, config }) => {
  const { title, subtitle, description, image } = getItemInfo(item, config);
  return (
    <Card className={cxCard({ simple: true }).toString()}>
      <div className="content">
        {image && <CdnImage src={image} className="image" maxHeight={60} />}
        {title && (
          <Header as="h3" className="title">
            {title}
          </Header>
        )}
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {description && (
          <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
      <SeeMoreButton entity={item} />
    </Card>
  );
};

const Offer = ({ offer, parent, config }) => {
  const {
    title: titleTemplate = '{title}',
    subtitle: subtitleTemplate = '{subtitle}',
    percentage: percentageField = 'percentage',
    progress,
  } = config;
  const title = replaceValues(titleTemplate, offer);
  const subtitle = replaceValues(subtitleTemplate, offer);
  const percentage = get(offer, percentageField);
  const { trackEvent } = useTracking();

  return (
    <Item
      className="offer"
      as={EntityLink}
      onClick={() =>
        trackEvent(eventTags.LIST_DETAIL_ITEMS, {
          userId: store.userId,
          item: offer,
        })
      }
      entity={{ ...offer, parent, kind: 'offer' }}
    >
      {percentage != null && (
        <div className="offer__progress">
          <ProgressCircular value={percentage} config={{ progress }} />
        </div>
      )}
      <div className="offer__content">
        <Header className="title" as="h5">
          {title}
        </Header>
        {subtitle && <p className="subtitle">{subtitle}</p>}
      </div>
    </Item>
  );
};

Offer.propTypes = {
  config: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  parent: PropTypes.shape(userProptypes).isRequired,
};

const MatchingCard = ({ item, config }) => {
  const { t } = useTranslations('components.exhibitor-group');
  const { title, subtitle, image, offers } = getItemInfo(item, config);

  const { offerConfig = {} } = config;
  const label = get(offerConfig, 'label', t('offers'));
  return (
    <Card className={cxCard({ matching: true }).toString()}>
      <div className="content">
        <div className="info">
          {image && <CdnImage src={image} className="image" maxHeight={60} />}
          {title && (
            <Header as="h3" className="title">
              {title}
            </Header>
          )}
          {subtitle && <p className="subtitle">{subtitle}</p>}
        </div>
        {offers.length > 0 && (
          <>
            <div className="offers__stat">
              <Header className="value" as="h2">
                {offers.length}
              </Header>
              <p className="label">{label}</p>
            </div>

            <div className="offers__list">
              {offers.slice(0, 3).map((offer) => (
                <Offer key={offer._id} offer={offer} config={offerConfig} parent={item} />
              ))}
            </div>
          </>
        )}
      </div>
      <SeeMoreButton entity={item} />
    </Card>
  );
};

const cardPropTypes = {
  config: PropTypes.object.isRequired,
  item: PropTypes.shape(userProptypes).isRequired,
};

SimpleCard.propTypes = cardPropTypes;
MatchingCard.propTypes = cardPropTypes;

const components = {
  simple: SimpleCard,
  matching: MatchingCard,
};

function ExhibitorGroup(props) {
  const { items, config, template, labels } = props;
  const { title, subtitle, emptySubtitle, emptyTitle } = labels;
  const { itemsPerRow = 4, filter, item: itemConfig = {} } = config;
  const finalItems = items.filter(makeFilter(filter));
  const hasItems = finalItems.length > 0;
  const Exhibitor = get(components, template);
  return (
    <>
      <Segment className={cxGroup({ template }).toString()}>
        {title && (
          <Header className={cxGroup('title').toString()} as="h3">
            {hasItems ? title : emptyTitle || title}
          </Header>
        )}
        {subtitle && (
          <p className={cxGroup('subtitle')}> {hasItems ? subtitle : emptySubtitle || subtitle}</p>
        )}
        <Card.Group itemsPerRow={itemsPerRow} stackable className={cxGroup('cards')}>
          {finalItems.map((item) => (
            <Exhibitor key={item._id} item={item} config={itemConfig} />
          ))}
        </Card.Group>
      </Segment>
    </>
  );
}

ExhibitorGroup.defaultProps = {
  config: {},
  items: [],
  labels: {},
  template: 'simple',
};

ExhibitorGroup.propTypes = {
  config: PropTypes.object,
  labels: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    emptyTitle: PropTypes.string,
    emptySubtitle: PropTypes.string,
  }),
  items: PropTypes.arrayOf(userArrayProptypes),
  template: PropTypes.oneOf(['simple', 'matching']),
};

export default ExhibitorGroup;
