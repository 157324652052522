import startsWith from 'lodash/startsWith';
import encode from 'querystring/encode';

export const chineseHost = { 'pes2021-cn.events.3ds.com': true };

const { forceImageFormat = 'original' } = window.__DATA__;

const cloudImageUrl =
  // prettier-ignore
  chineseHost[window.location.host] ?
    `https://${window.location.host}/img.appcraft.events` :
    'https://img.appcraft.events';

const aliases = {
  'https://app.appcraft.events/images': '_appcraft_images_',
  'https://app.sandbox.appcraft.events/images': '_sandbox_images_',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001/events':
    'evt',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static1':
    '_storage_static1_',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001':
    '_storage_static001_',
  'https://pes2021-cn.events.3ds.com/static001': '_storage_static001_',
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/sandbox001':
    '_storage_sandbox001_',
  'https://img.appcraft.events/cdn/n/n/': '',
  'https://avfuefcxen.cloudimg.io/cdn/n/n/': '',
};

const aliasUrls = Object.keys(aliases);

function shorthand(url) {
  const aliasUrl = aliasUrls.find((prefix) => startsWith(url, prefix));

  if (startsWith(url, 'https://avfuefcxen.cloudimg.io/v7/')) {
    // Already shorthanded, extract relevant part
    return url.replace(
      /^https:\/\/avfuefcxen\.cloudimg\.io\/v7\/([^?]+)\?.*/,
      (_all, group1) => group1,
    );
  }

  if (startsWith(url, 'https://img.appcraft.events/v7/')) {
    // Already shorthanded, extract relevant part
    return url.replace(
      /^https:\/\/img\.appcraft\.events\/v7\/([^?]+)\?.*/,
      (_all, group1) => group1,
    );
  }

  return url.replace(aliasUrl, aliases[aliasUrl]);
}

export function getUrl(uri) {
  return uri && (typeof uri === 'string' ? uri : uri.uri);
}

export function cloudimg(uri, options) {
  const url = getUrl(uri);
  if (!url) return url;
  return `${cloudImageUrl}/v7/${shorthand(url)}?${encode(options)}`;
}

function cdn(uri) {
  const url = getUrl(uri);
  if (!url) return url;

  // Already on the cdn ?
  if (
    startsWith(url, 'https://avfuefcxen.cloudimg.io') ||
    startsWith(url, 'https://img.appcraft.events')
  ) {
    return url;
  }

  return `${cloudImageUrl}/cdn/n/n/${shorthand(url)}`;
}

export function extractFormat(type) {
  if (type === 'auto') return '';
  return type || forceImageFormat;
}

function maxWidth(image, maxW = 1200, options = {}) {
  return cloudimg(image, {
    w: maxW,
    func: 'bound',
    org_if_sml: 1,
    force_format: extractFormat(options.type),
  });
}

function maxHeight(image, maxH = 1000, options = {}) {
  return cloudimg(image, {
    h: maxH,
    func: 'bound',
    org_if_sml: 1,
    force_format: extractFormat(options.type),
  });
}

function square(image, size = 200, options = {}) {
  return cloudimg(image, {
    w: size,
    h: size,
    force_format: extractFormat(options.type),
  });
}

function enableSrcSet(size, desiredSize) {
  if (!size) return true; // We don't know, allow by default
  return size >= desiredSize; // Don't enable srcSet if image is too small
}

function aspectRatio(image) {
  if (!image || typeof image !== 'object') return undefined;

  const { width, height } = image;
  if (!width || !height) return undefined;
  return width / height;
}

const srcSet = {
  maxWidth: (image, maxW, options) => ({
    src: maxWidth(image, maxW, options),
    srcSet: enableSrcSet(image.width, maxW * 2) ? `${maxWidth(image, maxW * 2, options)} 2x` : '',
  }),
  maxHeight: (image, maxH, options) => ({
    src: maxHeight(image, maxH, options),
    srcSet: enableSrcSet(image.height, maxH * 2) ? `${maxHeight(image, maxH * 2, options)} 2x` : '',
  }),
  square: (image, size, options) => ({
    src: square(image, size, options),
    srcSet: enableSrcSet(image.width, size * 2) ? `${square(image, size * 2, options)} 2x` : '',
  }),
};

function exists(image) {
  return !!getUrl(image);
}

function double(n) {
  if (typeof n === 'number') return n * 2;
  return n;
}

function computeSrcSet(src, func, imageType, cdnOptions) {
  if (!src.width) return undefined;
  const sizes = [200, 300, 500, 800, 1024, 1127, 1600, 1920].filter((size) => size < src.width);
  sizes.push(src.width);
  return sizes.map((size) => {
    return `${cloudimg(src, {
      w: size,
      h: double(maxHeight),
      func,
      org_if_sml: 1,
      force_format: extractFormat(imageType),
      ...cdnOptions,
    })} ${size}w`;
  });
}

export function getBackgroundStyle(background) {
  const { image, size = 'cover', position = 'center', color } = background;
  const isGradient = image && typeof image === 'string' && image.includes('gradient');
  const hasImage = !isGradient && exists(image);
  if (!hasImage && !isGradient && !color) return {};
  if (hasImage || isGradient) {
    return {
      backgroundImage: hasImage ? `url(${maxWidth(image, 1920)})` : image,
      backgroundPosition: position,
      backgroundSize: size,
      backgroundRepeat: 'no-repeat',
    };
  }
  if (color) return { backgroundColor: color };
  return {};
}

export default {
  shorthand,
  cdn,
  computeSrcSet,
  maxWidth,
  maxHeight,
  square,
  exists,
  aspectRatio,
  srcSet,
};
