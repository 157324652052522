/* eslint-disable consistent-return */
import { useRequest } from 'ahooks';
import { throttle } from 'lodash';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchAgenda } from '../../agenda/store/agenda.actions';
import { userAgenda } from '../../agenda/store/agenda.selectors';
import { useConfig } from '../../config/config.context';
import { useScreenConfig } from '../../config/screens.context';
import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import store from '../../shared/Store';
import { showBookConflict } from '../../sponsors/blocks/BookAppointmentBlock/utils';
import { fetchRegistrations } from '../../store/actions';
import { getString } from '../../utils';
import { findOverlappingEvent } from '../../utils/agendaUtils';
import { sweetAlert, sweetConfirmAlert } from '../../utils/popupUtils';
import { useStoreCollectionSync } from '../../utils/useVersionSync';
// eslint-disable-next-line import/no-cycle
import { useSyncedWorkshops } from '../../workshops/store/workshops.hooks';
import workshopSessionService from '../services/workshop-session.service';
import { fetchWorkshopSessions, throttledFetchWorkshopSessions } from './workshopSessions.actions';

const translationPrefix = 'workshop-sessions.registration';

function getErrorString(code) {
  switch (code) {
    case 'E_SESSION_FULL':
      return getString(`${translationPrefix}.session-full`);
    case 'E_USER_ALREADY_ATTENDING':
      return getString(`${translationPrefix}.user-already-attending-session`);
    default:
      return getString(`${translationPrefix}.workshop-session-error`);
  }
}

function showError(code) {
  return sweetAlert({ icon: 'error', title: getErrorString(code) });
}

export function useWorkshopSessions(collection, workshopId) {
  return useRequest(() => workshopSessionService.fetchWorkshopSessions(collection, workshopId), {
    formatResult: (workshopSessions) => orderBy(workshopSessions, 'startDate', 'asc'),
    cacheKey: `workshops/${collection}/${workshopId}/sessions`,
    cacheTime: 60 * 1000,
  });
}

const throttleWorkshopSessions = throttle(
  () => store.reduxStore.dispatch(fetchWorkshopSessions()),
  500,
  { trailing: false },
);

function useApecSessionsSync() {
  const config = useConfig();
  useEffect(() => {
    if (config.apec) {
      throttleWorkshopSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(config.apec)]);
}

export function useWorkshopWithSessions() {
  useApecSessionsSync();
  const workshops = useSyncedWorkshops();
  const workshopSessions = orderBy(
    useSelector((state) => state.workshopSessions.workshopSessions),
    'startDate',
    'asc',
  );

  return useMemo(() => {
    const sessionsByWorkshopId = groupBy(workshopSessions, 'workshopId');
    return workshops.map((workshop) => {
      const { _id: workshopId } = workshop;
      const sessions = get(sessionsByWorkshopId, workshopId) || [];
      const nextSession = sessions.find(
        ({ quota, usersCount }) => !!quota && quota - usersCount !== 0,
      );
      return {
        ...workshop,
        sessionCount: sessions.length,
        nextSession,
      };
    });
  }, [workshops, workshopSessions]);
}

export async function registerWorkshopSession(
  session,
  agenda,
  { trackEvent, setLoading, allowOverlappingSessions } = {},
) {
  try {
    setLoading(true);
    const { collection, workshopId, _id: sessionId } = session;
    const overlappingEvent = findOverlappingEvent(agenda, session);

    if (overlappingEvent && !allowOverlappingSessions) {
      await showBookConflict(overlappingEvent);
      return;
    }

    const res = await workshopSessionService.registerWorkshopSession(
      collection,
      workshopId,
      sessionId,
    );
    if (res.success) {
      trackEvent(eventTags.WORKSHOP_REGISTER, {
        userId: store.userId,
        session,
        item: session,
      });
      store.reduxStore.dispatch(fetchRegistrations());
      store.reduxStore.dispatch(fetchWorkshopSessions());
      store.reduxStore.dispatch(fetchAgenda());
      toast(getString(`${translationPrefix}.successful-registration`));
      return true;
    }
    if (res.errors) {
      store.reduxStore.dispatch(fetchRegistrations());
      const { code } = res.errors[0];
      showError(code);
    }
    return false;
  } catch (error) {
    showError('ERROR');
    return false;
  } finally {
    setLoading(false);
  }
}

export async function unregisterWorkshopSession(session, { setLoading, trackEvent }) {
  const { collection, workshopId } = session;
  const sessionId = session.sessionId || session._id; // Handle both cases
  const confirm = await sweetConfirmAlert({
    title: getString(`${translationPrefix}.unregister-confirm`, {
      name: session?.title,
    }),
    confirmButtonText: getString(`alert.confirm`),
    cancelButtonText: getString(`alert.cancel`),
  });
  if (confirm) {
    trackEvent(eventTags.WORKSHOP_UNREGISTER, {
      userId: store.userId,
      session,
      item: session,
    });
    if (setLoading) setLoading(true);
    await workshopSessionService.unregisterFromWorkshopSession(collection, workshopId, sessionId);
    store.reduxStore.dispatch(fetchRegistrations());
    store.reduxStore.dispatch(fetchWorkshopSessions());
    store.reduxStore.dispatch(fetchAgenda());
    if (setLoading) setLoading(false);
    toast(getString(`${translationPrefix}.successful-cancel`, { name: session.title }));
    return true;
  }
  return false;
}

export function useWorkshopSessionRegistration(session = {}) {
  const agenda = useSelector((state) => userAgenda(state));
  const [loading, setLoading] = useState(false);
  const { allowOverlappingSessions = false } = useScreenConfig(session?.collection) || {};
  const { trackEvent } = useContext(TrackingContext);
  const { usersCount, quota } = session;
  const isWorkshopSessionFull = quota ? quota === usersCount : false;

  return {
    isWorkshopSessionFull,
    registerWorkshopSession: async () =>
      registerWorkshopSession(session, agenda, {
        allowOverlappingSessions,
        trackEvent,
        setLoading,
      }),
    loading,
    unregisterWorkshopSession: async () =>
      unregisterWorkshopSession(session, { trackEvent, setLoading, allowOverlappingSessions }),
  };
}

export function useSyncedWorkshopSessions() {
  useStoreCollectionSync({
    resourceType: 'workshopSessions',
    fetcher: throttledFetchWorkshopSessions,
  });
  return useSelector((state) => state.workshopSessions.workshopSessions);
}

export function useSyncedCollectionWorkshopSessions(collection) {
  useStoreCollectionSync({
    resourceType: 'workshopSessions',
    fetcher: throttledFetchWorkshopSessions,
  });
  const sessions = useSelector((state) => state.workshopSessions.workshopSessions);
  return useMemo(() => sessions.filter((w) => w.collection === collection), [sessions, collection]);
}

export function useGroupWorkshopsWithSessions(workshops, sessions) {
  return useMemo(() => {
    if (!sessions) return workshops;

    const sessionsByWorkshop = groupBy(sessions, 'workshopId');

    return workshops.map((workshop) => {
      const workshopSessions = sessionsByWorkshop[workshop._id] || [];
      const usersCount = sumBy(workshopSessions, 'usersCount') || 0;
      const quota = sumBy(workshopSessions, 'quota');
      return {
        ...workshop,
        usersCount,
        quota,
        sessions: workshopSessions,
      };
    });
  }, [workshops, sessions]);
}
