import { useSetState } from 'ahooks';
import liveQuestionsService from '../core/services/live-questions-service';

export function useLiveQuestion(sessionId) {
  const [state, setState] = useSetState(() => ({
    question: '',
    saving: false,
  }));
  const { question, saving } = state;

  const handleSubmit = async () => {
    if (question.trim() === '') {
      return;
    }
    setState({ saving: true });
    const res = await liveQuestionsService.postQuestion(sessionId, {
      question,
    });
    if (res.success) {
      setTimeout(() => {
        setState({ saving: false });
      }, 2000);
      setState({ question: '' });
    }
  };

  function setQuestion(q) {
    setState({ question: q });
  }
  return { question, saving, setQuestion, onSubmit: handleSubmit };
}
