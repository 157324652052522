import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import store from '../../shared/Store';
import platformService from '../../core/services/platform.service';

const FeedbackPopupBlock = ({ item, field, liveField }) => {
  const state = useRef({
    hasShown: false,
  });
  const [feedbackUrl, setFeedbackUrl] = useState(null);

  const hasStreaming = item[liveField] && item[liveField].length > 0;
  const hasFeedbacks = item[field] && item[field].length > 0;
  const feedbackFormUri = (item[field]?.[0] || {})?.uri;

  useEffect(() => {
    state.current.hasShown = false; // Reboot when item and/or feedback changes
  }, [item._id, feedbackFormUri]);

  useEffect(() => {
    if (hasFeedbacks && hasStreaming && !state.current.hasShown) {
      // eslint-disable-next-line no-inner-declarations
      async function check() {
        if (!feedbackFormUri) return;
        const api = `${feedbackFormUri.replace('/html', '/api')}/workshops/${item._id}/users/${
          store.userId
        }`;
        const res = await platformService.secureGet(api);
        if (res) {
          const { status, token } = res;
          if (status === 'display') {
            state.current.hasShown = true;
            // Generate workshop-specific uri
            setFeedbackUrl(`${feedbackFormUri}/workshops/${item._id}?token=${token}`);
          } else if (status === 'done') {
            state.current.hasShown = true;
            // Already good
          }
        }
      }
      check();
    }
  }, [hasFeedbacks, hasStreaming, item._id, feedbackFormUri]);

  if (!feedbackUrl) {
    return null;
  }

  return (
    <Modal
      open
      header="feedback"
      basic
      closeIcon
      onClose={() => setFeedbackUrl(null)}
      style={{ maxWidth: 600 }}
    >
      <Modal.Content style={{ height: '85vh', padding: '40px 50px' }}>
        <iframe
          title="Feedback"
          src={`${feedbackUrl}&userId=${store.userId}`}
          scrolling
          style={{ border: 0, height: '100%', width: '100%' }}
        />
      </Modal.Content>
    </Modal>
  );
};

FeedbackPopupBlock.defaultProps = {
  field: 'feedbacks',
  liveField: 'liveStreams',
};
FeedbackPopupBlock.propTypes = {
  field: PropTypes.string,
  liveField: PropTypes.string,
  item: PropTypes.object.isRequired,
};

export default FeedbackPopupBlock;
