/* eslint-disable react/sort-comp */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import CdnImage, { cdnImagePropTypes } from '../../components/CdnImage';
import { eventTags } from '../../core/trackers/events';
import OptLink from '../../OptLink';
import { imageProptypes } from '../../propTypes';

export const ClickableImage = ({ entity, image, link, trackClick, openMode, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (openMode === 'modal') {
      setOpen(true);
    }
  };

  return (
    <OptLink entity={entity} to={link} trackClick={trackClick}>
      <CdnImage
        src={image}
        {...props}
        onClick={handleOpen}
        srcSet="auto"
        style={{ cursor: openMode === 'modal' ? 'pointer' : undefined }}
      />
      {open && openMode === 'modal' && !link && (
        <Modal
          open
          basic
          size="fullscreen"
          onClose={() => setOpen(false)}
          closeOnDocumentClick
          style={{ marginLeft: '2.5%' }}
        >
          <Modal.Content onClick={() => setOpen(false)}>
            <CdnImage src={image} {...props} srcSet maxWidth={1920} />
          </Modal.Content>
        </Modal>
      )}
    </OptLink>
  );
};

function Banner(props) {
  const { banner, image, link, trackClick, maxWidth, maxHeight, func, container, openMode } = props;
  if (!banner && !image) return null;
  return (
    <BlockContainer className="Home--banner" type="segment-light" {...container}>
      <ClickableImage
        link={link}
        trackClick={trackClick}
        image={image || banner}
        alt="banner"
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        func={func}
        fluid
        srcSet
        openMode={openMode}
      />
    </BlockContainer>
  );
}

Banner.defaultProps = {
  banner: undefined,
  container: {},
  image: undefined,
  func: undefined,
  link: undefined,
  maxHeight: undefined,
  maxWidth: 1127,
  openMode: undefined,
  trackClick: eventTags.CTA_GO_TO,
};

Banner.propTypes = {
  banner: imageProptypes,
  container: PropTypes.object,
  image: imageProptypes,
  func: cdnImagePropTypes.func,
  link: PropTypes.string,
  openMode: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  trackClick: PropTypes.string,
};

export default Banner;
