import React, { useState } from 'react';
import { Grid, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import sift, { Query } from 'sift';
import UserProfileDetail from '../../../profile/components/UserProfileDetail/UserProfileDetail';
import store from '../../../shared/Store';
import ProfileActionButton from '../../../profile/components/ProfileActionButton';
import { isProfileField, ProfileField } from './ProfileField';
import { sweetAlert } from '../../../utils/popupUtils';

type UserProfileModalProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Array<ProfileField | string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayCondition: Query<any>;
};

const translationPrefix = 'profile';

const UserProfileModalBlock = ({
  fields = [],
  displayCondition,
}: UserProfileModalProps): React.ReactElement | null => {
  const displayConditionTester = sift(displayCondition);
  const [userPatch, setUserPatch] = useState({});
  const [openedModal, setOpenedModal] = useState(displayConditionTester(store.user));
  const { t } = useTranslation();

  const patchedUser = { ...store.user, ...userPatch };
  const allFieldsSet = !fields.some((f) => isProfileField(f) && f.required && !patchedUser[f.key]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUserChange = (modifiedUser: Record<string, any>) => {
    setUserPatch({
      ...userPatch,
      ...modifiedUser,
    });
  };

  const saveUser = async () => {
    try {
      await store.updateUser({
        ...userPatch,
        updatedByUserAt: moment().toISOString(),
      });

      setOpenedModal(false);
      await sweetAlert({
        text: t(`${translationPrefix}.confirm.update-success`),
        icon: 'success',
      });
    } catch (e) {
      await sweetAlert({
        text: t(`${translationPrefix}.errors.update-profile`),
        icon: 'error',
      });
    }
  };

  if (!openedModal || !displayConditionTester(store.user)) {
    return null;
  }

  return (
    <Modal open>
      <Modal.Content>
        <Grid>
          <UserProfileDetail
            user={{ ...store.user, ...userPatch }}
            fields={fields}
            editing
            onFieldChange={onUserChange}
            onUpdateUser={onUserChange}
            actions={[
              <ProfileActionButton
                id="updateProfile"
                key="updateProfile"
                position="right"
                disabled={!allFieldsSet}
                primary
                onClick={saveUser}
              />,
            ]}
          />
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default UserProfileModalBlock;
