import React from 'react';
import { Item } from 'semantic-ui-react';
import SquareImage from '../../../SquareImage';
import { Item as ItemProps } from '../types';

type ClassicCircleProps = {
  as?: JSX.Element | undefined;
  item: ItemProps;
  imageSize?: number;
  onClick?: (...args: any[]) => any;
};

const ClassicCircle = ({ as, item, imageSize, onClick }: ClassicCircleProps): JSX.Element => {
  const { image, title, description } = item;
  return (
    <Item.Group>
      <Item
        as={!onClick && as ? as : undefined}
        entity={{ ...item, kind: 'users' }}
        onClick={onClick && !as ? () => onClick(item) : undefined}
      >
        {image && (
          <SquareImage
            src={image}
            srcSet
            imageSize={imageSize}
            cdnOptions={{}}
            circular
            style={{ width: imageSize, height: imageSize }}
          />
        )}
        <Item.Content verticalAlign="middle">
          <Item.Header>{title}</Item.Header>
          <Item.Description>{description}</Item.Description>
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

ClassicCircle.defaultProps = {
  as: undefined,
  imageSize: 100,
  onClick: undefined,
};

export default ClassicCircle;
