import React from 'react';
import PropTypes from 'prop-types';
import { addSeconds, differenceInSeconds, isAfter, parseISO } from 'date-fns';
import { useAutoRefresh } from '../../utils/hooks';
import RatioContainer from '../../components/RatioContainer';
import Images from '../../utils/Images';
import { containerTypePropTypes, liveImageProptypes } from '../../propTypes';
import BlockContainer from '../../components/BlockContainer';
import './Poster.scss';
import TextCountdown from './TextCountdown';

const Poster = (props) => {
  const {
    item,
    className,
    containerType,
    header,
    countdownMode,
    // Removed design for now...
    postLiveImage, // = design?.workshops?.postLiveImage,
    preLiveImage, //  = design?.workshops?.preLiveImage,
  } = props;
  const now = useAutoRefresh(60000); // TODO: voir pour le passer à 5 minutes
  const startAt = parseISO(item.startDate);

  const endAt = parseISO(item.endDate);
  const mid = addSeconds(startAt, differenceInSeconds(endAt, startAt) / 2);
  function getImage() {
    if (isAfter(now, mid)) {
      // Post
      return postLiveImage?.image;
    }
    if (!preLiveImage) return null;
    const { image, timeBefore } = preLiveImage;
    if (timeBefore && isAfter(startAt, now + timeBefore)) return null;
    return image;
  }
  const poster = getImage();

  if (!poster) return null;
  const { text } = preLiveImage || {};
  return (
    <BlockContainer className={className} type={containerType} header={header}>
      <RatioContainer ratio={16 / 9}>
        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={Images.maxWidth(poster, 1280)}
          alt={item.title || 'poster'}
        />
        {text && <TextCountdown text={text} date={item.startDate} mode={countdownMode} />}
      </RatioContainer>
    </BlockContainer>
  );
};
Poster.defaultProps = {
  className: undefined,
  containerType: 'segment',
  countdownMode: 'default',
  header: undefined,
  preLiveImage: undefined,
  postLiveImage: undefined,
};
Poster.propTypes = {
  className: PropTypes.string,
  containerType: containerTypePropTypes,
  countdownMode: PropTypes.oneOf(['absolute', 'default', 'relative']),
  header: PropTypes.string,
  item: PropTypes.object.isRequired,
  preLiveImage: liveImageProptypes,
  postLiveImage: liveImageProptypes,
};

export default Poster;
