import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import ActionsBlock from './ActionsBlock';
import BlockWrapper from './BlockWrapper.tsx';
import BrowserCheckBlock from './BrowserCheckBlock';
import CMSWorkshopLiveBlock from './CMSWorkshopLiveBlock';
import CTATileBlock from './CTATileBlock';
import CTATilesBlock from './CTATilesBlock';
import CarouselBlock from './CarouselBlock';
import ContactBlock from './ContactBlock';
import FaqBlock from './FaqBlock';
import FilesBlock from './FilesBlock';
import HtmlBlock from './HtmlBlock';
import IframeBlock from './IframeBlock';
import ImageBlock from './ImageBlock';
import ItemGridBlock from './ItemGridBlock';
import LogosBlock from './LogosBlock';
import PhotoGalleryBlock from './PhotoGalleryBlock';
import ReplayBlock from './ReplayBlock';
import SpeakersBlock from './SpeakersBlock';
import UserPreferencesModalBlock from './UserPreferencesModalBlock';
import UserProfileModalBlock from './UserProfileModalBlock';
import VideoBlock from './VideoBlock';
import VideosBlock from './VideosBlock';
import WorkshopFilesBlock from './WorkshopFilesBlock';
import WorkshopVideoBlock from './WorkshopVideoBlock';

import Blocks from '../Blocks';
import GridLayout from '../GridLayout';
import HeaderBlock from '../HeaderBlock';

import Banner from '../../headers/blocks/Banner';
import BannerAds from '../../home/blocks/BannerAds';
import CloudTvBlock from '../../home/blocks/CloudTVBlock/CloudTvBlock';
import CloudTvCommentsBlock from '../../home/blocks/CloudTVBlock/components/CloudTvCommentsBlock';
import CountdownBlock from '../../home/blocks/CountdownBlock';
import FlocklerBlock from '../../home/blocks/FlocklerBlock';
import NextLiveCountdownBlock from '../../home/blocks/NextLiveCountdownBlock';
import SocialWallWidgetBlock from '../../home/blocks/SocialWallWidgetBlock';
import WorkshopsWithRegistrationBlock from '../../home/blocks/WorkshopsWithRegistrationBlock';
import UpcomingSessions from '../../home/components/UpcomingSessions';
import UserSchedule from '../../profile/blocks/UserSchedule';
import UserProfileNetworkingBlock from '../../profile/components/UserProfileNetworkingBlock';
import SocialWallBlock from '../../social-wall/blocks/SocialWallBlock';

import { Action } from './ActionsBlock/ActionsBlock';
import CategoryWorkshopsBlock from './CategoryWorkshopsBlock';

export const CmsBlocks = ({ blocks }) => (
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <Blocks blocks={blocks} blockComponents={cmsFields} />
);

const RowBlock = ({ blocks, className, style }) => (
  <Grid>
    <Grid.Row className={cx('cms-page--block', 'column--row', className)} style={style} stretched>
      <CmsBlocks blocks={blocks} />
    </Grid.Row>
  </Grid>
);

RowBlock.defaultProps = {
  blocks: [],
  className: '',
  style: {},
};

RowBlock.propTypes = {
  className: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
};

const ColumnBlock = ({ width, blocks, className }) => (
  <Grid.Column
    className={cx('cms-page--block', `column--block`, className)}
    width={width}
    style={{ flex: width ? undefined : 1 }}
  >
    <CmsBlocks blocks={blocks} />
  </Grid.Column>
);

export const cmsFields = {
  action: Action,
  actions: ActionsBlock,
  agenda: UserSchedule,
  banner: Banner,
  bannerAds: BannerAds,
  blockContainer: BlockWrapper,
  browserCheck: BrowserCheckBlock,
  carousel: CarouselBlock,
  categoryWorkshops: CategoryWorkshopsBlock,
  cloudTv: CloudTvBlock,
  cloudTvComments: CloudTvCommentsBlock,
  column: ColumnBlock,
  contact: ContactBlock,
  countdown: CountdownBlock,
  ctaTile: CTATileBlock,
  ctaTiles: CTATilesBlock,
  flockler: FlocklerBlock,
  faq: FaqBlock,
  files: FilesBlock,
  gallery: PhotoGalleryBlock,
  grid: GridLayout,
  header: HeaderBlock,
  html: HtmlBlock,
  iframe: IframeBlock,
  image: ImageBlock,
  itemGrid: ItemGridBlock,
  logos: LogosBlock,
  nextLiveCountdown: NextLiveCountdownBlock,
  networking: UserProfileNetworkingBlock,
  replay: ReplayBlock,
  row: RowBlock,
  socialWall: SocialWallBlock,
  socialWallWidget: SocialWallWidgetBlock,
  speakers: SpeakersBlock,
  upcomingSessions: UpcomingSessions,
  userProfileModal: UserProfileModalBlock,
  userPreferencesModal: UserPreferencesModalBlock,
  video: VideoBlock,
  videos: VideosBlock,
  workshopFiless: WorkshopFilesBlock,
  workshopLive: CMSWorkshopLiveBlock,
  workshopVideo: WorkshopVideoBlock,
  workshopsWithRegistration: WorkshopsWithRegistrationBlock,
};
