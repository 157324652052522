/* eslint-disable import/no-cycle */
import { isAfter, parseISO } from 'date-fns';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { GroupedUserSessions } from '../../agenda/blocks/UserAgendaBlock';
import BlockContainer from '../../components/BlockContainer';
import useTranslations from '../../hooks/useTranslations';
import { useAutoRefresh, useUserTimezone } from '../../utils/hooks';
import { currentLiveSession } from '../../workshops/utils/session.utils';
import {
  useCloudTvSessions,
  useTimezoneAdjustementForSession,
} from '../blocks/CloudTVBlock/cloudtv.hooks';
import './UpcomingSessions.scss';

function useUpcomingSessions({ mode, workshopFilter, refreshRate, dateField }) {
  const sessions = useCloudTvSessions({ mode, workshopFilter });
  let now = useAutoRefresh(refreshRate || 20000);
  const currentLive = currentLiveSession(sessions, now);
  const nowAdjustement = useTimezoneAdjustementForSession(currentLive);
  now -= nowAdjustement;

  const field = dateField || 'endDate';
  return orderBy(
    sessions.filter((session) => isAfter(parseISO(session[field]), now)),
    ['startDate', 'endDate'],
    ['asc', 'asc'],
  );
}

function filterUpcomingSessions(sessions, collection, filterByTimezone, timezone) {
  if (!collection && !filterByTimezone) return sessions;
  if (collection && !filterByTimezone) return sessions.filter((s) => s.collection === collection);
  if (!collection && filterByTimezone) return sessions.filter((s) => s.timezone === timezone);
  return sessions.filter((s) => s.collection === collection && s.timezone === timezone);
}

const UpcomingSessions = ({
  clickableSession,
  mode = 'agenda',
  container,
  showImages,
  viewAllSessions,
  ...rest
}) => {
  const {
    collection,
    filterByTimezone = false,
    actions,
    workshopFilter,
    dateField,
    refreshRate,
  } = rest;
  const { t } = useTranslations('home');
  const userTimezone = useUserTimezone();
  const sessions = useUpcomingSessions({ mode, workshopFilter, refreshRate, dateField });
  const filteredSessions = filterUpcomingSessions(
    sessions,
    collection,
    filterByTimezone,
    userTimezone,
  );
  return (
    <BlockContainer header={t('upcoming-meetings')} {...container} className="home__upcoming">
      <div className="home__upcoming__sessions">
        <GroupedUserSessions
          actions={actions}
          sessions={filteredSessions}
          showImages={showImages}
          clickableSession={clickableSession}
        />
        <Link to="/profile" className="view-all">
          {t('view-all')} <Icon name="angle right" />
        </Link>
      </div>
      {viewAllSessions?.url && (
        <Button as={Link} to={viewAllSessions?.url} className="view-all-sessions" primary>
          {t('view-all-sessions')}
        </Button>
      )}
    </BlockContainer>
  );
};

UpcomingSessions.defaultProps = {
  container: {},
  mode: undefined,
  viewAllSessions: undefined,
  clickableSession: true,
  showImages: false,
};

UpcomingSessions.propTypes = {
  clickableSession: PropTypes.bool,
  container: PropTypes.object,
  mode: PropTypes.string,
  showImages: PropTypes.bool,
  viewAllSessions: PropTypes.shape({ url: PropTypes.string }),
};

export default UpcomingSessions;
