// eslint-disable-next-line import/no-extraneous-dependencies
import '@firebase/messaging';
import React from 'react';
import { toast } from 'react-toastify';
import { Header } from 'semantic-ui-react';
import store from '../../shared/Store';

function showToast(title, body) {
  toast(
    <div>
      <Header as="h4">{title}</Header>
      <p style={{ whiteSpace: 'pre-line' }}>{body}</p>
    </div>,
    {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  );
}

async function initMessaging(messaging) {
  console.log('Firebase.initNotifications');
  window.messaging = messaging;
  // messaging.use
  try {
    const currentToken = await messaging.getToken({
      vapidKey:
        'BOhX8nq4AMgCKqAPuLM4GOjEKiutxarRAZ9j4IZ7LuhDTj5Ids3A08-vxlANVrjH0-AkuRDmkXwiV-wxKw_75E8',
    });

    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      console.log('Found token', currentToken);

      // Send to server
      fetch(`https://app.appcraft.events/api/${store.eventId}/notifications/register`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: currentToken }),
      })
        .then((res) => res.json())
        .then(console.log)
        .catch(console.error);
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (e) {
    console.warn('ERROR', e);
  }

  messaging.onMessage((payload) => {
    const { notification } = payload;
    console.log('Message received. ', payload);
    if (notification) {
      showToast(notification.title, notification.body);
      if (window.onNotification) {
        window.onNotification(payload.notification);
      }
    }
  });
}

export async function initNotifications(firebase) {
  const { firebaseServiceWorker } = window.__DATA__.endpoints;
  if (!firebaseServiceWorker) {
    console.log('No firebaseServiceWorker');
    return; // Nothing to do
  }

  // setTimeout(() => {
  //   toast(
  //     <div>
  //       <Header as="h3">Long title that will break everything</Header>
  //       <p>Lorem ipsum why not</p>
  //     </div>,
  //     {
  //       position: 'top-right',
  //       autoClose: 6000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     },
  //   );
  // }, 1000);

  if ('serviceWorker' in navigator) {
    // Register a service worker hosted at the root of the
    // site using the default scope.
    navigator.serviceWorker.register(firebaseServiceWorker).then(
      function (registration) {
        console.log('Service worker registration succeeded:', registration);
        const messaging = firebase.messaging();
        messaging.useServiceWorker(registration);
        initMessaging(messaging);
      },
      function (error) {
        console.log('Service worker registration failed:', error);
      },
    );
  } else {
    console.log('Service workers are not supported.');
  }
}
