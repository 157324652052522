import React, { useRef } from 'react';
import { useConfig, useUpdateConfig } from '../config/config.context';
import platformService from '../core/services/platform.service';
import { useVersionSync } from './useVersionSync';

const GlobalConfigSync = () => {
  const update = useUpdateConfig();

  useVersionSync({
    resourceType: 'config',
    id: 'global',
    lazy: true,
    refresh: async ({ refresh } = {}) => {
      if (!refresh) return;
      const newConfig = await platformService.fetchConfig();
      update(newConfig);
    },
  });

  return null;
};

const GlobalConfigSyncDebugShield = () => {
  const state = useRef({ hasShownWarning: false });
  const config = useConfig();

  if (config?.debug?.disableConfigSync) {
    if (!state.current.hasShownWarning) {
      // eslint-disable-next-line no-console
      console.warn('Automated configuration synchronization is disabled.');
      state.current.hasShownWarning = true;
    }
    return null;
  }

  return <GlobalConfigSync />;
};

export default GlobalConfigSyncDebugShield;
