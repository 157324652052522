import cx from 'classnames';
import { parseISO, startOfDay } from 'date-fns';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';
import { useScreenConfig } from '../../../config/screens.context';
import { TrackingContext } from '../../../Context';
import { timeSlotObjectProptypes, userProptypes } from '../../../propTypes';

import { getString } from '../../../utils';
import { localeFormat } from '../../../utils/date';
import './BookAppointmentBlock.scss';
import { bookAppointment } from './utils';

const Slot = ({ time, isActive, onClick }) => (
  <div style={{ marginBottom: 10 }}>
    <Button
      className={cx('BookAppointmentBlock--slots__slot', { active: isActive })}
      style={{ width: 120 }}
      onClick={onClick}
    >
      {time}
    </Button>
  </div>
);

Slot.defaultProps = {
  isActive: false,
};

Slot.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired,
};

const BookingModal = ({
  item,
  onClose,
  timeSlots: { freeTimeSlots, isBookingAvailable },
  translationPrefix,
  agenda,
}) => {
  const [selectedSlot, setSelectedSlot] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useContext(TrackingContext);
  const appointments = useScreenConfig('appointments');
  const showMessage = appointments?.promptMessage || false;

  const timeSlotsByDays = groupBy(freeTimeSlots, (timeSlot) =>
    startOfDay(new Date(timeSlot.startDate)),
  );

  async function handleBook() {
    setLoading(true);
    const success = await bookAppointment(item, selectedSlot, agenda, {
      showMessage,
      trackEvent,
    });
    if (success) {
      onClose();
    } else {
      setLoading(false);
    }
  }

  return (
    <Modal open closeIcon onClose={onClose}>
      <Modal.Header>
        {getString(`${translationPrefix}.slots.meet`)} {item.name}
      </Modal.Header>
      <Modal.Content scrolling className="BookAppointmentBlock--slots__container">
        {freeTimeSlots.length === 0 && (
          <p>{getString(`${translationPrefix}.slots.unavailable-slots`)}</p>
        )}
        {freeTimeSlots.length > 0 && isBookingAvailable && (
          <p>{getString(`${translationPrefix}.slots.available-slots`)}</p>
        )}
        {!isBookingAvailable && (
          <p>{getString(`${translationPrefix}.slots.unavailable-booking`)}</p>
        )}
        <Grid className="BookAppointmentBlock--slots__grid" columns={3} divided centered>
          {isBookingAvailable &&
            keys(timeSlotsByDays).map((day) => {
              const dayFormat = localeFormat(new Date(day), 'PPP');
              const daySlots = timeSlotsByDays[day] || [];
              const slotsByStartDate = groupBy(daySlots, 'startDate');
              return (
                <Grid.Column key={day} textAlign="center">
                  <Header as="h4">{dayFormat}</Header>
                  {keys(slotsByStartDate).map((startDate) => {
                    const slot = slotsByStartDate[startDate][0];
                    return (
                      <Slot
                        key={startDate}
                        isActive={selectedSlot && selectedSlot.startDate === slot.startDate}
                        time={localeFormat(parseISO(startDate), 'p')}
                        onClick={() => setSelectedSlot(slot)}
                      />
                    );
                  })}
                </Grid.Column>
              );
            })}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          loading={loading}
          disabled={loading || !selectedSlot}
          onClick={handleBook}
        >
          {getString(`${translationPrefix}.slots.confirm`)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

BookingModal.defaultProps = {
  timeSlots: {
    isBookingAvailable: false,
    freeTimeSlot: [],
  },
  translationPrefix: 'appointments',
};

BookingModal.propTypes = {
  agenda: PropTypes.arrayOf(PropTypes.object).isRequired,
  item: PropTypes.shape(userProptypes).isRequired,
  timeSlots: timeSlotObjectProptypes,
  translationPrefix: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default BookingModal;
