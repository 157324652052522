import { useBoolean } from 'ahooks';
import { matches, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useConfig } from '../../config/config.context';
import { useScreenConfig } from '../../config/screens.context';
import { replacePath, replaceValues } from '../../utils/stringUtils';
// eslint-disable-next-line import/no-cycle
import WorkshopSessionsModal from '../../workshop-session/components/WorkshopSessionsModal';
// eslint-disable-next-line import/no-cycle
import ExpiringNavLink from '../ExpiringNavLink';
import { sanitizeQueryString } from './entityLink.utils';

const entityModals = {
  WorkshopSessionsModal: ({ entity, onClose }) => (
    <WorkshopSessionsModal workshop={entity} onClose={onClose} showDescription />
  ),
};

function computePath(entity, itemConfig = {}, screensConfig) {
  const { kind, parentPath } = entity;
  const { path, disableItemPage } = itemConfig;
  if (disableItemPage) return null;

  if (kind) {
    switch (kind) {
      case 'workshop':
        return `${path || entity.collection}/${entity.slug || entity._id}`;
      case 'workshops': {
        if (itemConfig?.workshop?.canonicalPath) {
          return replaceValues(itemConfig?.workshop?.canonicalPath, entity);
        }
        if (itemConfig?.[entity.collection]?.canonicalPath) {
          return replaceValues(itemConfig?.[entity.collection]?.canonicalPath, entity);
        }
        if (parentPath) return parentPath;
        return replaceValues(`${path || entity.collection}`, entity);

        // return `${path || entity.collection}`;
      }
      case 'offer': {
        return `/rdv/offers/${entity._id}`;
      }
      // ICI un kind:exihbitor ? pour gérer les url ?

      // eslint-disable-next-line no-empty
      default: {
      }
      // eslint-disable-next-line no-console
      // console.warn('Unhandled entity "kind" path for', entity);
      // return null;
    }
  }

  // Search for

  if (path && typeof path === 'string') return replaceValues(path, entity);
  switch (entity.collection) {
    case 'speakers':
      return `/speakers/${entity.slug || entity._id}`;
    case 'sponsors':
      return `/sponsors/${entity.slug || entity._id}`;
    case 'program': {
      const canonicalPath = screensConfig?.programs?.program?.canonicalPath;
      if (canonicalPath) return replaceValues(canonicalPath, entity);
      return `/programs/${entity.slug || entity._id}`;
    }
    case 'workshops':
    case 'live':
    case 'atelier':
    case 'ateliers': {
      const { workshopPath = '/schedule/{category}/{slug}' } = itemConfig.workshops || {};
      return replaceValues(workshopPath, entity);
    }
    case 'workshop-live': {
      const { workshopPath = '/live/{slug}' } = itemConfig.workshops || {};
      return replaceValues(workshopPath, entity);
    }
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled entity path for', entity);
      return null;
  }
}

function findPageFor(screens, entity) {
  return values(screens).find((page) => {
    const { path, entities } = page;
    return path && entities && entities.find((pageEntity) => matches(pageEntity)(entity));
  });
}

function findModalFor(screens, entity) {
  return values(screens).find((page) => {
    const { entities, pageType } = page;
    return (
      pageType && pageType === 'modal' && entities.find((pageEntity) => matches(pageEntity)(entity))
    );
  });
}

export function useEntityPath(entity) {
  // TODO: introduce entity.kind/type ?
  const { screens } = useConfig();
  const { search } = useLocation();
  const itemConfig = useScreenConfig(
    entity?.kind === 'workshops' ? 'workshops' : entity?.collection,
  );

  let path;
  const page = findPageFor(screens, entity);
  const modal = findModalFor(screens, entity);

  if (page) {
    // TODO: broken by modal router ?
    if (page.pageType === 'modal' && page.modal?.type in entityModals) {
      return { modal: entityModals[page.modal?.type] };
    }
    path = replacePath(page.path, entity);
    if (path) return { path };
  }

  if (modal) {
    const queryString = sanitizeQueryString(search) || '?';
    const prefix = queryString === '?' ? queryString : `${queryString}&`;
    switch (modal.type) {
      case 'modal-speaker':
        return {
          path: `${prefix}modal=speaker&speakerId=${entity._id}`,
        };
      case 'modal-workshop':
        // const parentPath = screens[modal.parent].path; ${parentPath}
        return {
          path: `${prefix}modal=workshop&workshopId=${entity.workshopId || entity._id}`,
        };
      case 'modal-user-profile-networking':
        return {
          path: `?modal=networking&userId=${entity._id}`,
        };
      default: {
        console.warn('Unknown modal type', modal.type);
      }
    }
  }

  // Legacy mode
  return { path: computePath(entity, itemConfig, screens) };
}

function useEntityModal(EntityModal, entity, { onClick: onClickListener }) {
  const [showModal, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

  const modal = useMemo(
    () => showModal && EntityModal && <EntityModal entity={entity} onClose={() => closeModal()} />,
    [entity, showModal, closeModal, EntityModal],
  );
  const onClick = useMemo(() => {
    if (!EntityModal) return onClickListener;
    return (e) => {
      openModal();
      if (onClickListener) {
        onClickListener(e);
      }
    };
  }, [onClickListener, EntityModal, openModal]);
  return { modal, onClick };
}

const EntityLink = ({
  children,
  entity,
  autoHide,
  forcePath,
  onClick: onClickListener,
  ...props
}) => {
  const { path, modal: EntityModal, onClick: onClickModal } = useEntityPath(entity);

  const { modal, onClick: onClickEntity } = useEntityModal(EntityModal, entity, {
    onClick: onClickListener,
  });
  const onClick = onClickModal || onClickEntity;

  if (!path) {
    if (autoHide) return null; // Hide link
    return (
      <>
        <div {...props} onClick={onClick}>
          {children}
        </div>
        {modal}
      </>
    ); // Default to div
  }

  // Don't use ExpiringNavLink for query links

  if (forcePath) {
    return (
      <>
        <ExpiringNavLink {...props} onClick={onClick} to={forcePath}>
          {children}
        </ExpiringNavLink>
        {modal}
      </>
    );
  }

  const Link = path?.includes('?') ? NavLink : ExpiringNavLink;
  return (
    <>
      <Link {...props} onClick={onClick} to={EntityModal ? '#' : path}>
        {children}
      </Link>
      {modal}
    </>
  );
};

EntityLink.defaultProps = {
  autoHide: false,
  onClick: undefined,
  forcePath: undefined,
};

EntityLink.propTypes = {
  autoHide: PropTypes.bool, // If used as a link and not a component, auto-hide link
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  entity: PropTypes.object.isRequired,
  forcePath: PropTypes.string,
};

export default EntityLink;
