import {
  addMinutes,
  subMinutes,
  isBefore,
  parseISO,
  isAfter,
  isTomorrow,
  isToday,
  formatDistance,
} from 'date-fns';
import { dateFNSLocale } from './date';
import { debugNowTime } from './hooks';

export function isNow({ startDate, endDate }) {
  const now = new Date();

  return (
    isBefore(parseISO(startDate), addMinutes(now, 5)) &&
    isAfter(parseISO(endDate), subMinutes(now, 5))
  );
}
export function isUpcoming({ startDate }) {
  const now = new Date();
  return isAfter(parseISO(startDate), now);
}

export function isFinished({ endDate }) {
  const now = new Date();
  return isBefore(parseISO(endDate), now);
}

export function startsToday({ startDate }) {
  return isToday(parseISO(startDate));
}

export function getWorkshopStatus(workshop) {
  if (isFinished(workshop)) return 'finished';
  if (isNow(workshop)) return 'now-soon';
  if (isTomorrow(parseISO(workshop.startDate))) return 'tomorrow';
  if (isUpcoming(workshop)) return 'upcoming';
  return 'upcoming';
}

export function fromNow(date) {
  const now = debugNowTime || new Date();
  if (!date) return null;
  return formatDistance(parseISO(date), now, {
    addSuffix: true,
    locale: dateFNSLocale(),
  });
}
