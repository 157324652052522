import PropTypes from 'prop-types';
import React from 'react';
import { Card, Grid, Icon, Header } from 'semantic-ui-react';
import cx from 'classnames';
import Blocks from '../components/Blocks';

const Title = ({ title, subtitle, icon }) => (
  <Header as="h3" className="card--title">
    {icon && <Icon name={icon} style={{ textAlign: 'left' }} />}
    {title}
    {subtitle && <Header.Subheader>{subtitle}</Header.Subheader>}
  </Header>
);

Title.defaultProps = {
  icon: undefined,
  subtitle: undefined,
};
Title.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

const HomeCard = ({ title, subtitle, icon, children, bordered = true, style = {} }) => {
  return (
    <Card
      className="home--screen card--shadow"
      style={{
        width: '100%',
        padding: 12,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px',
        ...style,
      }}
    >
      {!!title && <Title title={title} subtitle={subtitle} icon={icon} />}
      {bordered && <div className="border" />}
      {children}
    </Card>
  );
};

HomeCard.defaultProps = {
  bordered: true,
  icon: undefined,
  subtitle: undefined,
  style: {},
};
HomeCard.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  bordered: PropTypes.bool,
  style: PropTypes.object,
};

const Tiles = ({ blocks, blockComponents, sharedProps }) => {
  return (
    <>
      {blocks.map((block, index) => {
        const { size, type, template } = block;
        // const { size, type, style, title, subtitle, icon, template, redirectTo } = block;
        const Component = blockComponents[type];
        if (!Component) {
          // eslint-disable-next-line no-console
          console.warn('Unknown block type', block);
          return null;
        }
        return (
          <Grid.Column
            className={cx(
              'home--block',
              `${type}--block`,
              `${template ? `${template}--template` : ''}`,
            )}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            width={size}
          >
            <Blocks blocks={[block]} blockComponents={blockComponents} sharedProps={sharedProps} />
          </Grid.Column>
        );
      })}
    </>
  );
};

Tiles.defaultProps = {
  sharedProps: undefined,
};

Tiles.propTypes = {
  blockComponents: PropTypes.object.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
    }),
  ).isRequired,
  sharedProps: PropTypes.object,
};

export default Tiles;
