import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import './WorkshopSessionRegitrationButton.scss';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { workshopProptypes } from '../../../propTypes';
import { getString } from '../../../utils';
import { useWorkshopSessionRegistration } from '../../store/workshopSessions.hooks';

const translationPrefix = 'workshops.workshop';

const WorkshopSessionRegistrationButton = ({ session, registration }) => {
  const {
    isWorkshopSessionFull,
    isWorkshopSessionEnded,
    loading,
    registerWorkshopSession,
    unregisterWorkshopSession,
  } = useWorkshopSessionRegistration(session);
  if (!session) return null;

  if (isWorkshopSessionEnded) {
    return <p>{getString(`${translationPrefix}.workshop-ended`)}</p>;
  }
  if (registration) {
    return (
      <div className="WorkshopSessionRegistationButton__AlreadyRegistered">
        <Button
          as="a"
          className="unregister-button"
          onClick={unregisterWorkshopSession}
          loading={loading}
          disabled={loading}
        >
          <span> {getString(`${translationPrefix}.unregister`)}</span>
        </Button>
      </div>
    );
  }

  return (
    <div className="WorkshopSessionRegistationButton__Unregistered">
      {session.startDate ? (
        <div>
          {!isWorkshopSessionFull && (
            <>
              <Button
                primary
                className="register-button"
                floated="right"
                onClick={registerWorkshopSession}
                loading={loading}
                disabled={loading}
              >
                {getString(`${translationPrefix}.register`)}
              </Button>
            </>
          )}
        </div>
      ) : (
        <p className="registrations-not-yet-open-label">
          {getString(`${translationPrefix}.registrations-not-yet-open`)}
        </p>
      )}
    </div>
  );
};

WorkshopSessionRegistrationButton.defaultProps = {
  session: undefined,
  registration: undefined,
};

WorkshopSessionRegistrationButton.propTypes = {
  session: PropTypes.shape(workshopProptypes),
  registration: PropTypes.object,
};

export default connect((state, props) => ({
  registration: state.registrations.registrationsById[props.session?.workshopId],
  agenda: userAgenda(state),
}))(WorkshopSessionRegistrationButton);
