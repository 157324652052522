import { get } from 'lodash';
import orderBy from 'lodash/orderBy';
import React from 'react';
import BlockContainer from '../../../components/BlockContainer';
import { Action } from '../../../components/cms/ActionsBlock/ActionsBlock';
import CTATileBlock from '../../../components/cms/CTATileBlock';
import { ContainerProps } from '../../../components/cms/types';
import { useMe } from '../../../profile/hooks';
import store from '../../../shared/Store';
import { ensureDate } from '../../../utils/date';
import { debugNowTime, useAutoRefresh } from '../../../utils/hooks';
import { replaceUriValues } from '../../../utils/stringUtils';
import { useUserAgendaQuery, useWorkshopQuery } from '../../../workshops/store/workshops.hooks';
import { hasActiveLive, upcomingLiveSession } from '../../../workshops/utils/session.utils';
import { useTimeLeft } from '../CountdownBlock';
import './NextLiveCountdownBlock.scss';
import { countdownVariants, CountdownVariants } from './variants';

type WorkshopQuery = {
  from: { collection?: string; category?: string };
};

type Workshop = {
  _id: string;
  startDate: string;
  endDate: string;
  connectionTest?: string;
};

type ActionProps = {
  color?: string;
  variant?: string;
  text: string;
  url: string;
  type: string;
};

type MessageProps = {
  text: string;
};

type CountdownBlockProps = {
  action?: ActionProps;
  background?: string;
  color?: string;
  container?: ContainerProps;
  inverted?: boolean;
  circleSize?: number;
  item: Workshop;
  variant?: CountdownVariants;
  liveMessage?: MessageProps;
  hideIfLive?: boolean;
};

type NextLiveCountdownBlockProps = CountdownBlockProps & {
  query?: WorkshopQuery;
  mode: 'agenda' | 'workshops';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyCtaMessage?: Record<string, any>;
  item?: Workshop;
};

const OptAction = ({ action }: { action: ActionProps | undefined }): JSX.Element | null => {
  if (!action || !action.text || !action.url) return null;
  return (
    <div className="block--actions">
      <Action {...action} />
    </div>
  );
};

const CountdownBlock = ({
  item,
  inverted,
  variant,
  container,
  circleSize,
  action,
  liveMessage,
  hideIfLive,
  ...rest
}: CountdownBlockProps): JSX.Element | null => {
  const timeLeft = useTimeLeft(item?.startDate);
  const me = useMe();
  if (!item) return null; // Finished. TODO: message if finished ?

  if (hideIfLive && hasActiveLive(item)) {
    return null;
  }

  if (!timeLeft) {
    const now = debugNowTime || Date.now();
    if (liveMessage?.text && item.endDate && now < ensureDate(item.endDate).getTime()) {
      return (
        <BlockContainer
          className="block--countdown block--countdown--next-live"
          type="basic"
          {...container}
        >
          <div className="message message--live">{liveMessage?.text}</div>
          <OptAction action={action} />
        </BlockContainer>
      );
    }
    return null; // Auto-hide
  }
  const Component = (variant && get(countdownVariants, variant)) || countdownVariants.square;

  let finalAction = action;
  if (action && action.type === 'connection-test') {
    if (item.connectionTest) {
      finalAction = {
        ...action,
        type: 'iframe-modal',
        url: replaceUriValues(item.connectionTest, { ...me, userId: store?.user?._id }),
      };
    }
  }

  return (
    <BlockContainer
      className="block--countdown block--countdown--next-live"
      type="basic"
      {...container}
    >
      <Component
        {...rest}
        inverted={inverted}
        timeLeft={timeLeft}
        date={item.startDate}
        type={variant}
        circleSize={circleSize}
      />
      <OptAction action={finalAction} />
    </BlockContainer>
  );
};

CountdownBlock.defaultProps = {
  action: undefined,
  background: undefined,
  color: undefined,
  container: undefined,
  inverted: undefined,
  circleSize: undefined,
  variant: undefined,
  liveMessage: undefined,
  hideIfLive: true,
};

const NextLiveCountdownBlock = ({
  query,
  mode,
  emptyCtaMessage,
  ...rest
}: NextLiveCountdownBlockProps): JSX.Element | null => {
  const now = useAutoRefresh(30000);
  const { agenda: userEvents, loaded } = useUserAgendaQuery(query || {});
  const workshops = useWorkshopQuery(query || {});
  const items = mode === 'agenda' ? userEvents : workshops;
  const upcoming = upcomingLiveSession(orderBy(items, 'startDate'), now);

  if (!upcoming) {
    if (!loaded && mode === 'agenda') return null; // Wait for loading

    if (emptyCtaMessage && emptyCtaMessage.title && emptyCtaMessage.to) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return <CTATileBlock {...emptyCtaMessage} />;
    }
    return null;
  }

  return <CountdownBlock {...rest} item={upcoming} />;
};

NextLiveCountdownBlock.defaultProps = { ...CountdownBlock.defaultProps, mode: 'workshops' };

export default NextLiveCountdownBlock;
