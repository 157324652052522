import React from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Images from '../utils/Images';

const Lightbox = (props) => {
  const { views, currentIndex, onClose, components, modalProps, carouselProps } = props;
  const elements = views.map((post) => ({
    ...post,
    source: {
      download: post.image.uri,
      regular: Images.maxHeight(post.image, 800),
      fullscreen: Images.maxHeight(post.image, 1000),
      thumbnail: Images.square(post.image, 32),
    },
    caption: post.message || '',
  }));
  return (
    <ModalGateway>
      <Modal allowFullscreen closeOnBackdropClick={false} onClose={onClose} {...modalProps}>
        <Carousel
          currentIndex={currentIndex}
          components={components}
          views={elements}
          {...carouselProps}
        />
      </Modal>
    </ModalGateway>
  );
};

Lightbox.defaultProps = {
  carouselProps: {},
  components: undefined,
  currentIndex: undefined,
  modalProps: {},
  views: [],
};

Lightbox.propTypes = {
  carouselProps: PropTypes.object,
  components: PropTypes.object,
  currentIndex: PropTypes.number,
  modalProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.object),
};

export default Lightbox;
