import React from 'react';
import Highlighter from 'react-highlight-words';
import { Icon } from 'semantic-ui-react';
import { getString } from '../utils';
import { sweetAlert } from '../utils/popupUtils';

const translationPrefix = 'social-wall';

export function showBlacklistError(res, message) {
  const { code } = res.errors[0];
  if (code === 'E_INAPPROPRIATE_CONTENT') {
    const { message: errorMessage } = res.errors[0];
    const blacklist = JSON.parse(errorMessage);
    sweetAlert({
      icon: 'error',
      title: getString(`${translationPrefix}.post-inappropriate-content`),
      html: (
        <div>
          <Icon size="small" name="quote left" />
          <Highlighter
            className="SocialWall--Error__HighlightedText"
            searchWords={blacklist}
            autoEscape
            textToHighlight={message}
          />
          <Icon size="small" name="quote right" />
        </div>
      ),
    });
  } else {
    sweetAlert({ title: getString('errors.error'), icon: 'error' });
  }
}
