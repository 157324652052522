import get from 'lodash/get';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

// simple alert
export function sweetAlert(options = {}) {
  const { title, text, icon = 'info', ...rest } = options;
  return MySwal.fire({
    title,
    text,
    icon,
    ...rest,
  });
}

// simple alert
export function sweetPrompt(options = {}) {
  const { title, inputLabel, inputPlaceholder, showCancelButton = true, ...rest } = options;
  return MySwal.fire({
    title,
    inputLabel,
    inputPlaceholder,
    input: 'textarea',
    showCancelButton,
    ...rest,
  });
}

// confirm window
export async function sweetConfirmAlert(options = {}) {
  const primaryColor = get(window.__DATA__, ['design', 'primaryColor'], '#afa6a5');
  const result = await sweetAlert({
    showCancelButton: true,
    icon: 'question',
    confirmButtonColor: primaryColor,
    cancelButtonColor: '#afa6a5',
    reverseButtons: true,
    ...options,
  });
  return result.isConfirmed;
}
