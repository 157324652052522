import { useWindowSize } from '@react-hook/window-size';
import { MasonryScroller, useContainerPosition, usePositioner, useResizeObserver } from 'masonic';
import React from 'react';

/**
 * Masonic doesn't support updating/deleting items, this component uses
 * a custom usePositioner that invalidates the cache when items change
 */
const UpdatableMasonic = ({ items, columnWidth, columnGutter, ...props }) => {
  const containerRef = React.useRef(null);
  const [windowWidth, windowHeight] = useWindowSize();
  const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);
  const positioner = usePositioner(
    { width, columnWidth, columnGutter },
    // This is our dependencies array. When these dependencies
    // change, the positioner cache will be cleared and the
    // masonry component will reset as a result.
    [items.length],
  );
  const resizeObserver = useResizeObserver(positioner);

  return (
    <MasonryScroller
      positioner={positioner}
      resizeObserver={resizeObserver}
      containerRef={containerRef}
      items={items}
      height={windowHeight}
      offset={offset}
      columnWidth={columnWidth}
      columnGutter={columnGutter}
      {...props}
    />
  );
};

export default UpdatableMasonic;
