import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import { useFirebase, useFirestoreCollection, useFirestoreDocument } from '../utils/useFirebase';
import store from '../shared/Store';

export function useSocialWallData() {
  const firebase = useFirebase();
  const [messagesRef, userLikesRef] = useMemo(() => {
    const socialWallRef = firebase?.firestore().collection('social-wall').doc(store.eventId);
    return [
      socialWallRef?.collection('messages'),
      socialWallRef?.collection('users').doc(store.userId).collection('likes'),
    ];
  }, [firebase]);
  const { list: posts } = useFirestoreCollection(messagesRef);
  const { list: userLikes } = useFirestoreCollection(userLikesRef);
  const messages = useMemo(() => orderBy(posts, 'createdAt', 'desc'), [posts]);
  const likes = useMemo(() => groupBy(userLikes, 'messageId'), [userLikes]);
  return { messages, likes };
}

export const useUserSocialWallDocument = () => {
  const firebase = useFirebase();
  const userRef = useMemo(
    () =>
      firebase
        ?.firestore()
        .collection('social-wall')
        .doc(store.eventId)
        .collection('users')
        .doc(store.userId),
    [firebase],
  );

  const { item: user, set: setUser } = useFirestoreDocument(userRef);

  return { userRef, user, setUser };
};
