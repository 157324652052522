import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import WorkshopList from '../../components/workshops/WorkshopList';
import './WorkshopsBlock.scss';

const WorkshopsBlock = ({ title, workshops }) => (
  <Grid.Row stretched className="home-block--workshops">
    <Grid.Column width={16}>
      <Segment>
        {title && <Header as="h3">{title}</Header>}
        <WorkshopList centered workshopList={workshops} />
      </Segment>
    </Grid.Column>
  </Grid.Row>
);

WorkshopsBlock.defaultProps = {
  title: undefined,
  workshops: [],
};

WorkshopsBlock.propTypes = {
  title: PropTypes.string,
  workshops: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })),
};

export default connect((state, props) => ({
  workshops: props.workshopIds.map((id) => state.workshops.workshopsById[id]).filter((v) => v),
}))(WorkshopsBlock);
