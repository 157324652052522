import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import Blocks from '../components/Blocks';
import Banner from './blocks/Banner';

const headerBlocks = {
  banner: Banner,
};

function HeaderBlocks({ blocks, wrapperComponent: Wrapper, sharedProps }) {
  const blockComponents = useMemo(
    () =>
      mapValues(headerBlocks, (Original) => (props) => (
        <Wrapper>
          <Original {...props} />
        </Wrapper>
      )),
    [Wrapper],
  );
  return <Blocks blockComponents={blockComponents} blocks={blocks} sharedProps={sharedProps} />;
}
HeaderBlocks.defaultProps = {
  wrapperComponent: React.Fragment,
  sharedProps: undefined,
};
HeaderBlocks.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
    }),
  ).isRequired,
  wrapperComponent: PropTypes.elementType,
  sharedProps: PropTypes.object,
};

export default HeaderBlocks;
