import { useFullscreen } from 'ahooks';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import PageBackground from '../../components/PageBackground';
import { useDesignConfig } from '../../config/design.context';
import { bem } from '../../core/design/bem';
import { useMe } from '../../profile/hooks';
import { validatesCondition } from '../../utils/conditionUtils.ts';
import useLastSeen from '../hooks/useLastSeen';
import useLightbox from '../hooks/useLightbox';
import NewSocialWallPostModal from '../NewSocialWallPostModal';
import '../SocialWall.scss';
import SocialWallCard from '../SocialWallCard';
import { useSocialWallData } from '../useSocialWallData';
import UpdatableMasonic from './UpdatableMasonic';

const translationPrefix = 'social-wall';

const css = bem('SocialWall');

const FullscreenBackground = () => {
  const design = useDesignConfig();
  const { background } = design;
  const { color: backgroundColor, image: backgroundImage } = background || {};

  return (
    <PageBackground
      {...background}
      image={backgroundImage || (!backgroundColor && design.homeBackground)}
    />
  );
};

function SocialWall({ fullscreen, masonryConfig, postCondition }) {
  const [isOpen, setIsOpen] = useState(false);
  const sociallWallRef = useRef();
  const [isFullscreen, { setFull }] = useFullscreen(sociallWallRef);
  const { t } = useTranslation();
  const userProfile = useMe();
  const { columnWidth, columnGutter } = masonryConfig;
  const { messages, likes } = useSocialWallData();
  const { lightbox, openLightbox } = useLightbox(messages);
  const canPost = validatesCondition(postCondition, userProfile);

  useLastSeen(messages);

  return (
    <div className={css.toString()}>
      {lightbox}
      {canPost && (
        <div style={{ textAlign: 'right' }}>
          {fullscreen?.available && (
            <Button className="SocialWall--Button icon" onClick={setFull}>
              <Icon name="expand" />
            </Button>
          )}
          <Button className="SocialWall--Button" onClick={() => setIsOpen(true)}>
            <Icon name="send" />
            {t(`${translationPrefix}.add-message`)}
          </Button>
        </div>
      )}
      <div
        ref={sociallWallRef}
        className={css('Wrapper').state({ fullscreen: isFullscreen }).toString()}
      >
        {isFullscreen && <FullscreenBackground />}
        <UpdatableMasonic
          className="SocialWall--Masonry"
          items={messages}
          itemKey={(data) => (data ? data.id : undefined)}
          columnWidth={columnWidth}
          columnGutter={columnGutter}
          render={({ data: message }) => (
            <SocialWallCard post={message} likes={likes} onClick={() => openLightbox(message.id)} />
          )}
        />
      </div>
      {canPost && isOpen && <NewSocialWallPostModal onClose={() => setIsOpen(false)} />}
    </div>
  );
}

SocialWall.defaultProps = {
  fullscreen: { available: true },
  masonryConfig: {},
  postCondition: undefined,
};

SocialWall.propTypes = {
  fullscreen: PropTypes.shape({ available: PropTypes.bool }),
  masonryConfig: PropTypes.shape({ columnWidth: PropTypes.number, columnGutter: PropTypes.number }),
  postCondition: PropTypes.object,
};

export default SocialWall;
