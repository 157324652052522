/* eslint-disable no-shadow */
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { useConfig } from '../../config/config.context';
import { useDesignConfig } from '../../config/design.context';
import { useScreenConfig } from '../../config/screens.context';
import { useTracking } from '../../Context';
import { bem } from '../../core/design/bem';
import { eventTags } from '../../core/trackers/events';
import { useMe } from '../../profile/hooks';
import store from '../../shared/Store';
import { evalValue } from '../../utils/constraints';
import { handleChangeLanguage, makeLanguagePage } from '../AppHeader';
import CdnImage from '../CdnImage';
import ExpiringNavLink from '../ExpiringNavLink';
import ImageIcon from '../ImageIcon';
import './AppSecondaryMenu.scss';
import { Avatar } from './LogoutButton';
import MenuSearchBar from './MenuSearchBar';
import NavMenuItem from './NavMenuItem';

const css = bem('secondary-menu');
const langClass = bem('lang');

const ProfileItem = ({ path }) => {
  const user = useSelector((state) => state.user.user);
  if (!user) return null;

  return (
    <Menu.Item className="item--profile" as={NavLink} to={path}>
      <Avatar user={user} />
      <span>{user.firstName}</span>
    </Menu.Item>
  );
};

export async function handleChangeTimezone(value) {
  await store.updateUser({ timezone: value });
}

const TimezoneItems = ({ className }) => {
  const { timezones, defaultTimezone } = useConfig();
  const user = useMe();

  if (!timezones) return null;
  return (
    <Menu.Item className={className}>
      <Dropdown
        className="timezones"
        placeholder="Timezone"
        selection
        compact
        options={timezones}
        value={user?.timezone || defaultTimezone}
        onChange={(_e, { value }) => handleChangeTimezone(value)}
      />
    </Menu.Item>
  );
};

TimezoneItems.propTypes = {
  className: PropTypes.string,
};

TimezoneItems.defaultProps = {
  className: '',
};

const LanguagesItem = ({ className, variant }) => {
  const { lang, languages } = useConfig();
  const hasMultipleLanguages = languages && languages.length > 1;
  if (!hasMultipleLanguages) return null;

  if (variant === 'horizontal') {
    return (
      <Menu.Item className={`item--languages item--languages-horizontal ${className || ''}`}>
        {languages.map((lng) => (
          <span
            key={lng.key}
            className={langClass({ [lng.value]: true })
              .state({ active: lng.value === lang })
              .toString()}
          >
            <a href={makeLanguagePage(lng.value)}>{lng.value}</a>
          </span>
        ))}
      </Menu.Item>
    );
  }
  return (
    <Menu.Item className={className}>
      <Dropdown
        className="languages"
        placeholder="Language"
        compact
        selection
        options={languages}
        value={lang}
        onChange={handleChangeLanguage}
      />
    </Menu.Item>
  );
};

export const SecondaryMenuEntry = ({ entry, config }) => {
  const user = useMe();
  const { _id, type, label, pageId, icon, url, condition } = entry;
  const isVisible = condition ? evalValue(condition, { user }) : true;
  const { style: iconStyle, maxHeight = 40 } = config;
  const { trackEvent } = useTracking();
  const page = useScreenConfig(pageId);
  if (!isVisible) return null;
  const path = page?.path || pageId;
  if (pageId && type === 'page' && (!page || isEmpty(page))) return null; // Hidden
  const className = css('item', { _id, pageId, type }).toString();
  switch (type) {
    case 'search': {
      const { searchConfig } = entry;
      return (
        <Menu.Item className={className} key={_id || pageId} style={{ color: '#FFF' }}>
          <MenuSearchBar searchConfig={searchConfig} />
        </Menu.Item>
      );
    }

    case 'languages': {
      return <LanguagesItem key={_id} className={className} {...entry} />;
    }
    case 'social': {
      const { social = [], circular = false } = entry;
      return (
        <Menu.Item className={className}>
          {social.map((s) => {
            const { icon, url, color, style } = s;
            return (
              <a href={url} target="_blank" rel="noreferrer">
                <Button
                  icon
                  circular={circular}
                  color={color}
                  name={icon}
                  style={{ padding: 10, ...style }}
                  onClick={() => trackEvent(eventTags.MENU_CLICK, s)}
                >
                  <Icon name={icon} style={{ fontSize: 18 }} />
                </Button>
              </a>
            );
          })}
        </Menu.Item>
      );
    }
    case 'profile': {
      return <ProfileItem {...entry} />;
    }
    case 'timezones': {
      return <TimezoneItems key={_id} className={className} {...entry} />;
    }
    case 'button':
    case 'page': {
      const { style = {} } = entry;
      return (
        <NavMenuItem
          className={className}
          to={url || path}
          key={_id || pageId}
          exact={false}
          onClick={() => trackEvent(eventTags.MENU_CLICK, entry)}
          style={{ color: '#FFF', cursor: 'pointer', ...style }}
        >
          <ImageIcon
            icon={icon}
            style={{
              height: 40,
              ...iconStyle,
            }}
            maxHeight={maxHeight}
          />
          <label style={{ cursor: 'pointer' }} htmlFor={pageId}>
            {label}
          </label>
        </NavMenuItem>
      );
    }
    default:
      return null;
  }
};
SecondaryMenuEntry.defaultProps = {
  config: {},
};
SecondaryMenuEntry.propTypes = {
  config: PropTypes.object,
  entry: PropTypes.object.isRequired,
};

const AppSecondaryMenu = ({ menu }) => {
  const { secondaryLogo, logoProps = {}, secondaryLogoHeight = 80 } = useDesignConfig();
  const { logoPath = '/' } = logoProps;
  if (!menu) return null;
  const { entries, config } = menu;

  if (!entries) return null;
  return (
    <div className={css('container')}>
      <ExpiringNavLink to={logoPath}>
        <CdnImage
          as="img"
          src={secondaryLogo}
          className="logo--secondary"
          maxHeight={secondaryLogoHeight}
          alt="event logo secondary"
          {...logoProps}
        />
      </ExpiringNavLink>
      <Menu secondary>
        {entries.map((entry) => (
          <SecondaryMenuEntry key={entry._id} entry={entry} config={config} />
        ))}
      </Menu>
    </div>
  );
};

AppSecondaryMenu.defaultProps = {
  menu: undefined,
};
AppSecondaryMenu.propTypes = {
  menu: PropTypes.object,
};

export default AppSecondaryMenu;
