import { every, get, isNaN, some } from 'lodash';

type User = { _id: string; [x: string]: any };

export function isTrue(value: unknown): boolean {
  return value === true || value === 1 || value === '1' || value === 'YES' || value === 'OUI';
}

export function isEqual(v1: unknown, v2: unknown): boolean {
  // eslint-disable-next-line prefer-template
  return (isTrue(v1) && isTrue(v2)) || v1 === v2 || '' + v1 === '' + v2;
}

function isEmpty(value: unknown) {
  return value === '' || value === undefined || value === null;
}

function contains(haystack: unknown, needle: any) {
  if (!haystack) return false;
  if (!needle) return false;
  if (typeof haystack === 'string') {
    return haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1;
  }
  if (Array.isArray(haystack)) {
    return haystack.indexOf(needle) !== -1;
  }
  return false;
}

export type ConditionFunction = (object: Record<string, unknown> | null | undefined) => boolean;

export interface LogicalCondition {
  op: 'and' | 'or';
  expressions: ConditionContext[];
}

export interface StraightCondition {
  op:
    | 'isEqual'
    | 'isNotEqual'
    | 'isEmpty'
    | 'isNotEmpty'
    | 'isAnyOf'
    | 'isNoneOf'
    | 'contains'
    | 'doesntContain'
    | '>'
    | '<'
    | '>='
    | '<=';
  key: string;
  value: any;
}

export type ConditionContext = ConditionFunction | LogicalCondition | StraightCondition;

function isAnyOf(condition: StraightCondition, object: Record<string, any> | null | undefined) {
  const value = get(object, condition.key);
  const { value: values } = condition;
  if (!values) return true;
  return some(values, (v: any) => v === value);
}

function evalCondition(
  condition: ConditionContext | null | undefined,
  object: Record<string, any> | null | undefined,
): boolean {
  if (!condition) return true;
  if (typeof condition === 'object') {
    switch (condition.op) {
      case 'isEqual':
        return isEqual(get(object, condition.key), condition.value);
      case 'isNotEqual':
        return !isEqual(get(object, condition.key), condition.value);
      case 'isEmpty':
        return !get(object, condition.key);
      case 'isNotEmpty':
        return !!get(object, condition.key);
      case 'isAnyOf':
        return isAnyOf(condition, object);
      case 'isNoneOf':
        return !isAnyOf(condition, object);
      case 'contains':
        return contains(get(object, condition.key), condition.value);
      case 'doesntContain':
        return !contains(get(object, condition.key), condition.value);
      case '>':
      case '<':
      case '>=':
      case '<=': {
        let leftValue = get(object, condition.key);
        if (isEmpty(leftValue) || isEmpty(condition.value)) return false;
        leftValue = parseFloat(leftValue);
        if (isNaN(leftValue)) return false;
        switch (condition.op) {
          case '>':
            return leftValue > condition.value;
          case '<':
            return leftValue < condition.value;
          case '>=':
            return leftValue >= condition.value;
          case '<=':
            return leftValue <= condition.value;
          default:
            return false;
        }
      }
      case 'and': {
        const { expressions } = condition;
        if (!expressions || expressions.length === 0) return true;
        return every(expressions, (exp) => evalCondition(exp, object));
      }
      case 'or': {
        const { expressions } = condition;
        if (!expressions || expressions.length === 0) return true;
        return some(expressions, (exp) => evalCondition(exp, object));
      }
      default: {
        console.warn('unknown op', (condition as any).op);
        return false;
      }
    }
  } else {
    return condition(object);
  }
}

export function validatesCondition(
  condition: ConditionContext | undefined,
  user: User | null | undefined,
): boolean {
  return evalCondition(condition, user);
}
