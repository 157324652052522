/* eslint-disable max-len */
/* eslint-disable camelcase */
import cx from 'classnames';
import padStart from 'lodash/padStart';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './CountdownCircle.scss';
import { defaultItemList, timeLeftProps } from './CountdownSquare';

const translationPrefix = 'blocks.countdown';

// From StackOverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeTextArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
    ' ',
  );

  return d;
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, startAngle);
  const end = polarToCartesian(x, y, radius, endAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(
    ' ',
  );

  return d;
}

// From StackOverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

const SVGCircle = ({ radius, stroke, size, label, curved, ...props }) => {
  const { color1 = stroke, color2 = stroke, strokeWidth = 4, textStyle = {}, circle } = props;
  const r = size / 2;
  const hasStroke = color1 && color2;

  return (
    <svg className="countdown-svg" style={{ width: size + 40, height: size + 40 }}>
      <defs>
        <path id="MyPath" fill="none" strokeWidth="4" d={describeTextArc(r, r, r + 20, 0, -180)} />
        {hasStroke && (
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: color1, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: color2, stopOpacity: 1 }} />
          </linearGradient>
        )}
      </defs>
      {circle && <circle cx={r} cy={r} r={r - 6} {...circle} style={{ zIndex: -1 }} />}
      <path
        fill="none"
        style={{ stroke: hasStroke ? `url(#grad1)` : undefined }}
        strokeWidth={strokeWidth}
        d={describeArc(r, r, r - 6, 0, radius)}
      />
      {curved && (
        <text>
          <textPath
            style={{ fontSize: 18, textTransform: 'uppercase', ...textStyle }}
            fill="white"
            href="#MyPath"
          >
            {label}
          </textPath>
        </text>
      )}
    </svg>
  );
};

const CircleProgress = React.memo(({ color, value, label, maxValue, size, curved, ...props }) => (
  <div
    className={cx('countdown-item', { curved, 'countdown-item--no-label': !label })}
    style={{ color, width: size, height: size }}
  >
    <SVGCircle
      curved={curved}
      radius={mapNumber(value, maxValue, 0, 360, 0)}
      stroke={color}
      size={size}
      label={label}
      {...props}
    />
    <div className="label" style={{ display: 'flex', flexDirection: 'column', zIndex: 10 }}>
      <div className="hour">{padStart(value, 2, '0')}</div>
      {label && <span>{label}</span>}
    </div>
  </div>
));

CircleProgress.defaultProps = {
  curved: undefined,
};

CircleProgress.propTypes = {
  curved: PropTypes.boolean,
  color: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
};

const CountdownCircle = React.memo(
  ({ timeLeft, color, inverted, itemList, circleSize, labelOutside, ...props }) => {
    const { t } = useTranslation();
    return (
      <div>
        <div className={cx('countdown-wrapper', { inverted })}>
          {itemList.map(({ key, maxValue }) => (
            <div
              className={`countdown--field-${key}`}
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
            >
              <CircleProgress
                {...props}
                key={key}
                label={!labelOutside && t(`${translationPrefix}.${key}`)}
                value={timeLeft[key]}
                color={color}
                size={circleSize}
                maxValue={maxValue}
              />
              {labelOutside && (
                <div className="countdown-wrapper--label-outside" style={{ color: '#FFF' }}>
                  {t(`${translationPrefix}.${key}`)}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  },
);

export default CountdownCircle;

CountdownCircle.defaultProps = {
  circleSize: 120,
  color: undefined,
  inverted: false,
  itemList: defaultItemList,
  labelOutside: false,
};
CountdownCircle.propTypes = {
  circleSize: PropTypes.number,
  color: PropTypes.string,
  labelOutside: PropTypes.bool,
  inverted: PropTypes.bool,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string.isRequired, maxValue: PropTypes.number }),
  ),
  timeLeft: timeLeftProps.isRequired,
};
