/* eslint-disable prettier/prettier */
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import { useConfig } from '../../../config/config.context';
import { matchDefinedValues } from '../../../utils';
import { useAutoRefresh } from '../../../utils/hooks';
import { useSyncedWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import { useSyncedWorkshops } from '../../../workshops/store/workshops.hooks';
import { currentLiveSession, hasActiveLive } from '../../../workshops/utils/session.utils';

export function useTimezoneAdjustementForSession(currentSession) {
  const { timezones } = useConfig();
  if (timezones && currentSession?.timezone) {
    const timezone = timezones.find((tz) => tz.value === currentSession?.timezone);
    if (timezone?.delayMinutes) {
      return timezone?.delayMinutes * 60 * 1000;
    }
  }
  return 0;
}

export function useCloudTvSessions({ mode = 'agenda', workshopFilter }) {
  const sessions = useSyncedWorkshopSessions();
  const workshops = useSyncedWorkshops();
  const agenda = useSelector(userAgenda);
  return useMemo(() => {
    let userSessions = { sessions, workshops, agenda }[mode] ?? [];
    if (workshopFilter) {
      userSessions = userSessions.filter(matchDefinedValues(workshopFilter));
    }
    return orderBy(userSessions, ['startDate', 'endDate', '_id']);
  }, [mode, sessions, workshops, agenda, workshopFilter]);
}

const injectLiveStream = (entity, liveStream) => {
  return { ...entity, liveStreams: [liveStream] };
};

export function useCurrentCloudTvSession(mode, { forceLiveStream, workshopFilter, refreshRate }) {
  const now = useAutoRefresh(refreshRate || 10000);
  const agenda = useCloudTvSessions({ mode, workshopFilter });
  const currentLive = currentLiveSession(agenda, now);
  const nowAdjustement = useTimezoneAdjustementForSession(currentLive);

  return useMemo(() => {
    let upcomingLive = currentLive;
    if (nowAdjustement) {
      upcomingLive = currentLiveSession(agenda, now - nowAdjustement);
    }

    if (forceLiveStream) {
      return {
        upcomingLive: injectLiveStream(upcomingLive, forceLiveStream),
        hasCurrentLive: true,
      };
    }
    const hasCurrentLive = hasActiveLive(upcomingLive);
    return { upcomingLive, hasCurrentLive };
  }, [now, nowAdjustement, currentLive, forceLiveStream, agenda]);
}
