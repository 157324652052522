import cx from 'classnames';
import React, { useMemo } from 'react';
import { bem } from '../../../core/design/bem';
import VideoTracker from '../../../core/trackers/VideoTracker';
import BlockContainer from '../../BlockContainer';
import VideoEmbed from '../../VideoEmbed';
import { ContainerProps } from '../types';
import './VideoBlock.scss';

const css = bem('VideoBlock');

type Video = {
  uri: string;
  source: string;
  id: string;
};

export type VideoProps = {
  autoPlay?: boolean;
  defaultActive?: boolean;
};

type VideoBlockType = {
  allowFullScreen?: boolean;
  container?: ContainerProps;
  title?: string; // Deprecated
  video: Video;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item?: Record<string, any>;
  videoProps?: VideoProps;
};

const VideoBlock = ({
  title,
  video,
  item,
  allowFullScreen,
  container,
  videoProps,
}: VideoBlockType): JSX.Element | null => {
  const legacyContainer = useMemo(() => {
    if (container) return container;
    return { header: title };
  }, [container, title]);

  if (!video) return null;
  return (
    <BlockContainer
      className={cx(css.toString(), 'block--VideoBlock')}
      type="segment"
      {...legacyContainer}
    >
      <VideoTracker item={item} video={video} videoType="video" />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <VideoEmbed
        defaultActive
        allowFullScreen={allowFullScreen}
        {...videoProps}
        url={video.uri}
        {...video}
        item={item}
        video={video}
      />
    </BlockContainer>
  );
};

VideoBlock.defaultProps = {
  allowFullScreen: true,
  container: undefined,
  title: undefined,
  videoProps: {},
  item: undefined,
};

export default VideoBlock;
