import PropTypes from 'prop-types';
import React from 'react';
import { Label, Menu } from 'semantic-ui-react';

const LanguageMenu = ({ language, languages, onChange, ...props }) => (
  <Menu {...props}>
    {languages.map((lang) => (
      <Menu.Item
        key={lang.value}
        active={language === lang.value}
        onClick={() => onChange(lang.value)}
      >
        {lang.text} {lang.count ? <Label>{lang.count}</Label> : null}
      </Menu.Item>
    ))}
  </Menu>
);

LanguageMenu.defaultProps = {
  language: window.__DATA__.lang,
  languages: window.__DATA__.languages,
};

LanguageMenu.propTypes = {
  language: PropTypes.string,
  languages: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string.isRequired, text: PropTypes.string.isRequired }),
  ),
  onChange: PropTypes.func.isRequired,
};

export default LanguageMenu;
