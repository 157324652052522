/* eslint-disable react/no-danger */
import React from 'react';
import cx from 'classnames';
import { Form, Header, Segment } from 'semantic-ui-react';
// import { Form, Header } from 'semantic-ui-react';
// import { LoginBanner, LoginFooter, LoginColumnsContainer } from './components';
import { LoginBanner, LoginFooter } from './components';
import ExpiringNavLink from '../../../components/ExpiringNavLink';
import PageBackground from '../../../components/PageBackground';
import { useConfig } from '../../../config/config.context';
import { useDesignConfig } from '../../../config/design.context';
import CdnImage from '../../../components/CdnImage';
import { getString } from '../../../utils';
import Styles from '../../../Styles';
import './AsideLoginLayout.scss';

const translationPrefix = 'login';

type Props = React.PropsWithChildren<{
  error?: any;
  screen?: any;
}>;

const AsideLoginLayout = ({ children, error, screen }: Props): JSX.Element => {
  const {
    loginBackground,
    loginBackgroundVideo,
    loginLogo,
    loginLogoProps,
    primaryColor: color,
  } = useDesignConfig();
  const config = useConfig();
  const { isPlatformClosed } = config;

  return (
    <>
      <Styles />
      {/* <LoginColumnsContainer className={cx('screen-auth', screen && `screen-auth--${screen}`)}> */}
      <div
        className={cx(
          'screen-auth',
          screen && `screen-auth--${screen}`,
          // TODO use bem for className with variant
          'screen-auth--variant-aside',
        )}
      >
        <PageBackground image={loginBackground} video={loginBackgroundVideo} />
        <aside className="screen-auth__sidebar">
          {loginLogo && (
            <ExpiringNavLink to="/">
              <CdnImage
                className="authentication--logo"
                as="img"
                src={loginLogo}
                maxWidth={422}
                maxHeight={100}
                alt="event logo"
                {...loginLogoProps}
              />
            </ExpiringNavLink>
          )}
          <p className="authentication--welcome">
            {getString(`${translationPrefix}.welcome-to-the-platform`)}
          </p>
          <Form size="large" error={!!error}>
            <Segment className="authentication--container" basic>
              <LoginBanner />
              {isPlatformClosed && (
                <div className="platform-closed">
                  <Header as="h2" style={{ color }}>
                    {getString(`${translationPrefix}.platform-closed-title`)}
                  </Header>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: getString(`${translationPrefix}.platform-closed-content`),
                    }}
                  />
                </div>
              )}
              {!isPlatformClosed && children}
            </Segment>
          </Form>
          <LoginFooter />
        </aside>
      </div>
      {/* </LoginColumnsContainer> */}
    </>
  );
};

export default AsideLoginLayout;
