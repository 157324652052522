const timeSlotReducer = (state = { timeSlots: {} }, action) => {
  switch (action.type) {
    case 'SET_TIMESLOTS': {
      const { timeSlots: sponsorTimeSlots, sponsorId } = action;
      return {
        timeSlots: {
          ...state.timeSlots,
          [sponsorId]: sponsorTimeSlots,
        },
      };
    }
    default:
      return state;
  }
};

export function setTimeSlots(timeSlots, sponsorId) {
  return {
    type: 'SET_TIMESLOTS',
    timeSlots,
    sponsorId,
  };
}

export default timeSlotReducer;
