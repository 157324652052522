/* eslint-disable import/no-cycle */
import platformService from '../../core/services/platform.service';
// eslint-disable-next-line import/no-cycle
import { setUsers } from '../../core/users/users.actions';
import store from '../../shared/Store';
import { setWorkshopSessions } from '../../workshop-session/store/workshopSessions.actions';
import { setWorkshops } from '../../workshops/store/workshops.actions';
import { setAppointments } from '../reducers/appointments';
import { setRegisrations } from '../reducers/registrations';
import { setTimeSlots } from '../reducers/timeSlots';
import { setStoreUser } from '../reducers/user';

/**
 * WORKSHOP
 */

export function fetchUser() {
  return async (dispatch) => {
    const user = await platformService.fetchUser();
    // if user doesn't exist anymore (has been deleted) => logout
    if (user.error) {
      store.disconnect();
    }
    dispatch(setStoreUser(user));
  };
}

export function fetchTimeSlots(collection, groupId) {
  return async (dispatch) => {
    const timeSlots = await platformService.fetchTimeSlots(collection, groupId);
    dispatch(setTimeSlots(timeSlots, groupId));
  };
}

export function fetchRegistrations() {
  return async (dispatch) => {
    const { registrations } = await platformService.fetchRegistrations();
    if (registrations) {
      dispatch(setRegisrations(registrations));
    }
  };
}

export function fetchAppointments() {
  return async (dispatch) => {
    const appointments = await platformService.fetchAppointments();
    dispatch(setAppointments(appointments));
  };
}

export function dispatchData(data) {
  const { workshops, workshopSessions, schema } = data;
  return (dispatch) => {
    if (workshops) dispatch(setWorkshops(workshops));
    if (schema?.contributors) {
      schema?.contributors.forEach((collection) =>
        dispatch(setUsers(collection, data[collection])),
      );
    }
    if (workshopSessions) dispatch(setWorkshopSessions(workshopSessions));
  };
}
