import PropTypes from 'prop-types';
import React from 'react';
import BlockContainer from '../../components/BlockContainer';
import { containerTypePropTypes } from '../../propTypes';
import SocialWall from '../components/SocialWall';
import '../SocialWall.scss';

function SocialWallBlock({ header, containerType, ...rest }) {
  return (
    <BlockContainer className="block--socialWall" header={header} type={containerType}>
      <SocialWall {...rest} />
    </BlockContainer>
  );
}

SocialWallBlock.defaultProps = {
  containerType: 'basic',
  header: undefined,
  masonryConfig: {},
};

SocialWallBlock.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  masonryConfig: PropTypes.shape({ columnWidth: PropTypes.number, columnGutter: PropTypes.number }),
};

export default SocialWallBlock;
