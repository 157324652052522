/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable operator-linebreak */
import cx from 'classnames';
import { parseISO } from 'date-fns';
import findIndex from 'lodash/findIndex';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';
import { useSelector } from 'react-redux';
import { Button, Header, Icon, Label, Modal } from 'semantic-ui-react';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
// eslint-disable-next-line import/no-cycle
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import CdnImage from '../../../components/CdnImage';
import EntityLink from '../../../components/EntityLink';
import HtmlTemplate from '../../../components/HtmlTemplate';
import SpeakerAvatars from '../../../components/speakers/SpeakerAvatars';
import WorkshopLogos from '../../../components/workshops/WorkshopLogos';
import { bem } from '../../../core/design/bem';
import PageMeta from '../../../core/metadata/PageMeta';
import { trackModal } from '../../../core/trackers';
import { workshopArrayProptypes } from '../../../propTypes';
import { useModalEntries } from '../../../store/reducers/modalEntries.hooks';
import { useEntityTags, useOrderBy } from '../../../utils/hooks';
import Images from '../../../utils/Images';
import { GLOBAL_MEDIA_QUERIES } from '../../../utils/mediaQueries';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import { useSyncedCollectionWorkshops } from '../../store/workshops.hooks';
// eslint-disable-next-line import/no-cycle
import WorkshopActions from '../WorkshopActions';
import { useWorkshopToRegister } from './hooks';
import './WorkshopModal.scss';

const translationPrefix = 'workshops.workshop.modal';

function asWorkshop(entity) {
  if (!entity) return entity;
  return { kind: 'workshops', ...entity };
}

const css = bem('WorkshopModal');

export const EntityTags = ({ entity, type, tagFields }) => {
  const tags = useEntityTags(entity, type, tagFields);
  if (!tags || !tags.length) return null;
  return (
    <div className="tags">
      {tags.map((tag) => (
        <Label className="tags--tag" key={tag}>
          {tag}
        </Label>
      ))}
    </div>
  );
};

EntityTags.defaultProps = {
  tagFields: [],
};

EntityTags.propTypes = {
  entity: PropTypes.object.isRequired,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string.isRequired,
};

const FooterButton = ({ type, icon, entity }) => {
  const { t } = useTranslation();
  const label = t(`${translationPrefix}.${type}`);
  const isNext = type === 'next';
  const disabled = !entity;

  const Component = entity ? EntityLink : 'div';

  return (
    <Component entity={entity} className={cx(type, { disabled })}>
      {isNext && label}
      <Button
        circular
        icon={icon}
        style={{ marginLeft: isNext ? 10 : 0, marginRight: isNext ? 0 : 10 }}
        disabled={disabled}
      />
      {!isNext && label}
    </Component>
  );
};

FooterButton.defaultProps = {
  entity: undefined,
};

FooterButton.propTypes = {
  type: PropTypes.string.isRequired,
  entity: PropTypes.bool,
  icon: PropTypes.string.isRequired,
};

const defaultOrder = [{ field: 'startDate', order: 'asc' }];

export const SessionDates = ({ registration, workshopSessions }) => {
  const { t } = useTranslation();

  if (!workshopSessions || workshopSessions.length === 0) return null;
  return (
    <div className="session-dates">
      <Icon name="calendar outline" style={{ marginRight: 8 }} />
      {workshopSessions.map((workshopSession, idx) => {
        const { _id: sessionId, startDate: sessionStartDate, timezone } = workshopSession;
        const formattedDate = moment(parseISO(sessionStartDate)).tz(timezone);
        const isRegistered = registration?._id === sessionId;
        return (
          <span style={{ marginRight: 4 }} className={cx('date', { active: isRegistered })}>
            {t(`${translationPrefix}.session-date`, {
              startDate: formattedDate,
              timezone,
            })}
            {idx !== workshopSessions.length - 1 && '  /  '}
          </span>
        );
      })}
    </div>
  );
};

SessionDates.defaultProps = {
  workshopSessions: [],
  registration: undefined,
};

SessionDates.propTypes = {
  workshopSessions: workshopArrayProptypes,
  registration: PropTypes.object,
};

function WorkshopModal(props) {
  const {
    collection,
    orderBy: orderByConfig,
    workshopId,
    onClose,
    itemProps,
    metadata,
    ...modalConfig
  } = props;

  const {
    showSpeakers = false,
    speakersConfig,
    tagFields = [],
    actions = [],
    saveOnSessionsOnly = false,
    imageProps = {},
    logosProps,
  } = itemProps;
  const { t } = useTranslation();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const isDesktop = matches.desktop;

  const modalEntries = useModalEntries('workshops', collection);

  const syncedWorkshops = useOrderBy(useSyncedCollectionWorkshops(collection), orderByConfig);
  const session = syncedWorkshops.find((w) => w._id === workshopId);
  useEffect(() => {
    if (session) {
      trackModal(modalConfig, session);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfig.type, modalConfig.modalKey, workshopId, !!session]);
  const syncedWorkshopSessions = useOrderBy(
    useSyncedCollectionWorkshopSessions(collection),
    orderByConfig,
  );
  const [isOpen, setIsOpen] = useState(false);
  const workshopSessions = syncedWorkshopSessions.filter((s) => s.workshopId === workshopId);
  const workshopToRegister = useWorkshopToRegister(
    workshopId,
    syncedWorkshops,
    workshopSessions,
    saveOnSessionsOnly,
  );
  const events = useSelector(userAgenda);
  const registration = events.find((e) => e.workshopId === workshopId);

  if (!session) return null;

  const { title, image, startDate, endDate, description, speakers, logos } = session;
  const hasImage = Images.exists(image);

  const index = findIndex(modalEntries, (w) => w.workshopId === workshopId);

  return (
    <>
      <Modal
        className={css().toString()}
        open
        onClose={() => {
          onClose({ workshopId: undefined, modal: undefined });
        }}
        closeIcon
      >
        {metadata && <PageMeta {...metadata} item={session} />}
        <Modal.Header as="h3" className={css('header').toString()}>
          {startDate && t(`${translationPrefix}.date-time`, { startDate, endDate })}
        </Modal.Header>
        <Modal.Content className={css('content').toString()} scrolling>
          <div style={{ flex: 1 }}>
            {hasImage && (
              <div className="image" style={{ flexShrink: 0 }}>
                <CdnImage
                  key={image?.uri}
                  src={image}
                  maxWidth={900}
                  maxHeight={280}
                  func="crop"
                  {...imageProps}
                />
              </div>
            )}
            <div className="container">
              <WorkshopLogos logos={logos} {...logosProps} />
              <Header as="h2" className="title">
                {title}
              </Header>
              <SessionDates workshopSessions={workshopSessions} registration={registration} />
              {description && <HtmlTemplate className="description" template={description} />}
              {showSpeakers && speakers?.length > 0 && (
                <div className="speakers">
                  <span className="with-speakers" style={{ minWidth: 50 }}>
                    {t(`${translationPrefix}.with`)}
                  </span>
                  <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />
                </div>
              )}
              <EntityTags tagFields={tagFields} entity={session} type="workshop" />
            </div>
          </div>
          <div className="footer">
            {!isDesktop && (
              <WorkshopActions
                actions={actions}
                workshop={workshopToRegister}
                onClick={setIsOpen}
              />
            )}
            <div className="controls">
              <FooterButton
                type="previous"
                icon="chevron left"
                entity={asWorkshop(modalEntries?.[index - 1])}
              />
              {isDesktop && (
                <WorkshopActions
                  actions={actions}
                  workshop={workshopToRegister}
                  onClick={setIsOpen}
                />
              )}
              <FooterButton
                type="next"
                icon="chevron right"
                entity={asWorkshop(modalEntries?.[index + 1])}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
      {isOpen && (
        <UserRegistrationModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
}

WorkshopModal.defaultProps = {
  itemProps: {},
  metadata: undefined,
  onChange: undefined,
  orderBy: defaultOrder,
  sessionId: undefined,
  workshopId: undefined,
  workshops: [],
};

WorkshopModal.propTypes = {
  collection: PropTypes.string.isRequired,
  itemProps: PropTypes.object,
  metadata: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  orderBy: PropTypes.object,
  sessionId: PropTypes.string,
  workshopId: PropTypes.string,
  workshops: workshopArrayProptypes,
};

export default WorkshopModal;
