import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

function LiveSessionsBlock(props) {
  const { items } = props;
  return (
    <List>
      {items.map((item) => {
        const { title, startDate } = item;
        return (
          <List.Item>
            <List.Content>
              <List.Item>
                <List.Header>{title}</List.Header>
                {startDate}
              </List.Item>
            </List.Content>
          </List.Item>
        );
      })}
    </List>
  );
}

LiveSessionsBlock.defaultProps = {
  items: [],
};

LiveSessionsBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default LiveSessionsBlock;
