import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon } from 'semantic-ui-react';

import './CloudTvLiveDescription.scss';

import HtmlTemplate from '../../../../components/HtmlTemplate';
import SpeakerAvatars from '../../../../components/speakers/SpeakerAvatars';
import WorkshopLogos from '../../../../components/workshops/WorkshopLogos';
import { bem } from '../../../../core/design/bem';
import { EntityTags } from '../../../../workshops/components/WorkshopTable/WorkshopModal';

const css = bem('CloudTvLiveDescription');

function CloudTvLiveDescription(props) {
  const { session, isOpen, onClose, ...rest } = props;
  const { tagFields = [], showSpeakers = false, speakersConfig = {}, logosProps } = rest;
  const { t } = useTranslation();
  const { logos, title, description, speakers } = session;
  if (!isOpen) return null;
  return (
    <div className={css()}>
      <Icon name="times" style={{ cursor: 'pointer' }} onClick={onClose} />
      <WorkshopLogos logos={logos} {...logosProps} />
      <Header as="h2" className="title">
        {title}
      </Header>
      {description && <HtmlTemplate className="description" template={description} />}
      <EntityTags tagFields={tagFields} entity={session} type="workshop" />
      {showSpeakers && speakers?.length > 0 && (
        <div className="speakers">
          <span className="with-speakers" style={{ minWidth: 50 }}>
            {t('workshops.workshop.modal.with')}
          </span>
          <SpeakerAvatars speakerIds={speakers} {...speakersConfig} />
        </div>
      )}
    </div>
  );
}

CloudTvLiveDescription.defaultProps = {
  isOpen: true,
};

CloudTvLiveDescription.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
};

export default CloudTvLiveDescription;
