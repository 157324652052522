import { useConfig } from './config.context';

export const useScreensConfig = () => {
  return useConfig()?.screens;
};

export function useScreenConfig(name) {
  const screenConfig = useScreensConfig() || {};
  // Backwards-compatible
  if (name === 'workshop') return screenConfig[name] ?? screenConfig.workshops?.[name] ?? {};
  return screenConfig[name] ?? {};
}
