import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import '../LiveQuestions.scss';
import { containerTypePropTypes } from '../../../propTypes';
import { useLiveQuestion } from '../../../hooks/useLivequestions';
import CompactBar from './variant/CompactBar';

const components = {
  compactBar: CompactBar,
};

const LiveQuestions = (props) => {
  const { variant, sessionId } = props;
  const Component = components[variant] || CompactBar; // TODO: replace CompactBar by a component LiveQuestionClassic
  const liveQuestionStore = useLiveQuestion(sessionId);
  return <Component {...props} {...liveQuestionStore} />;
};

LiveQuestions.defaultProps = {
  containerType: 'headerless',
  header: undefined,
  variant: undefined,
};

LiveQuestions.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  sessionId: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

export default connect((state) => ({
  ...state.user,
}))(LiveQuestions);
