import { useUnmount } from 'ahooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalEntries } from './modalEntries';

export function useModalEntries(kind, collection) {
  return useSelector((state) => state.modalEntries?.[kind]?.[collection]);
}

export function useUpdateModalEntries(kind, collection, items) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setModalEntries(kind, collection, items));
  }, [dispatch, kind, collection, items]);
  useUnmount(() => {
    dispatch(setModalEntries(kind, collection, []));
  });
}
