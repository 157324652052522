/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { bem } from '../../core/design/bem';
import BlockContainer from '../BlockContainer';
import { BlockProps, ContainerProps } from '../cms/types';
import './HeaderBlock.scss';
import HeaderSlide from './HeaderSlide';

const css = bem('HeaderBlock');

type SlideProps = {
  type: string;
  [x: string]: any;
};

type HeaderBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  height?: number;
  blocks?: SlideProps[];
  carousel?: Record<string, any>;
} & typeof HeaderBlockDefaultProps;

const HeaderBlockDefaultProps = {
  carousel: {},
  className: '',
  height: 350,
  blocks: [],
  container: {},
};

const HeaderBlock = ({
  _id,
  className,
  container,
  carousel,

  blocks = [],
}: HeaderBlockProps): JSX.Element | null => {
  if (blocks.length === 0) return null;
  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      <div className={css('container')}>
        {blocks.length === 1 ? (
          <HeaderSlide {...blocks[0]} />
        ) : (
          <Carousel showThumbs={false} {...carousel}>
            {blocks?.map((slide: SlideProps, index: number) => {
              return <HeaderSlide key={slide._id} className={`slide-${index}`} {...slide} />;
            })}
          </Carousel>
        )}
      </div>
    </BlockContainer>
  );
};

HeaderBlock.defaultProps = HeaderBlockDefaultProps;

export default HeaderBlock;
