/* eslint-disable jsx-a11y/media-has-caption */
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { getString } from '../../utils';
import { Replay } from '../../workshops/blocks/ReplayBlock';
import './VideoModal.scss';

const ReplayModal = (props) => {
  const { item, replay, autoPlay, ...modalProps } = props;
  const { title } = item;
  const { value } = replay;
  return (
    <Modal className="VideoModal" basic closeIcon {...modalProps}>
      <Modal.Content>
        <Replay
          icon="play"
          defaultActive={autoPlay}
          replay={replay}
          item={item}
          {...replay}
          url={value.uri}
        />
      </Modal.Content>
      {title && (
        <Modal.Header className="VideoModal--Header">
          {title}
          {value.downloadUri && (
            <Button floated="right" color="blue" as="a" target="_blank" href={value.downloadUri}>
              {getString('btn.download')}
            </Button>
          )}
        </Modal.Header>
      )}
    </Modal>
  );
};
ReplayModal.defaultProps = {
  autoPlay: true,
};
ReplayModal.propTypes = {
  autoPlay: PropTypes.bool,
  item: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  replay: PropTypes.shape({ uri: PropTypes.string.isRequired }).isRequired,
};

export default ReplayModal;
