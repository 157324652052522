import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Card, Header } from 'semantic-ui-react';
import noop from 'lodash/noop';
import { generateGroups } from '../utils/groupUtils';

const ItemCardGroup = ({
  centered,
  className,
  groupClassName,
  items,
  itemsPerRow,
  component: Component,
  groupBy: groupByConfig,
  onClick,
}) => {
  if (!isEmpty(groupByConfig)) {
    const groups = generateGroups(items, groupByConfig);
    return (
      <div className={`items--group items--group--${groupClassName}`}>
        {groups.map((group) => (
          <div
            key={group.id}
            className={`${groupClassName}--group ${groupClassName}--group--${group.id}`}
          >
            <Header as="h3">{group.label}</Header>
            <ItemCardGroup
              className={className}
              items={group.items}
              itemsPerRow={itemsPerRow}
              component={Component}
              onClick={onClick}
              centered={centered}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <Card.Group className={className} centered={centered} itemsPerRow={itemsPerRow}>
      {items.map((item) => (
        <Component key={item._id} item={item} onClick={onClick} />
      ))}
    </Card.Group>
  );
};

ItemCardGroup.defaultProps = {
  centered: true,
  className: '',
  groupBy: undefined,
  groupClassName: undefined,
  itemsPerRow: undefined,
  onClick: noop,
};

ItemCardGroup.propTypes = {
  centered: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  groupBy: PropTypes.shape({
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  groupClassName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })).isRequired,
  itemsPerRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default ItemCardGroup;
