import { useMount } from 'ahooks';
import pick from 'lodash/pick';
import { stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import ForgottenPasswordScreen from './authentication/ForgottenPasswordScreen';
import LoginScreen from './authentication/LoginScreen';
import ResetPasswordScreen, { AutoLoginScreen } from './authentication/ResetPasswordScreen';
import BackToTopArrow from './components/BackToTopArrow';
import ScrollToTop from './components/ScrollToTop';
import { useConfig, useUpdateConfig } from './config/config.context';
import { TrackingContext } from './Context';
import platformService from './core/services/platform.service';
import { trackEvent, trackPage } from './core/trackers';
import Layout from './Layouts/Layout';
import CheckMustResetPassword from './profile/components/CheckMustResetPassword';
import store, { getUrlParameters } from './shared/Store';
import Styles from './Styles';
import GlobalConfigSync from './utils/GlobalConfigSync';
import { FirebaseProvider } from './utils/useFirebase';
import { SessionCheck } from './utils/useSessionCheck';

function ensureFreshConfig(Component) {
  return (props) => {
    const { menu, debug } = useConfig();
    const updateConfig = useUpdateConfig();
    useMount(async () => {
      if (debug?.disableConfigSync) return;
      updateConfig(await platformService.fetchConfig());
    });
    if (!menu) return null; // Menu isn't loaded yet
    return <Component {...props} />;
  };
}

const ConnectedApp = ensureFreshConfig(() => {
  const { isPlatformClosed } = useConfig();
  if (isPlatformClosed) {
    store.disconnect();
  }

  return (
    <Provider store={store.reduxStore}>
      <FirebaseProvider>
        <Layout />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop
          closeOnClick
          pauseOnHover={false}
          progressClassName="toastify-progress-bar"
        />
        <GlobalConfigSync />
        <SessionCheck />
        <BackToTopArrow />
        <CheckMustResetPassword />
      </FirebaseProvider>
    </Provider>
  );
});

const PrivateRoute = ({ component: Component, render, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // eslint-disable-next-line no-nested-ternary
      !store.mustRedirectToLogin() ? (
        Component ? (
          <Component {...props} />
        ) : (
          render(props)
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: `?${stringify(pick(getUrlParameters(), ['u', 'email', 'modal']))}`,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { screens } = useConfig();
  useEffect(() => {
    if (screens) {
      trackPage(location.pathname, screens);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, !!screens]);

  useEffect(() => {
    store.checkConnection().then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return null;
  }
  return (
    <TrackingContext.Provider value={{ trackEvent }}>
      <Styles />
      <ScrollToTop />
      <Switch>
        <Route exact path="/login" component={LoginScreen} />
        <Route exact path="/resetPassword" component={ResetPasswordScreen} />
        <Route exact path="/forgottenPassword" component={ForgottenPasswordScreen} />
        <Route exact path="/auth/autoLogin" component={AutoLoginScreen} />
        <PrivateRoute path="/" component={ConnectedApp} />
      </Switch>
    </TrackingContext.Provider>
  );
};

export default App;
