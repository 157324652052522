import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';

import { BlockProps, ContainerProps, Image } from '../types';

import { bem } from '../../../core/design/bem';
import Images from '../../../utils/Images';
import BlockContainer from '../../BlockContainer';
import CdnImage from '../../CdnImage';

import ImageLightbox from '../FilesBlock/components/ImageLightbox';

const css = bem('PhotoGalleryBlock');

type ImageProps = {
  _id: string;
  image?: Image;
  title?: string;
};

type PhotoProps = {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  photo?: Record<string, any>;
};

type PhotoGalleryBlockProps = BlockProps & {
  className?: string;
  container?: ContainerProps;
  images?: ImageProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  galleryProps?: Record<string, any>;
} & typeof PhotoGalleryBlockDefaultProps;

const PhotoGalleryBlockDefaultProps = {
  container: {},
  className: '',
  galleryProps: {},
  images: [],
};

function formatPhotos(images: ImageProps[]) {
  return images?.map(({ _id: imageId, image }) => {
    return {
      key: imageId,
      src: image?.uri,
      cdn: Images.cdn(image),
      srcSet: [200, 300, 500, 800, 1024, 1600].map(
        (size) => `${Images.maxWidth(image, size)} ${size}w`,
      ),
      width: image?.width,
      height: image?.height,
    };
  });
}

const PhotoGalleryBlock = ({
  _id,
  className,
  container,
  galleryProps,
  images,
}: PhotoGalleryBlockProps): JSX.Element => {
  const [lightbox, setLightbox] = useState(false);
  const photos = formatPhotos(images);
  const renderImage = ({ index, photo }: PhotoProps) => {
    return (
      <div key={index}>
        <CdnImage {...photo} />
      </div>
    );
  };

  return (
    <BlockContainer
      {...container}
      className={css({
        id: _id,
      }).mix(className)}
    >
      {lightbox && (
        <ImageLightbox
          images={images.map(({ image, title }) => ({ ...image, title }))}
          onClose={() => setLightbox(false)}
        />
      )}
      <Gallery
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        photos={photos}
        {...galleryProps}
        renderImage={renderImage}
        onClick={() => setLightbox(true)}
      />
    </BlockContainer>
  );
};

PhotoGalleryBlock.defaultProps = PhotoGalleryBlockDefaultProps;

export default PhotoGalleryBlock;
