/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Header } from 'semantic-ui-react';

import './WorkshopOvhCard.scss';

import WorkshopDate from '../WorkshopDate';

import { userAgenda } from '../../../agenda/store/agenda.selectors';
import UserRegistrationModal from '../../../authentication/components/UserRegistrationModal/UserRegistrationModal';
import { bem } from '../../../core/design/bem';
import { workshopProptypes } from '../../../propTypes';
import Images from '../../../utils/Images';
import { useSyncedCollectionWorkshopSessions } from '../../../workshop-session/store/workshopSessions.hooks';
import WorkshopActions from '../../../workshops/components/WorkshopActions';
import { SessionDates } from '../../../workshops/components/WorkshopModal/WorkshopModal';
import { useWorkshopToRegister } from '../../../workshops/components/WorkshopModal/hooks';
import { EntityTags } from '../../../workshops/components/WorkshopTable/WorkshopModal';
import { useSyncedCollectionWorkshops } from '../../../workshops/store/workshops.hooks';
import EntityLink from '../../EntityLink';

const css = bem('workshop-card-ovh');

function useWorkshopSessions(collection, workshopId) {
  return useSyncedCollectionWorkshopSessions(collection).filter((s) => s.workshopId === workshopId);
}

function useRegistration(collection, workshopId, saveOnSessionsOnly) {
  const syncedWorkshops = useSyncedCollectionWorkshops(collection);
  const syncedWorkshopSessions = useWorkshopSessions(collection, workshopId);

  return useWorkshopToRegister(
    workshopId,
    syncedWorkshops,
    syncedWorkshopSessions,
    saveOnSessionsOnly,
  );
}
function WorkshopOvhCard(props) {
  const { workshop, agendaCard, tagFields, actions, ...rest } = props;
  const { saveOnSessionsOnly } = rest;
  const {
    _id: workshopId,
    title,
    collection,
    image,
    shortDescription,
    startDate,
    endDate,
  } = workshop;
  const [isOpen, setIsOpen] = useState(false);
  const workshopToRegister = useRegistration(collection, workshopId, saveOnSessionsOnly);
  const events = useSelector(userAgenda);
  const registration = events.find((e) => e.workshopId === workshopId);
  const workshopSessions = useWorkshopSessions(collection, workshopId);
  return (
    <div className={css()}>
      <Card as={agendaCard ? EntityLink : undefined} entity={agendaCard ? workshop : undefined}>
        <Card.Content className={css('container').toString()}>
          {image && image.uri && (
            <div
              className="image"
              style={{
                backgroundImage: `url(${Images.cdn(image)})`,
              }}
            >
              {actions.length > 0 && (
                <div className="overlay">
                  <WorkshopActions
                    actions={actions}
                    workshop={workshopToRegister}
                    onClick={setIsOpen}
                  />
                </div>
              )}
            </div>
          )}
          <div className={css('content')}>
            <div style={{ flex: 1 }}>
              {title && (
                <Header className="title" as="h3">
                  {title}
                </Header>
              )}
              <Card.Description className="date">
                <WorkshopDate startDate={startDate} endDate={endDate} />
                <SessionDates workshopSessions={workshopSessions} registration={registration} />
              </Card.Description>
              {shortDescription && (
                <Card.Description className="description">{shortDescription}</Card.Description>
              )}
              <EntityTags tagFields={tagFields} entity={workshop} type="workshop" />
            </div>
          </div>
        </Card.Content>
      </Card>
      {isOpen && (
        <UserRegistrationModal
          onClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
}

WorkshopOvhCard.defaultProps = {
  actions: [],
  agendaCard: false,
  tagFields: [],
};

WorkshopOvhCard.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  agendaCard: PropTypes.bool,
  tagFields: PropTypes.arrayOf(PropTypes.string),
  workshop: workshopProptypes.isRequired,
};

export default WorkshopOvhCard;
