import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'semantic-ui-react';
import CdnImage from '../../components/CdnImage';
import useTranslations from '../../hooks/useTranslations';

import './SsoLogin.scss';

const translationPrefix = 'login.sso';

const SsoLogin = ({ sso }) => {
  const { t } = useTranslations(translationPrefix);

  if (sso.length === 0) return null;

  const validSSO = sso.filter((s) => s.url);

  return (
    <div className="sso-authentication">
      {validSSO.map(({ image, label, url }) => (
        <Button basic className="sso--button" fluid href={url} key={label}>
          {image && (
            <CdnImage
              className="sso--logo"
              as="img"
              src={image}
              maxHeight={45}
              maxWidth={130}
              alt="event logo"
            />
          )}
          <span>{t('connect-with', { label })}</span>
        </Button>
      ))}
      <Divider horizontal>{t('or')}</Divider>
    </div>
  );
};

SsoLogin.propTypes = {
  sso: PropTypes.array.isRequired,
};

export default SsoLogin;
