import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import BlockContainer from '../../components/BlockContainer';
import CdnImage from '../../components/CdnImage';
import { containerTypePropTypes, imageProptypes } from '../../propTypes';
import './ImageWithIcons.scss';

function ImageWithIcons(props) {
  const { header, containerType, backgroundImage, buttons } = props;
  return (
    <BlockContainer className="block--image-with-icons" type={containerType} header={header}>
      <div style={{ position: 'relative' }}>
        <CdnImage src={backgroundImage} style={{ width: '100%' }} />
        <div className="image__icons">
          {buttons.map(({ label, link, x, y, width, height, image }) => (
            <Popup
              content={label}
              basic
              position="bottom center"
              trigger={
                <NavLink
                  className="image__icons__button"
                  to={link || '#'}
                  style={{
                    left: `calc(${x} - ${width}px / 2`,
                    top: `calc(${y} - ${height}px / 2`,
                    width,
                    height,
                  }}
                >
                  <CdnImage src={image} maxWidth={width * 2} maxHeight={height * 2} func="crop" />
                </NavLink>
              }
            />
          ))}
        </div>
      </div>
    </BlockContainer>
  );
}

ImageWithIcons.defaultProps = {
  containerType: 'segment',
  header: undefined,
  backgroundImage: undefined,
  buttons: [],
};

ImageWithIcons.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  backgroundImage: imageProptypes,
  buttons: PropTypes.array,
};

export default ImageWithIcons;
