import React from 'react';
import PropTypes from 'prop-types';
import WorkshopList from './WorkshopList';
import { getString } from '../../utils';
import BlockHeader from '../BlockHeader';

const translationPrefix = 'blocks';

const WorkshopsBlock = ({ workshopList, header, headerKey, itemProps, template }) => (
  <>
    <BlockHeader title={header || getString(headerKey)} />
    <WorkshopList
      centered
      workshopList={workshopList}
      itemProps={itemProps}
      template={template || itemProps}
    />
  </>
);

WorkshopsBlock.defaultProps = {
  header: undefined,
  headerKey: `${translationPrefix}.sessions`,
  itemProps: undefined,
  template: undefined,
};

WorkshopsBlock.propTypes = {
  header: PropTypes.string,
  headerKey: PropTypes.string,
  itemProps: PropTypes.object,
  template: PropTypes.object,
  workshopList: PropTypes.array.isRequired,
};

export default WorkshopsBlock;
