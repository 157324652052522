import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Header } from 'semantic-ui-react';
import BlockContainer from '../../../components/BlockContainer';
import { bem } from '../../../core/design/bem';
import { useCloudTvSessions } from '../../../home/blocks/CloudTVBlock/cloudtv.hooks';
import store from '../../../shared/Store';
import { fromNow } from '../../../utils/dateUtils';
import { useAutoRefresh } from '../../../utils/hooks';
import { findNextSession } from '../../../workshops/utils/session.utils';
import './WebinarLiveBlock.scss';

const { endpoints } = window.__DATA__;

const css = bem('WebinarLiveBlock');

const translationPrefix = 'sponsors.sponsor.webinar-block';

function isNow(event, autoNow = Date.now()) {
  const { startDate, endDate } = event || {};
  if (!event || !startDate || !endDate) return false;
  const now = moment(autoNow);
  return (
    now.isSameOrAfter(moment(startDate).subtract(5, 'minutes')) &&
    now.isSameOrBefore(moment(endDate).add(5, 'minutes'))
  );
}
function getWebinarLink(session, userMustRegister) {
  const { _id, collection, sessionId } = session;
  const commonUrl = `${endpoints.meetings || '/meet'}/w/c/${collection}/${sessionId || _id}`;
  if (userMustRegister || !store.isAnonymous()) return `${commonUrl}/u/${store.userId}`;
  return commonUrl;
}

function WebinarLiveBlock(props) {
  const { t } = useTranslation();
  const { item, container, mode, workshopFilter, refreshRate, userMustRegister } = props;
  const now = useAutoRefresh(refreshRate);
  const sessions = useCloudTvSessions({ mode, workshopFilter }).filter(
    ({ groupId }) => groupId === item._id,
  );
  const upcomingLive = findNextSession(sessions, now);
  const isLiveNow = isNow(upcomingLive || {}, now);
  if (!upcomingLive) return null;
  const webinarLink = getWebinarLink(upcomingLive, userMustRegister);
  if (isLiveNow) {
    return (
      <BlockContainer className={css()} {...container}>
        <span className="live">{t(`${translationPrefix}.live`)}</span>
        <div className={css('content')}>
          <Header as="h3" className={css('title').toString()}>
            {t(`${translationPrefix}.we-re-live`)}
          </Header>
          <p className={css('description')}>{t(`${translationPrefix}.enter-the-room`)}</p>
          {webinarLink && (
            <Button
              target="_blank"
              href={webinarLink}
              primary
              className={css('join-button').toString()}
            >
              {t(`${translationPrefix}.join-now`)}
            </Button>
          )}
        </div>
      </BlockContainer>
    );
  }
  return (
    <BlockContainer className={css()} {...container}>
      <div className={css('content')}>
        <Header as="h3" className={css('title').toString()}>
          {t(`${translationPrefix}.next-live`, { fromNow: fromNow(upcomingLive.startDate) })}
        </Header>
        <p className={css('description')}>{upcomingLive.title}</p>
      </div>
    </BlockContainer>
  );
}

WebinarLiveBlock.defaultProps = {
  container: {},
  mode: 'agenda',
  refreshRate: 30000,
  userMustRegister: true,
  workshopFilter: {},
};

WebinarLiveBlock.propTypes = {
  container: PropTypes.object,
  item: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['agenda', 'sessions', 'workshops']),
  refreshRate: PropTypes.number,
  userMustRegister: PropTypes.bool,
  workshopFilter: PropTypes.object,
};

export default WebinarLiveBlock;
