import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { getString } from '../../utils';

function computeUserAgenda(workshops, agenda) {
  const { workshopsById } = workshops;
  const events = agenda.agenda
    .map((event) => {
      const { type } = event;
      switch (type) {
        case 'workshop':
          return { ...workshopsById[event._id], type: 'workshop' };
        case 'session': {
          return { ...event, type: 'session' };
        }
        case 'appointment': {
          const { group } = event;

          return {
            ...event,
            title: getString(`appointments.slots.meeting-with`, {
              name: group?.name,
              entity: group,
              appointment: event,
            }),
            appointment: event,
            sponsor: group,
          };
        }
        default:
          return null;
      }
    })
    .filter((v) => v);
  return orderBy(events, 'startDate', 'asc');
}

export const userAgenda = createSelector(
  [(state) => state.workshops, (state) => state.agenda],
  computeUserAgenda,
);

export const userAgendaWithLoading = createSelector(
  [(state) => state.workshops, (state) => state.agenda],
  (workshopsState, agendaState) => {
    const agenda = computeUserAgenda(workshopsState, agendaState);
    return {
      agenda,
      loading: agendaState.loading,
      lastLoadTime: agendaState.lastLoadTime,
      loaded: !!agendaState.lastLoadTime,
    };
  },
);
