import PropTypes from 'prop-types';

const { arrayOf, date, string } = PropTypes;

export const dataProptypes = {
  _id: string,
  name: string,
};

export const imageProptypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    uri: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
]);

export const logoPropType = PropTypes.shape({
  image: imageProptypes.isRequired,
  link: PropTypes.string,
});

export const logoArrayPropTypes = PropTypes.arrayOf(logoPropType);

export const liveImageProptypes = PropTypes.shape({
  image: imageProptypes.isRequired,
});

export const timeSlotProptypes = {
  _id: string,
  appointmentId: string,
  endDate: date,
  groupId: string,
  ownerId: string,
  startDate: date,
  timeRangeId: string,
};

export const userProptypes = {
  _id: string,
  email: string,
  firstName: string,
  lastName: string,
};

export const workshopProptypes = {
  _id: string,
  title: string,
  startDate: date,
  endDate: date,
};

export const appointmentProptypes = {
  _id: string,
  endDate: date,
  startDate: date,
  users: PropTypes.arrayOf(PropTypes.shape({ userId: PropTypes.string, status: PropTypes.string })),
};

export const dataArrayProptypes = arrayOf(PropTypes.shape(dataProptypes));
export const userArrayProptypes = arrayOf(PropTypes.shape(userProptypes));
export const timeSlotObjectProptypes = PropTypes.shape({
  isBookingAvailable: PropTypes.bool,
  freeTimeSlots: PropTypes.arrayOf(PropTypes.shape(timeSlotProptypes)),
});
export const workshopArrayProptypes = arrayOf(PropTypes.shape(workshopProptypes));
export const appointmentArrayProptypes = arrayOf(PropTypes.shape(appointmentProptypes));

export const dateProptypes = PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]);

export const containerTypePropTypes = PropTypes.oneOf([
  'basic',
  'segment',
  'segment-light',
  'headerless',
]);

export const containerProptypes = PropTypes.shape({
  header: PropTypes.string,
  type: containerTypePropTypes,
});

export const speakerPropTypes = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  slug: PropTypes.string,
  shortDescription: PropTypes.string,
  description: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  company: PropTypes.string,
  role: PropTypes.string,
  thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});
