import groupBy from 'lodash/groupBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Card, Header, Icon, Modal } from 'semantic-ui-react';
import { userAgenda } from '../../../agenda/store/agenda.selectors';
import ClickmeetingAccessButton from '../../../components/ClickmeetingAccessButton';
import QuotaField from '../../../components/workshops/QuotaField';
import WorkshopDate, { WorkshopTimeRemaining } from '../../../components/workshops/WorkshopDate';
import { useConfig } from '../../../config/config.context';
import { useScreenConfig } from '../../../config/screens.context';
import { bem } from '../../../core/design/bem';
import { workshopProptypes } from '../../../propTypes';
import { AddWorkshopToCalendar } from '../../../workshops/blocks/WorkshopAddToCalendarBlock';
import {
  useWorkshopSessionRegistration,
  useWorkshopSessions,
} from '../../store/workshopSessions.hooks';
import LanguageMenu from './components/LanguageMenu';
import './WorkshopSessionModal.scss';

const translationPrefix = 'workshop-sessions.sessions-modal';

const cxModal = bem('workshop-session-modal');

const WorkshopSession = ({ session, onClick, selectedSession }) => {
  const { t } = useTranslation();
  const { usersCount, quota, _id } = session;
  const isSelected = selectedSession?._id === _id;
  const remainingPlaces = quota - usersCount;
  const isFull = remainingPlaces === 0;
  return (
    <Card
      fluid
      as={Button}
      className={cxModal('card').state({ active: isSelected }).toString()}
      disabled={isFull}
      onClick={() => onClick(session)}
    >
      <Card.Header>{t('common.session-time', session)}</Card.Header>
      <QuotaField quota={quota} usersCount={usersCount} />
    </Card>
  );
};

WorkshopSession.defaultProps = {
  selectedSession: undefined,
};

WorkshopSession.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedSession: PropTypes.object,
  session: PropTypes.object.isRequired,
};

function useGroupByDay(items, key = 'startDate') {
  return groupBy(items, (item) => moment(item[key], moment.ISO_8601).format('YYYY-MM-DD'));
}

function useLanguageFilter(workshop, sessions) {
  const { lang, languages } = useConfig();
  const [language, setLanguage] = useState(lang);
  const { sessionsModal } = useScreenConfig('workshops'); // TODO: make this dynamic ?

  // No filter
  if (!sessionsModal?.languageFilter || !sessions || sessions.length === 0) {
    return { languageFilterElement: null, filteredSessions: sessions };
  }

  const sessionsByLanguage = groupBy(sessions, 'lang');

  // Filter !
  return {
    languageFilterElement: (
      <LanguageMenu
        language={language}
        languages={languages.map((lng) => ({
          ...lng,
          count: sessionsByLanguage[lng.value]?.length || 0,
        }))}
        onChange={setLanguage}
        pointing
        secondary
      />
    ),
    filteredSessions: sessionsByLanguage[language] ?? [],
  };
}

const WorkshopSessionsModal = ({ workshop, showDescription, onClose }) => {
  const { t } = useTranslation();
  const { collection, _id: workshopId, title } = workshop;
  const { data: sessions = [] } = useWorkshopSessions(collection, workshopId);
  const agenda = useSelector(userAgenda);
  const registration = agenda.find(
    (evt) => evt._id === workshopId || evt.workshopId === workshopId,
  );
  // const registration = useSelector((state) => state.registrations.registrationsById[workshop._id]);

  const [selectedSession, setSelectedSession] = useState();
  const {
    loading,
    registerWorkshopSession,
    unregisterWorkshopSession,
  } = useWorkshopSessionRegistration(selectedSession || registration);
  const handleConfirm = async () => {
    const hasRegistered = await registerWorkshopSession();
    if (hasRegistered) {
      onClose();
    }
  };
  const handleUnregister = async () => {
    const hasUnregistered = await unregisterWorkshopSession();
    if (hasUnregistered) {
      setSelectedSession(undefined);
    }
  };

  const { languageFilterElement, filteredSessions } = useLanguageFilter(workshop, sessions);

  const sessionsByDay = useGroupByDay(filteredSessions);
  const days = Object.keys(sessionsByDay);

  if (registration) {
    // Already registered
    const isToday = moment(registration.startDate).isSame(new Date(), 'day');
    return (
      <Modal open onClose={onClose} className={cxModal().toString()}>
        <Modal.Header>{t(`${translationPrefix}.header`, { title })}</Modal.Header>
        <Modal.Content>
          {showDescription && workshop.description && (
            <div
              style={{ marginBottom: 20 }}
              dangerouslySetInnerHTML={{ __html: workshop.description }}
            />
          )}
          <Header as="h4">{t(`workshops.workshop.registered`)}</Header>
          <div>
            <div style={{ display: 'inline-block', marginRight: 20, marginBottom: 8 }}>
              <WorkshopDate startDate={registration.startDate} />
              <div>
                <AddWorkshopToCalendar primary workshop={registration} fluid={false} />
              </div>
            </div>
            {/* {isToday && ( */}
            <div style={{ display: 'inline-block' }}>
              <WorkshopTimeRemaining date={registration.startDate} />
              <div>
                <ClickmeetingAccessButton event={registration} disabled={!isToday} />
              </div>
            </div>
            {/* )} */}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>{t('btn.cancel')}</Button>

          <Button primary onClick={handleUnregister} loading={loading} disabled={loading}>
            {t(`workshops.workshop.unregister`)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose} className={cxModal().toString()}>
      <Modal.Header>{t(`${translationPrefix}.header`, { title })}</Modal.Header>
      <Modal.Content>
        {showDescription && workshop.description && (
          <div
            style={{ marginBottom: 20 }}
            dangerouslySetInnerHTML={{ __html: workshop.description }}
          />
        )}
        {languageFilterElement}
        <Header as="h4">
          {t(
            `${translationPrefix}.${
              filteredSessions.length > 0 ? 'select-session' : 'no-available-session'
            }`,
          )}
        </Header>
        {days.map((day) => {
          return (
            <>
              <Header as="h4" className="header--date">
                <Icon name="calendar outline alternate" />{' '}
                <Header.Content>
                  {t(`common.date`, {
                    date: moment(day, 'YYYY-MM-DD'),
                    context: 'workshop-session-modal',
                  })}
                </Header.Content>
              </Header>
              <Card.Group itemsPerRow={4}>
                {sessionsByDay[day].map((session) => (
                  <WorkshopSession
                    key={session._id}
                    session={session}
                    selectedSession={selectedSession}
                    onClick={setSelectedSession}
                  />
                ))}
              </Card.Group>
            </>
          );
        })}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>{t('btn.cancel')}</Button>

        <Button
          primary
          onClick={handleConfirm}
          loading={loading}
          disabled={loading || !selectedSession}
        >
          {t(`workshop-sessions.register`)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

WorkshopSessionsModal.defaultProps = {
  showDescription: false,
};

WorkshopSessionsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
  workshop: PropTypes.shape(workshopProptypes).isRequired,
};
export default WorkshopSessionsModal;
