/* eslint-disable no-restricted-imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Image } from 'semantic-ui-react';
import { fromNow } from '../../utils/dateUtils';
import { avatarWithFallbackWhenMissing } from '../../utils/avatarUtils';
import { useScreenConfig } from '../../config/screens.context';
import { defaultReactions } from '../SocialWallCard';

function FooterCaption(props) {
  const { currentView } = props;
  const { reactions = defaultReactions } = useScreenConfig('socialWall');
  const {
    firstName,
    lastName,
    thumbnail,
    createdAt,
    message,
    reactions: reactionsCount = {},
  } = currentView;
  return (
    <div>
      <div className="SocialWall--Card__Actions">
        <div style={{ display: 'flex' }}>
          <div className="SocialWall--Card__Actions--reactions">
            {reactions.map(({ icon, color, key }) => {
              const count = reactionsCount[key] || 0;
              const hasCount = count !== 0;
              return (
                <div className="SocialWall--Card__Actions--reactions__reaction">
                  <Icon
                    size="large"
                    name={hasCount ? icon : `${icon} outline`}
                    color={hasCount ? color : 'grey'}
                  />
                  <p className="count" style={{ color: hasCount ? 'white' : '#777' }}>
                    {count}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="SocialWall--Card__User">
        <Image
          className="thumbnail"
          src={avatarWithFallbackWhenMissing(thumbnail)}
          avatar
          size="mini"
        />
        <div>
          <Card.Description className="fullname">
            {firstName} {lastName}
          </Card.Description>
          <Card.Description className="date">
            <Icon name="clock outline" />
            {fromNow(createdAt)}
          </Card.Description>
        </div>
      </div>
      <Card.Description
        className="SocialWall--Card__Message quoted"
        style={{ fontSize: 14, color: 'white' }}
      >
        {message}
      </Card.Description>
    </div>
  );
}

FooterCaption.propTypes = {
  currentView: PropTypes.object.isRequired,
};

export default FooterCaption;
