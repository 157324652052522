const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER': {
      return { user: action.user };
    }
    case 'GET_CURRENT_USER':
      return state;
    default:
      return state;
  }
};

export function setStoreUser(user) {
  return {
    type: 'SET_USER',
    user,
  };
}

export default userReducer;
