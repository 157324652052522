import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import SVG from 'react-inlinesvg';
import BlockContainer from '../../components/BlockContainer';
import { containerTypePropTypes, imageProptypes } from '../../propTypes';
import './SVGMap.scss';

const SVGCSS = () => (
  <style
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: `
  svg g#stands>polygon:hover, svg g#stands>rect:hover {
    opacity: 0.5;
    cursor: pointer;
    fill: rgba(0, 0, 255, 0.5);
  }
  svg g#noms, svg g#numeros {
    pointer-events: none;
  }
  `,
    }}
  />
);

function SVGMap(props) {
  const { header, containerType, image } = props;
  const ref = useRef(null);

  function onClick(evt) {
    window.alert(evt.target.id);
  }

  function handleLoaded() {
    if (ref.current) {
      // Attach click listeners
      const polygons = ref.current.querySelectorAll('svg g#stands>polygon');
      polygons.forEach((node) => node.addEventListener('click', onClick));

      const rects = ref.current.querySelectorAll('svg g#stands>rect');
      rects.forEach((node) => node.addEventListener('click', onClick));
    }
  }

  if (!image) return null;
  return (
    <BlockContainer className="block--svg-map" type={containerType} header={header}>
      <div style={{ position: 'relative' }}>
        <SVGCSS />
        <SVG
          innerRef={ref}
          src={image.uri}
          onError={console.log}
          width="100%"
          onLoad={handleLoaded}
        />
      </div>
    </BlockContainer>
  );
}

SVGMap.defaultProps = {
  containerType: 'segment',
  header: undefined,
  image: undefined,
};

SVGMap.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  image: imageProptypes,
};

export default SVGMap;
