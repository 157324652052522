import { useSetState } from 'ahooks';
import React, { useCallback, useMemo } from 'react';
import Lightbox from '../../components/Lightbox';
import FooterCaption from '../Lightbox/FooterCaption';
import '../SocialWall.scss';

const carouselProps = { components: { FooterCaption } };

function useLightbox(messages) {
  const [{ selectedIndex, isOpenLightbox }, setState] = useSetState({
    selectedIndex: 0,
    isOpenLightbox: false,
  });
  const lightboxImages = useMemo(() => messages.filter((p) => p.image), [messages]);
  const openLightbox = useCallback(
    (messageId) => {
      setState({
        selectedIndex: lightboxImages.findIndex((p) => p.id === messageId),
        isOpenLightbox: true,
      });
    },
    [lightboxImages, setState],
  );

  const lightbox = useMemo(
    () =>
      isOpenLightbox && (
        <Lightbox
          currentIndex={selectedIndex}
          views={lightboxImages}
          onClose={() => setState({ isOpenLightbox: false })}
          carouselProps={carouselProps}
        />
      ),
    [isOpenLightbox, selectedIndex, lightboxImages, setState],
  );
  return {
    lightbox,
    isOpenLightbox,
    openLightbox,
  };
}

export default useLightbox;
