/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AddToCalendar from '../../components/AddToCalendar';
import { useConfig } from '../../config/config.context';
import { TrackingContext } from '../../Context';
import { eventTags } from '../../core/trackers/events';
// import { useUser } from '../../core/users/users.hooks';
import store from '../../shared/Store';
import { useCalendarEventLink, useEntityUrl } from '../../utils/hooks';
import { replaceObjectValues } from '../../utils/stringUtils';
import { workshopPropType } from '../utils';
import './WorkshopAddToCalendarBlock.scss';

function useEntityConfig(kind) {
  return useConfig()?.entities?.[kind] || {};
}

function useEntityIcal(kind, collection) {
  const config = useEntityConfig(kind);
  const ical = config[collection]?.ical ?? config.default?.ical;
  return ical ?? {};
}

export const AddWorkshopToCalendar = ({ workshop, ...props }) => {
  const url = useCalendarEventLink(workshop, true);
  const { trackEvent } = useContext(TrackingContext);
  const icalConfig = useEntityIcal('workshops', workshop.collection);

  const event = {
    description: `${workshop.shortDescription || ''}. \n${url}`,
    reminderTime: '-PT15M',
    title: workshop.title,
    url,
    ...replaceObjectValues(icalConfig, { workshop }),
    // Flush organizer and email for now
    organizer: undefined,
    email: undefined,
    startDate: workshop.startDate,
    endDate: workshop.endDate,
  };

  function onAdd({ calendarType }) {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: workshop,
      kind: 'workshops',
      entityType: 'addToCalendar',
      calendarType,
    });
  }

  function onOpen() {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: workshop,
      kind: 'workshops',
      entityType: 'listCalendarOptions',
    });
  }

  return <AddToCalendar event={event} onAdd={onAdd} onOpen={onOpen} {...props} />;
};

AddWorkshopToCalendar.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const WorkshopAddToCalendarBlock = memo(({ workshop }) => (
  <div className="WorkshopAddToCalendarBlock--Container">
    <AddWorkshopToCalendar workshop={workshop} />
  </div>
));

WorkshopAddToCalendarBlock.propTypes = {
  workshop: workshopPropType.isRequired,
};

export const AddAppointmentToCalendar = ({ appointment, sponsor, ...props }) => {
  const { t } = useTranslation();
  const url = useEntityUrl({ kind: 'users', ...sponsor }, true);
  const icalConfig = useEntityIcal('appointments', appointment.collection);
  const { trackEvent } = useContext(TrackingContext);

  const event = {
    title: t('appointments.slots.meeting-with', {
      name: sponsor.name,
      entity: sponsor,
      appointment,
    }),
    description: `${sponsor?.shortDescription || ''}. \n${url}`,
    ...replaceObjectValues(icalConfig, { parent: sponsor, appointment }),
    endDate: appointment.endDate,
    startDate: appointment.startDate,
    // timezone: 'Europe/Paris', // Don't force the timezone unless you force it when formatting time
  };

  function onAdd({ calendarType }) {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: appointment,
      kind: 'appointments',
      entityType: 'addToCalendar',
      calendarType,
    });
  }

  function onOpen() {
    trackEvent(eventTags.BUTTON_CLICK, {
      userId: store.userId,
      item: appointment,
      kind: 'appointments',
      entityType: 'listCalendarOptions',
    });
  }

  return <AddToCalendar event={event} onAdd={onAdd} onOpen={onOpen} {...props} />;
};

AddAppointmentToCalendar.propTypes = {
  appointment: PropTypes.object.isRequired,
  sponsor: PropTypes.object.isRequired,
};

export const AppointmentAddToCalendarBlock = memo(({ appointment, sponsor, ...props }) => (
  <div className="WorkshopAddToCalendarBlock--Container">
    <AddAppointmentToCalendar appointment={appointment} sponsor={sponsor} {...props} />
  </div>
));

AppointmentAddToCalendarBlock.propTypes = {
  appointment: PropTypes.object.isRequired,
  sponsor: PropTypes.object.isRequired,
};
