/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import './FlipCountdown.scss';
import { padStart } from 'lodash';
import { defaultItemList, timeLeftProps } from '../../home/components/CountdownSquare';
import { getString } from '../../utils';
import Flip from './Flip';

const translationPrefix = 'blocks.countdown';

const FlipCountdown = (props) => {
  const { timeLeft, itemList, digitStyle } = props;

  return (
    <div className="flipClock">
      {itemList.map((item) => {
        const { key, label } = item;
        return (
          <Flip
            key={key}
            digitStyle={digitStyle}
            value={padStart(timeLeft[key], 2, '0')}
            label={label || getString(`${translationPrefix}.${key}`)}
          />
        );
      })}
    </div>
  );
};

FlipCountdown.defaultProps = {
  digitStyle: 'single',
  itemList: defaultItemList,
  timeLeft: {},
};

FlipCountdown.propTypes = {
  digitStyle: PropTypes.oneOf(['single', 'combined']),
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      maxValue: PropTypes.number,
      valueLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  timeLeft: timeLeftProps,
};

export default FlipCountdown;
