import keyBy from 'lodash/keyBy';

const registartionsReducer = (state = { registrations: [], registrationsById: {} }, action) => {
  switch (action.type) {
    case 'SET_REGISTRATIONS':
      return {
        registrations: action.registrations,
        registrationsById: keyBy(action.registrations, '_id'),
      };
    default:
      return state;
  }
};

export function setRegisrations(registrations) {
  return {
    type: 'SET_REGISTRATIONS',
    registrations,
  };
}

export default registartionsReducer;
