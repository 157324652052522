import { isAfter, parseISO, startOfDay } from 'date-fns';
import { orderBy } from 'lodash';

export function hasActiveLive(session) {
  if (!session) return false;

  const { liveStreams } = session;
  return liveStreams?.length > 0 && liveStreams[0].visible;
}

export function computeMidTime({ startDate, endDate }) {
  if (!startDate && !endDate) return null;
  const startAt = parseISO(startDate);
  const endAt = parseISO(endDate);
  return new Date((startAt.getTime() + endAt.getTime()) / 2);
}

export function findNextSession(sessions, now) {
  const nowDate = new Date(now);
  if (sessions?.length === 0) return null;
  const candidateSessions = sessions.filter(({ startDate, endDate }) => startDate && endDate);

  // Order sessions
  const sortedSessions = orderBy(candidateSessions, 'startDate');
  return sortedSessions.find((s) => isAfter(computeMidTime(s), nowDate));
}

// TODO: almost identical to currentLiveSession...
export function currentLiveSession(sessions, now) {
  const nowDate = new Date(now);
  const today = startOfDay(nowDate);
  if (sessions?.length === 0) return null;
  // Today or later sessions
  const todayOrLaterSessions = sessions.filter(({ startDate }) =>
    isAfter(parseISO(startDate), today),
  );
  const candidateSessions = todayOrLaterSessions.filter(({ noLive }) => !noLive);
  // Find first session that has an active live
  const activeLive = candidateSessions.find(hasActiveLive);
  if (activeLive) return activeLive;

  // Didn't find any live. Find next "upcoming" session
  return candidateSessions.find((s) => isAfter(parseISO(s.endDate), nowDate));
}

export function upcomingLiveSession(sessions, now) {
  const nowDate = new Date(now);
  const today = startOfDay(nowDate);
  if (sessions?.length === 0) return null;
  // Today or later sessions
  const todayOrLaterSessions = sessions.filter(({ startDate }) =>
    isAfter(parseISO(startDate), today),
  );
  const candidateSessions = todayOrLaterSessions.filter(({ noLive }) => !noLive);
  // Find first session that has an active live
  const activeLive = candidateSessions.find(hasActiveLive);
  if (activeLive) return activeLive;

  // Didn't find any live. Find next "upcoming" session
  return candidateSessions.find((s) => isAfter(computeMidTime(s), nowDate));
}
