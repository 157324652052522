// eslint-disable-next-line import/no-cycle
import platformService from '../services/platform.service';
import { SET_BY_ID, SET_COLLECTION } from './users.types';

export const setUsers = (collection, users) => ({
  type: SET_COLLECTION,
  collection,
  users,
});

export const setUser = (collection, user) => ({
  type: SET_BY_ID,
  collection,
  user,
});

export function fetchUsersByCollection(collection) {
  return async (dispatch) => {
    const users = await platformService.fetchUsers(collection);
    // TODO: handle errors
    if (Array.isArray(users)) {
      dispatch(setUsers(collection, users));
    }
  };
}

export function fetchContributor(userId, options, collection) {
  return async (dispatch) => {
    const user = await platformService.fetchContributor(collection, userId, options);
    dispatch(setUser(collection, user));
  };
}
