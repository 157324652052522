import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'semantic-ui-react';
import DaysContainer from '../../components/DaysContainer';
import { groupEventsByDays } from '../../utils/agendaUtils';
import { useOrderBy } from '../../utils/hooks';
import { useSyncedWorkshops } from '../../workshops/store/workshops.hooks';
import WorkshopGridItem from './workshop-templates/WorkshopGridItem';
import WorkshopImageItem from './workshop-templates/WorkshopImageItem';
import WorkshopLightItem from './workshop-templates/WorkshopLightItem';

const templates = {
  light: WorkshopLightItem,
  image: WorkshopImageItem,
  grid: WorkshopGridItem,
};

const defaultOrder = [{ field: 'startDate', order: 'asc' }];

const WorkshopsContainer = ({ template, children, style }) => {
  const isGrid = template === 'grid';
  const className = cx('WorkshopsRegistrationBlock', template);
  if (isGrid) {
    return (
      <Card.Group className={className} itemsPerRow={3} stackable centered style={style}>
        {children}
      </Card.Group>
    );
  }
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

WorkshopsContainer.defaultProps = {
  style: {},
};

WorkshopsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  template: PropTypes.string.isRequired,
};
function WorkshopsWithRegistrationBlock(props) {
  const { template, config, collection } = props;
  let workshops = useSyncedWorkshops();
  const { groupByDays = true, orderBy: orderByConfig = defaultOrder } = config;
  const Component = templates[template];
  if (collection) {
    workshops = workshops.filter((ws) => ws.collection === collection);
  }
  const orderedWorkshops = useOrderBy(workshops, orderByConfig);

  const { days, eventsByDay } = groupEventsByDays(orderedWorkshops);
  if (groupByDays) {
    return (
      <div style={{ padding: '20px 10px' }}>
        {days.map((day) => {
          const dayEvents = eventsByDay[day] || [];
          return (
            <div key={day}>
              <DaysContainer days={[day]} />
              <WorkshopsContainer template={template} style={{ marginBottom: 10 }}>
                {dayEvents.map((workshop) => (
                  <Component key={workshop._id} workshop={workshop} config={config} />
                ))}
              </WorkshopsContainer>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <WorkshopsContainer template={template} style={{ padding: '20px 10px' }}>
      {orderedWorkshops.map((workshop) => (
        <Component key={workshop._id} workshop={workshop} config={config} />
      ))}
    </WorkshopsContainer>
  );
}

WorkshopsWithRegistrationBlock.defaultProps = {
  collection: undefined,
  config: {},
  template: 'image',
};

WorkshopsWithRegistrationBlock.propTypes = {
  collection: PropTypes.string,
  config: PropTypes.object,
  template: PropTypes.oneOf(['light', 'image', 'grid']),
};

export default WorkshopsWithRegistrationBlock;
