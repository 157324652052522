import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'semantic-ui-react';
import { useSpeakerIds } from '../../core/users/users.hooks';
import { getString } from '../../utils';
import BlockHeader from '../BlockHeader';
import SpeakerAvatars from './SpeakerAvatars';
import SpeakerCard from './SpeakerCard';

const translationPrefix = 'blocks';

const SpeakersBlock = ({ ids, ...blockConfig }) => {
  const speakers = useSpeakerIds(ids);
  const { t } = useTranslation();
  if (!speakers || speakers.length === 0) return null;
  if (blockConfig?.variant === 'text') {
    return (
      <div className="speakers" style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 10 }}> {t('workshops.workshop.modal.with')}</span>
        <SpeakerAvatars speakerIds={ids} {...blockConfig} />
      </div>
    );
  }
  return (
    <>
      <BlockHeader title={getString(`${translationPrefix}.speakers`)} />
      <Card.Group itemsPerRow={3} centered>
        {speakers.map((speaker) => (
          <SpeakerCard key={speaker._id} speaker={speaker} {...blockConfig} />
        ))}
      </Card.Group>
    </>
  );
};
SpeakersBlock.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SpeakersBlock;
