// eslint-disable-next-line import/prefer-default-export
export async function postJson(url, json) {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  });
  return res.json();
}

export async function getJson(url, options) {
  const res = await fetch(url, options);
  return res.json();
}
