import cx from 'classnames';
import padStart from 'lodash/padStart';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './CountdownSquare.scss';

const translationPrefix = 'blocks.countdown';

export const defaultItemList = [
  { key: 'days', maxValue: 360 },
  { key: 'hours', maxValue: 24 },
  { key: 'minutes', maxValue: 60 },
  { key: 'seconds', maxValue: 60 },
];

export const timeLeftProps = PropTypes.shape({
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
});

const CountdownSquare = React.memo(
  ({ background: backgroundColor, color, inverted, itemList, timeLeft, type }) => {
    const { t } = useTranslation();
    return (
      <div className={cx('countdown', { inverted })}>
        {itemList.map((item) => {
          const { key, valueLabel, label } = item;
          return (
            <div
              key={key}
              className={cx('clock', type, `countdown--field-${key}`)}
              style={{ backgroundColor, color }}
            >
              <div className="clock-counter">{valueLabel || padStart(timeLeft[key], 2, '0')}</div>
              <div className="clock-label">{label || t(`${translationPrefix}.${key}`)}</div>
            </div>
          );
        })}
      </div>
    );
  },
);

CountdownSquare.defaultProps = {
  background: undefined,
  color: undefined,
  inverted: undefined,
  itemList: defaultItemList,
  timeLeft: {},
};
CountdownSquare.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      maxValue: PropTypes.number,
      valueLabel: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  timeLeft: timeLeftProps,
  type: PropTypes.oneOf(['square', 'circle1']).isRequired,
};

export default CountdownSquare;
