/* eslint-disable class-methods-use-this */
import platformService from './platform.service';

const { endpoints } = window.__DATA__;

class LiveQuestionsService {
  async postQuestion(sessionId, post) {
    return platformService.securePost(
      `${endpoints.platform}/live-questions/session/${sessionId}/questions`,
      post,
    );
  }
}

export default new LiveQuestionsService();
