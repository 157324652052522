import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import UpcomingSessions from '../components/UpcomingSessions';
import './BannerAndUpcoming.scss';
import { imageProptypes } from '../../propTypes';
import CdnImage from '../../components/CdnImage';

const BannerAndUpcoming = ({ banner, style, clickableSession }) => {
  return (
    <Grid.Row stretched>
      <Grid.Column width={12}>
        <Segment className="Home--banner" style={style}>
          <CdnImage src={banner} alt="banner" maxWidth={838} />
        </Segment>
      </Grid.Column>
      <Grid.Column width={4}>
        <UpcomingSessions clickableSession={clickableSession} />
      </Grid.Column>
    </Grid.Row>
  );
};

BannerAndUpcoming.defaultProps = {
  banner: undefined,
  clickableSession: true,
  style: undefined,
};

BannerAndUpcoming.propTypes = {
  banner: imageProptypes,
  clickableSession: PropTypes.bool,
  style: PropTypes.object,
};

export default BannerAndUpcoming;
