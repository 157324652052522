import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

function BlockHeader({ title, style }) {
  return (
    <Header as="h3" className="blocks--header" style={style}>
      {title}
    </Header>
  );
}

BlockHeader.defaultProps = {
  style: {},
  title: '',
};

BlockHeader.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
};

export default BlockHeader;
