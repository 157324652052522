/* eslint-disable react/prop-types */
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import EntityLink from '../../../../../components/EntityLink';
import { bem } from '../../../../../core/design/bem';
import { useMe } from '../../../../../profile/hooks';
import { useWorkshop } from '../../../../../workshops/store/workshops.hooks';

const block = bem('AgendaDay');
const sessionBlock = bem('AgendaSession');

function getEntityForSession(session, workshop) {
  if (session.type === 'appointment') {
    // Fetch parent
    return { kind: 'users', ...session.group };
  }
  if (session.type === 'session') {
    // Fetch parent
    // TODO: auto-inject from agenda ?
    return { kind: 'workshops', ...workshop };
  }
  return session; // Workshop
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Session = ({ session }) => {
  const { t } = useTranslation();
  const user = useMe();
  const workshop = useWorkshop({ workshopId: session.workshopId });
  const tag = t(`workshops.collections.${session.collection}.tag`, '');
  const booked = (session.slug && user[session.slug]) || false;
  return (
    <div
      className={sessionBlock({ collection: session.collection, category: session.category })
        .is({ booked })
        .toString()}
    >
      <div className={sessionBlock('time').toString()}>
        {t('components.day-calendar.start-time', { startDate: session.startDate })}
      </div>
      <EntityLink
        entity={getEntityForSession(session, workshop)}
        className={sessionBlock('content').toString()}
      >
        {tag && <div className={sessionBlock('tag').toString()}>{tag}</div>}
        <div className={sessionBlock('title').toString()}>{session.title}</div>
        {session.shortDescription && (
          <div className={sessionBlock('shortDescription').toString()}>
            {session.shortDescription}
          </div>
        )}
        <div className={sessionBlock('time').toString()}>
          {t('components.day-calendar.time', session)}
        </div>
        {booked && <div className={sessionBlock('booked').toString()}>Booked</div>}
      </EntityLink>
    </div>
  );
};

const AgendaDay = ({ day, events }) => {
  const { t } = useTranslation();
  const date = moment(day, 'YYYY-MM-DD');
  const sessions = orderBy(events, 'startDate');
  return (
    <div className={block({ day }).toString()}>
      <div className={block('header').toString()}>
        <Icon name="calendar check outline" />
        {t('components.day-calendar.day-header', { date })}
      </div>
      <div className={block('content').toString()}>
        {sessions.map((session) => (
          <Session key={session._id} session={session} />
        ))}
      </div>
    </div>
  );
};

export default AgendaDay;
