import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Card, Item, Icon, Segment, Header, Button } from 'semantic-ui-react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { formatDistanceToNow, parseISO } from 'date-fns';
import SquareImage from '../../components/SquareImage';
import CdnImage from '../../components/CdnImage';
import { dateFNSLocale } from '../../utils/date';
import { useSocialWallData } from '../../social-wall/useSocialWallData';
import { useAutoRefresh } from '../../utils/hooks';
import Images from '../../utils/Images';
import { getString } from '../../utils';
import NewSocialWallPostModal from '../../social-wall/NewSocialWallPostModal';
import './SocialWallBlock.scss';
import { SocialWallMessageInfo } from '../../social-wall/SocialWallCard';

function fromNow(date) {
  if (!date) return null;
  return formatDistanceToNow(parseISO(date), {
    addSuffix: true,
    locale: dateFNSLocale(),
  });
}

function sanitizeNumber(value, defaultValue) {
  if (!value) return defaultValue;
  return value;
}

export function ellipsisIfNeeded(message, length = 100) {
  if (!message) return message;
  const len = sanitizeNumber(length, 100); // In case we have maxMessageLength = ""
  if (message.length > len) {
    return `${message.slice(0, len).trim()}…`;
  }
  return message;
}

const SocialLight = ({ items, maxMessageLength }) => (
  <List>
    {items.map((item) => {
      const { message, firstName, lastName, thumbnail, image } = item;
      return (
        <List.Item>
          <SquareImage avatar src={thumbnail && thumbnail.uri} imageSize={40} />
          <List.Content>
            <List.Header>
              {firstName} {lastName}
            </List.Header>
            <List.Description style={{ opacity: 0.8 }}>
              {ellipsisIfNeeded(message, maxMessageLength)}
            </List.Description>
            {Images.exists(image) && (
              <List.Description>
                <CdnImage src={image.uri} maxWidth={300} />
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      );
    })}
  </List>
);

const SocialList = ({ items, maxMessageLength }) => (
  <Item.Group style={{ fontSize: '0.9em' }} divided>
    {items.map((item) => {
      const { id, message, image } = item;
      return (
        <Item key={id}>
          {image && image.uri && (
            <CdnImage maxWidth={80} as={Item.Image} size="tiny" src={image.uri} />
          )}
          <Item.Content>
            <SocialWallMessageInfo {...item} />
            <Item.Description>{ellipsisIfNeeded(message, maxMessageLength)}</Item.Description>
          </Item.Content>
        </Item>
      );
    })}
  </Item.Group>
);

const SocialMasonry = ({ items }) => (
  <ResponsiveMasonry columnsCountBreakPoints={{ 200: 1, 350: 2, 900: 3 }}>
    <Masonry>
      {items.map((item) => {
        const { _id, image, content, createdAt } = item;
        return (
          <div image key={_id} style={{ margin: 4, background: 'white', borderRadius: 4 }}>
            {image && image.uri && <CdnImage src={image.uri} />}
            <div style={{ padding: 8 }}>
              <Card.Description style={{ color: '#999', textAlign: 'right' }}>
                <Icon name="clock outline" style={{ marginRight: 4 }} />
                {fromNow(createdAt)}
              </Card.Description>
              <Card.Description style={{ color: 'black' }}>{content}</Card.Description>
            </div>
          </div>
        );
      })}
    </Masonry>
  </ResponsiveMasonry>
);

const components = {
  light: SocialLight,
  list: SocialList,
  masonry: SocialMasonry,
};

function SocialWallWidgetBlock(props) {
  const { template, title, canPost, sendButton, maxMessageLength } = props;
  const { messages } = useSocialWallData();
  const [showPost, setShowPost] = useState(false);
  useAutoRefresh(60 * 1000); // Refresh time
  const Component = components[template] || SocialLight;
  return (
    <>
      <Segment.Group className="block--social-wall">
        <Segment>
          <div className="header-wrapper">
            <Header as="h3">{title || getString(`blocks.social-wall.title`)}</Header>
            {canPost && (
              <Button
                icon="send"
                size="small"
                circular
                compact
                primary
                onClick={() => setShowPost(true)}
                floated="right"
                {...sendButton}
              />
            )}
          </div>
        </Segment>
        <Segment className="segment--social-wall">
          <Component items={messages.slice(0, 5)} maxMessageLength={maxMessageLength} />
        </Segment>
      </Segment.Group>

      {showPost && <NewSocialWallPostModal onClose={() => setShowPost(false)} />}
    </>
  );
}

SocialWallWidgetBlock.defaultProps = {
  canPost: false,
  maxMessageLength: 100,
  sendButton: {},
  template: 'list',
  title: undefined,
};

SocialWallWidgetBlock.propTypes = {
  canPost: PropTypes.bool,
  maxMessageLength: PropTypes.number,
  sendButton: PropTypes.shape({ icon: PropTypes.string }),
  template: PropTypes.oneOf(['light', 'list', 'masonry']),
  title: PropTypes.string,
};

export default SocialWallWidgetBlock;
