import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'semantic-ui-react';
import BlockContainer from '../../../../components/BlockContainer';
import { containerTypePropTypes } from '../../../../propTypes';
import { getString } from '../../../../utils';

const translationPrefix = 'blocks.live-questions';

const CompactBar = (props) => {
  const { containerType, header, question, saving, setQuestion, onSubmit } = props;
  const btnIcon = saving ? 'check' : 'play';
  const disabledBtn = saving || !question;
  return (
    <BlockContainer
      className="block--live-questions"
      header={header}
      type={containerType}
    >
      <Input
        className={cx("block--live-questions__input-text", !question && 'empty')}
        name="name"
        placeholder={getString(`${translationPrefix}.input-label`)}
        onChange={(e, { value }) => setQuestion(value)}
        value={question}
      />
      <Icon
        className="block--live-questions__submit-icon"
        disabled={disabledBtn}
        floated="right"
        name={btnIcon}
        onClick={onSubmit}
      />
    </BlockContainer>
  );
};

CompactBar.defaultProps = {
  containerType: 'headerless',
  header: undefined,
};

CompactBar.propTypes = {
  containerType: containerTypePropTypes,
  header: PropTypes.string,
  question: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  setQuestion: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default CompactBar;
