import React from 'react';
import './SpeakerCard.scss';
import PropTypes from 'prop-types';

import ClassicSpeakerCard from './variants/ClassicSpeakerCard';
import SquareSpeakerCard from './variants/SquareSpeakerCard';

function getLegacyProps(openMode, showLink, showModal) {
  const legacyShowLink =
    // prettier-ignore
    (openMode === 'modal' || openMode === 'none') ?
    false :
    (showLink && !showModal) || openMode === 'link';

  const legacyShowModal = showModal || openMode === 'modal';

  return { legacyShowLink, legacyShowModal };
}

const SpeakerCard = (props) => {
  const { variant, openMode, showLink, showModal, ...cardProps } = props;

  const { legacyShowLink, legacyShowModal } = getLegacyProps(openMode, showLink, showModal);

  switch (variant) {
    case 'square':
      return <SquareSpeakerCard openMode={openMode} {...cardProps} />;
    case 'light':
    case 'compact':
      return (
        <ClassicSpeakerCard
          ligthVariant
          {...cardProps}
          showLink={legacyShowLink}
          showModal={legacyShowModal}
        />
      );
    case 'classic':
    default:
      return (
        <ClassicSpeakerCard {...cardProps} showLink={legacyShowLink} showModal={legacyShowModal} />
      );
  }
};

SpeakerCard.defaultProps = {
  openMode: undefined,
  variant: undefined,
  showLink: undefined,
  showModal: undefined,
};
SpeakerCard.propTypes = {
  openMode: PropTypes.string,
  showLink: PropTypes.bool,
  showModal: PropTypes.bool,
  variant: PropTypes.string,
};

export default SpeakerCard;
