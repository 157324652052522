import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Footer from '../../../../components/Footer';
import SideSection from '../../../components/SideSection';
import './LoginFullScreenContainer.scss';

const sideSectionPropType = PropTypes.shape({
  visible: PropTypes.bool,
  side: PropTypes.oneOf(['left', 'right']),
  blocks: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string })),
});

type SideSectionProps = {
  visible?: boolean;
  side?: 'left' | 'right';
  blocks?: { type: string }[];
};

type Props = React.PropsWithChildren<{
  className?: string;
  sideSection?: SideSectionProps;
}>;

const defaultProps = {
  className: undefined as Props['className'],
  sideSection: undefined as Props['sideSection'],
};

const LoginFullScreenContainer = ({
  className,
  children,
  sideSection,
}: Props & typeof defaultProps): JSX.Element => {
  const { visible: sideVisible = true, side = 'right' } = sideSection || {};
  const hasSection = sideSection && sideVisible && !!sideSection?.blocks?.length;
  const sidebar = hasSection && <SideSection sidebar={sideSection} />;
  return (
    <div className={cx('fullscreen-container', className)}>
      <div className="fullscreen-center-wrapper">
        <Grid textAlign="center" stackable relaxed verticalAlign="middle">
          {side === 'left' && sidebar && <Grid.Column width={1} />}
          {side === 'left' && sidebar}
          {hasSection && <Grid.Column width={1} />}
          <Grid.Column className="column--auth-content" width={hasSection ? 4 : undefined}>
            {children}
          </Grid.Column>
          {hasSection && <Grid.Column width={1} />}
          {side !== 'left' && sidebar}
          {side !== 'left' && sidebar && <Grid.Column width={1} />}
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

LoginFullScreenContainer.defaultProps = defaultProps;

LoginFullScreenContainer.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  sideSection: sideSectionPropType,
};

export default LoginFullScreenContainer;
