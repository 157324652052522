import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/fp/orderBy';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import negate from 'lodash/fp/negate';
import { differenceInMinutes, parseISO } from 'date-fns';
import WorkshopList from '../../components/workshops/WorkshopList';
import { useAutoRefresh } from '../../utils/hooks';
import { isFinished, startsToday } from '../../utils/dateUtils';

function isSoon({ startDate }, startedLessThanMinutesAgo, startingInLessThanMinutes) {
  const now = new Date();
  const date = parseISO(startDate);
  const delta = differenceInMinutes(date, now);

  if (delta <= 0 && delta >= -startedLessThanMinutesAgo) {
    // Started less than 5 minutes ago, give it a go...
    return true;
  }
  if (delta >= 0 && delta <= startingInLessThanMinutes) {
    // Starting in less than 30m, good to go too
    return true;
  }
  return false;
}

function isCandidateWorkshop(onlyToday) {
  const notFinished = negate(isFinished);
  if (onlyToday) {
    return flow(startsToday, notFinished);
  }
  return notFinished;
}

function upcomingSessions(
  { workshops },
  startedLessThanMinutesAgo,
  startingInLessThanMinutes,
  minimumCountToDisplay,
  maximumCountToDisplay,
  todayOnly,
) {
  const isSoonWorkshop = (workshop) =>
    isSoon(workshop, startedLessThanMinutesAgo, startingInLessThanMinutes);

  const candidatesWorkshops = flow(
    filter(isCandidateWorkshop(todayOnly)),
    orderBy(['startDate'], ['asc']),
  )(workshops.workshops);

  const soonWorkshops = filter(isSoonWorkshop)(candidatesWorkshops);

  const hasEnoughSoonWorkshops = soonWorkshops.length >= minimumCountToDisplay;
  if (hasEnoughSoonWorkshops) {
    return soonWorkshops.slice(0, maximumCountToDisplay);
  }

  return candidatesWorkshops.slice(0, minimumCountToDisplay);
}

const UpcomingWorkshopsBlock = ({
  title,
  startedLessThanMinutesAgo,
  startingInLessThanMinutes,
  minimumCountToDisplay,
  maximumCountToDisplay,
  todayOnly,
}) => {
  useAutoRefresh(60 * 1000); // Refresh home every 60s
  const upcomingWorkshops = useSelector((state) =>
    upcomingSessions(
      state,
      startedLessThanMinutesAgo,
      startingInLessThanMinutes,
      minimumCountToDisplay ?? 0,
      maximumCountToDisplay,
      todayOnly,
    ),
  );
  if (upcomingWorkshops.length === 0) return null;
  return (
    <Grid.Row stretched className="home-block--upcoming-workshops">
      <Grid.Column width={16}>
        <Segment>
          {title && <Header as="h3">{title}</Header>}
          <WorkshopList centered workshopList={upcomingWorkshops} />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

UpcomingWorkshopsBlock.defaultProps = {
  startedLessThanMinutesAgo: 5,
  startingInLessThanMinutes: 30,
  minimumCountToDisplay: undefined,
  maximumCountToDisplay: undefined,
  todayOnly: true,
  title: undefined,
};

UpcomingWorkshopsBlock.propTypes = {
  startedLessThanMinutesAgo: PropTypes.number,
  startingInLessThanMinutes: PropTypes.number,
  minimumCountToDisplay: PropTypes.number,
  maximumCountToDisplay: PropTypes.number,
  todayOnly: PropTypes.bool,
  title: PropTypes.string,
};

export default UpcomingWorkshopsBlock;
