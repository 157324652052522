import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

const ActionIcons = {
  updatePassword: 'lock',
  logout: 'log out',
  updateProfile: 'check',
  editProfile: 'edit',
  cancelEditProfile: 'undo',
};

const ProfileActionButton = ({ id, position = 'left', disabled = false, onClick, ...props }) => {
  const { t } = useTranslation();

  const label = t(`profile.actions.${id}`);

  return (
    <>
      <Button
        size="small"
        icon
        floated={position}
        disabled={disabled}
        {...props}
        onClick={onClick}
        className={`button--action button--action-${id}`}
      >
        <Icon name={ActionIcons[id]} />
        &nbsp;
        {label}
      </Button>
    </>
  );
};

ProfileActionButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
};

ProfileActionButton.defaultProps = {
  disabled: false,
  icon: undefined,
  onClick: undefined,
  position: 'left',
};

export default ProfileActionButton;
