import React from 'react';

import { Card, Icon, Label } from 'semantic-ui-react';

export const CardInfo = ({ icon, style, children, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', ...style }}>
    {icon && <Icon name={icon} />} {children}
  </Card.Description>
);

export const CardBoolTag = ({ icon, label, color, style, ...props }) => (
  <Card.Description {...props} style={{ textAlign: 'left', marginTop: 8, ...style }}>
    <Label color={color} icon={icon} content={label} />
  </Card.Description>
);

export const cardMetaComponents = {
  extra: Card.Meta,
  description: Card.Description,
  info: CardInfo,
  boolTag: CardBoolTag,
};
