import PropTypes from 'prop-types';
import React from 'react';
import { Header, Icon, Item, Segment } from 'semantic-ui-react';
import { useTracking } from '../../Context';
import { eventTags } from '../../core/trackers/events';
import { getString } from '../../utils';

const ContactsBlock = ({ item, field, header }) => {
  const contacts = item[field];
  const { trackEvent } = useTracking();
  if (!contacts || contacts.length === 0) return null;

  return (
    <Segment>
      <Header as="h3">{header || getString(`sponsors.sponsor.contact-information`)}</Header>
      <Item.Group link>
        {contacts.map((contact) => (
          <Item
            key={contact._id}
            as="a"
            href={`mailto:${contact.email}`}
            target="_blank"
            onClick={() => trackEvent(eventTags.ITEM_CONTACT_CLICK, { contact, item })}
          >
            <Item.Content>
              <Item.Header style={{ fontSize: '1.2em', marginBottom: 0 }}>
                <Icon name="user circle outline" style={{ marginRight: 4 }} /> {contact.firstName}{' '}
                {contact.lastName}
              </Item.Header>
              {contact.phoneNumber && (
                <Item.Extra style={{ color: '#777' }}>
                  <Icon name="phone" style={{ marginRight: 4 }} />
                  {contact.phoneNumber}
                </Item.Extra>
              )}
              <Item.Meta>
                <Icon name="envelope outline" style={{ marginRight: 4 }} />
                {contact.email}
              </Item.Meta>
            </Item.Content>
          </Item>
        ))}
      </Item.Group>
    </Segment>
  );
};

ContactsBlock.defaultProps = {
  field: 'contacts',
  header: undefined,
};

ContactsBlock.propTypes = {
  field: PropTypes.string,
  item: PropTypes.object.isRequired,
  header: PropTypes.string,
};

export default ContactsBlock;
