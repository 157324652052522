import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import CdnImage from './CdnImage';

function ImageIcon({ type, icon, maxHeight, style }) {
  if (!icon) return null;
  if (type === 'icon' && typeof icon === 'string') {
    if (!icon) return null;
    return <Icon name={icon} style={style} className="icon" />;
  }
  if (!icon.uri) return null;
  return <CdnImage src={icon} className="image-icon" maxHeight={maxHeight} style={style} />;
}

ImageIcon.defaultProps = {
  type: 'icon',
  maxHeight: 30,
  icon: undefined,
  style: {},
};

ImageIcon.propTypes = {
  type: PropTypes.oneOf(['image', 'icon']),
  maxHeight: PropTypes.number,
  style: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ImageIcon;
