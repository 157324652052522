import _orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { throttledFetchWorkshops } from './workshops.actions';
import { selectWorkshopsById, selectWorkshopsBySlug } from './workshops.selectors';

import { userAgendaWithLoading } from '../../agenda/store/agenda.selectors';
import { matchesWithArray } from '../../core/filter/utils';
import platformService from '../../core/services/platform.service';
import store from '../../shared/Store';
// eslint-disable-next-line import/no-cycle
import { useOrderBy } from '../../utils/hooks';
import { useStoreCollectionSync } from '../../utils/useVersionSync';

export function useWorkshop({
  workshopId = undefined,
  workshopSlug = undefined,
  match = undefined,
}) {
  const workshopsById = useSelector(selectWorkshopsById) || {};
  const workshopsBySlug = useSelector(selectWorkshopsBySlug) || {};

  if (workshopId) return workshopsById[workshopId];
  const slug = workshopSlug || match?.params?.slug;
  if (slug) return workshopsBySlug[slug];

  return undefined;
}

export function useWorkshops() {
  return useSelector((state) => state.workshops.workshops);
}

export function useWorkshopsFromIds(workshopIds) {
  const workshopsById = useSelector(selectWorkshopsById);
  return useMemo(() => {
    if (!workshopIds) return null;
    return workshopIds.map((id) => workshopsById[id]).filter((v) => v);
  }, [workshopsById, workshopIds]);
}

export function useSyncedWorkshops() {
  useStoreCollectionSync({ resourceType: 'workshops', fetcher: throttledFetchWorkshops });
  return useSelector((state) => state.workshops.workshops);
}

export function useSyncedCollectionWorkshops(collection) {
  useStoreCollectionSync({ resourceType: 'workshops', fetcher: throttledFetchWorkshops });
  return useSelector((state) =>
    state.workshops.workshops.filter((w) => w.collection === collection),
  );
}

export function useFeedbackUrl(workshop, feedback) {
  const [feedbackUrl, setFeedbackUrl] = useState(null);
  const { uri, title } = feedback || {};
  useEffect(() => {
    async function check() {
      if (!uri) return;
      const api = `${uri.replace('/html', '/api')}/workshops/${workshop._id}/users/${store.userId}`;
      const res = await platformService.secureGet(api);
      if (res) {
        const { status, token } = res;
        if (status === 'display') {
          // Generate workshop-specific uri
          setFeedbackUrl(`${uri}/workshops/${workshop._id}?token=${token}&userId=${store.userId}`);
        }
      }
    }
    check();
  }, [workshop, feedback]);
  return { feedbackUrl, title };
}

export function useWorkshopQuery(query = {}) {
  const { from, orderBy } = query;
  const { workshops = [] } = useSelector((state) => state.workshops);
  const items = from ? workshops.filter(matchesWithArray(from)) : workshops;
  return useOrderBy(items, orderBy);
}

export function useUserAgendaQuery(query = {}) {
  const { from, orderBy } = query;
  const { agenda: userEvents, ...loadingData } = useSelector(userAgendaWithLoading);
  const items = from ? userEvents.filter(matchesWithArray(from)) : userEvents;
  const orderedAgenda = useOrderBy(items, orderBy);
  return { ...loadingData, agenda: orderedAgenda };
}

export function useCollectionAndCategoryWorkshops(collection, category) {
  const workshops = _orderBy(useSyncedCollectionWorkshops(collection), 'title', 'asc');
  return useMemo(() => {
    if (!category || category?.length === 0) return [];
    if (Array.isArray(category)) {
      return workshops.filter((w) => w.category?.some((value) => category.indexOf(value) >= 0));
    }
    return workshops.filter((w) => w.category === category || w.category?.includes(category));
  }, [category, workshops]);
}
