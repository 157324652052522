/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef } from 'react';
import { useConfig } from '../config/config.context';
import pubsubService from '../core/websockets/pubsub.service';
import store from '../shared/Store';
import { useFirebase } from './useFirebase';

export function useDisableFirebase() {
  const config = useConfig();
  return config?.options?.disableFirebase || config.lang === 'en-cn';
}

function useFirebaseOrWs(useFB, useWS) {
  const disableFirebase = useDisableFirebase();
  return disableFirebase ? useWS : useFB;
}

export const useWsVersionSync = ({ refresh, resourceType, id, actualVersion, lazy = false }) => {
  const state = useRef({
    previousVersion: null,
    firstRunDone: lazy,
    actualVersion,
  });
  state.current.actualVersion = actualVersion;
  useEffect(() => {
    if (!resourceType || !id) return () => {};

    const unsubPromise = Promise.resolve().then(async () => {
      if (!state.current.firstRunDone) {
        await refresh();
        state.current.firstRunDone = true;
      }
      if (!state.current.actualVersion && !lazy) return () => {};

      return pubsubService.onDocument(
        `platform/${store.eventId}/versions/${resourceType}/items/${id}`,
        (data) => {
          const { version } = data ?? {};
          if (
            (!lazy || state.current.previousVersion) &&
            state.current.actualVersion?.toString() !== version?.toString() &&
            state.current.previousVersion !== version
          ) {
            refresh({
              refresh: true,
            });
          }
          state.current.previousVersion = version;
        },
      );
    });

    return () => {
      unsubPromise.then((unsub) => unsub());
    };
  }, [resourceType, id, refresh, lazy]);
};

export const useVersionSync = ({ refresh, resourceType, id, actualVersion, lazy = false }) => {
  const state = useRef({
    previousVersion: null,
    firstRunDone: lazy,
    actualVersion,
  });
  state.current.actualVersion = actualVersion;
  const firebase = useFirebase();
  useEffect(() => {
    if (!resourceType || !id || !firebase) return () => {};

    const unsubPromise = Promise.resolve().then(async () => {
      if (!state.current.firstRunDone) {
        await refresh();
        state.current.firstRunDone = true;
      }
      if (!state.current.actualVersion && !lazy) return () => {};

      return firebase
        .firestore()
        .doc(`platform/${store.eventId}/versions/${resourceType}/items/${id}`)
        .onSnapshot({
          next(snapshot) {
            if (!snapshot.exists) return;
            const { version } = snapshot.data() ?? {};
            if (
              (!lazy || state.current.previousVersion) &&
              state.current.actualVersion?.toString() !== version?.toString() &&
              state.current.previousVersion !== version
            ) {
              refresh({
                refresh: true,
              });
            }
            state.current.previousVersion = version;
          },
        });
    });

    return () => {
      unsubPromise.then((unsub) => unsub());
    };
  }, [resourceType, id, refresh, firebase, lazy]);
};

export const useStoreVersionSync = ({ fetcher, resourceType, id, collection, actualVersion }) => {
  const refresh = useCallback(
    (options) => store.reduxStore.dispatch(fetcher(id, options, collection)),
    [id, collection, fetcher],
  );
  return useFirebaseOrWs(
    useVersionSync,
    useWsVersionSync,
  )({
    refresh,
    resourceType,
    id,
    collection,
    actualVersion,
  });
};

const useWsCollectionSync = ({ refresh, resourceType }) => {
  useEffect(() => {
    if (!resourceType) return () => {};
    return pubsubService.onCollection(
      `platform/${store.eventId}/versions/${resourceType}/items`,
      () => {
        refresh({
          refresh: true,
        });
      },
    );
  }, [resourceType, refresh]);
};

const useCollectionSync = ({ refresh, resourceType }) => {
  const firebase = useFirebase();
  useEffect(() => {
    if (!firebase || !resourceType) return () => {};
    return firebase
      .firestore()
      .collection(`platform/${store.eventId}/versions/${resourceType}/items`)
      .onSnapshot({
        next() {
          refresh({
            refresh: true,
          });
        },
      });
  }, [resourceType, refresh, firebase]);
};

export const useStoreCollectionSync = ({ resourceType, fetcher }) => {
  const refresh = useCallback((options) => store.reduxStore.dispatch(fetcher(options)), [fetcher]);
  return useFirebaseOrWs(
    useCollectionSync,
    useWsCollectionSync,
  )({
    refresh,
    resourceType,
  });
};
