import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { Header, Message } from 'semantic-ui-react';
import { useDesignConfig } from '../config/design.context';
import store from '../shared/Store';
import { markdownUrlify } from '../utils/stringUtils';
import LoginPasswordForm from './components/LoginPasswordForm';
import OneTimePasswordForm from './components/OneTimePasswordForm';
import { useForceUpdate, useLoginConfig } from './hooks';
import LoginLayout from './LoginLayout';

const translationPrefix = 'login';

const loginModes = {
  otp: OneTimePasswordForm,
  password: LoginPasswordForm,
};

const LoginScreen = () => {
  const { t } = useTranslation();
  const design = useDesignConfig();
  const login = useLoginConfig();
  const forceUpdate = useForceUpdate();
  const { state } = useLocation();
  const { tokenExpired = false } = state || {};
  if (store.isLoggedIn()) {
    return <Redirect to="/" />;
  }
  const { primaryColor: color } = design;
  const { mode = 'password', ctaSection, layout = {} } = login;
  const { variant } = layout;

  const notYetRegistered = t(`${translationPrefix}.not-yet-registered`, '');
  const LoginComponent = loginModes[mode] || loginModes.password;
  return (
    <LoginLayout screen="login" variant={variant} sideSection={ctaSection}>
      <div className="platform-opened">
        <Header as="h2" style={{ color }} textAlign="center">
          {t(`${translationPrefix}.connect-to-your-account`, '')}
        </Header>
        <LoginComponent onLogin={forceUpdate} />
        {notYetRegistered && (
          <p className="text--not-yet-registered">{markdownUrlify(notYetRegistered)}</p>
        )}
        {tokenExpired && (
          <Message
            className="error-message"
            error
            visible
            header={t(`${translationPrefix}.error`)}
            content={t(`${translationPrefix}.link-has-expired`)}
          />
        )}
      </div>
    </LoginLayout>
  );
};

export default LoginScreen;
