import { useSelector } from 'react-redux';

export function useMe() {
  return useSelector((state) => state.user?.user);
}

export function useIsAnonymous() {
  const user = useMe();
  return !user || user.userType === 'anonymous';
}
