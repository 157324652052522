/* eslint-disable react/no-array-index-key */
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import pick from 'lodash/pick';
import React, { useState } from 'react';
import { Button, Form, Icon, Input, Segment, TextArea } from 'semantic-ui-react';
import platformService from '../../core/services/platform.service';
import { containerTypePropTypes } from '../../propTypes';
import store from '../../shared/Store';
import { getString } from '../../utils';
import { sweetAlert } from '../../utils/popupUtils';
import BlockContainer from '../BlockContainer';

const prefix = 'contact';

const contactFields = [
  [
    {
      name: 'firstName',
      placeholder: getString('users.field.firstName'),
      label: getString('users.field.firstName'),
      type: 'string',
      icon: 'user',
      width: 8,
      required: true,
      control: Input,
    },
    {
      name: 'lastName',
      placeholder: getString('users.field.lastName'),
      label: getString('users.field.lastName'),
      type: 'string',
      icon: 'user',
      width: 8,
      required: true,
      control: Input,
    },
  ],
  [
    {
      name: 'email',
      placeholder: getString('users.field.email'),
      label: getString('users.field.email'),
      type: 'email',
      width: 16,
      icon: 'envelope',
      required: true,
      control: Input,
    },
    {
      name: 'phoneNumber',
      placeholder: getString('users.field.phoneNumber'),
      label: getString('users.field.phoneNumber'),
      type: 'string',
      width: 16,
      icon: 'phone',
      required: false,
      control: Input,
    },
  ],
  {
    name: 'subject',
    // placeholder: getString(`${prefix}.subject`),
    label: getString(`${prefix}.subject`),
    type: 'string',
    // icon: 'question',
    width: 16,
    required: true,
    control: Input,
  },
  {
    name: 'message',
    label: getString(`${prefix}.message`),
    type: 'string',
    width: 16,
    required: true,
    control: TextArea,
  },
];

function initFormFromUser() {
  const fields = flattenDeep(contactFields).map((field) => field.name);
  return pick(store.user, fields);
}

function getErrorMessage(code) {
  switch (code) {
    case 'E_EMPTY_CONTACT_EMAIL':
      return getString(`${prefix}.empty-email-recipient`);
    default:
      return getString(`${prefix}.email-error`);
  }
}

const ContactBlock = (props) => {
  const { containerType } = props;
  const [data, setData] = useState(initFormFromUser);
  const [state, setState] = useState('IDLE');
  const handleChange = (e, { name }) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    const newData = { ...data, [name]: value };
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState('SENDING');
    try {
      const res = await platformService.sendContactEmail(data);
      if (res.success) {
        setState('DONE');
      } else if (res.errors) {
        const { code } = res.errors[0];
        sweetAlert({ icon: 'error', title: getErrorMessage(code) });
        setState('ERROR');
      }
    } catch (error) {
      sweetAlert({ icon: 'error', title: getString(`${prefix}.email-error`) });
      setState('ERROR');
    }
  };

  if (state === 'DONE') {
    return (
      <Segment>
        <div style={{ textAlign: 'center' }}>{getString(`${prefix}.mail-sent`)}</div>
      </Segment>
    );
  }
  const renderField = (field) => (
    <Form.Field
      key={field.name}
      {...field}
      iconPosition={field.icon && 'left'}
      value={get(data, field.name)}
      onChange={handleChange}
    />
  );
  return (
    <BlockContainer
      className="block--contact"
      header={getString(`${prefix}.contact-us`)}
      type={containerType}
    >
      <Form onSubmit={handleSubmit}>
        {contactFields.map((field, index) => {
          if (Array.isArray(field)) {
            return <Form.Group key={index}>{field.map(renderField)}</Form.Group>;
          }
          return renderField(field);
        })}
        <Button type="submit" primary>
          <Icon
            name={state !== 'SENDING' ? 'send' : 'circle notch'}
            loading={state === 'SENDING'}
          />
          {getString(`${prefix}.send`)}
        </Button>
      </Form>
    </BlockContainer>
  );
};

ContactBlock.defaultProps = {
  containerType: 'segment-light',
};

ContactBlock.propTypes = {
  containerType: containerTypePropTypes,
};

export default ContactBlock;
